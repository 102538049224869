import { TFunction } from "i18next";

import { GridColDef } from "@mui/x-data-grid-pro";
import { COLUMN_SIZE, formatDateToQuery } from "@sbm/fe-utils";
import { StatusForAuthorEnum, IEmployee, TaskCategoryEnum } from "@types";

import { getTaskOptions } from "../../MyTasksContainer/helpers";

export enum TasksFieldsEnum {
  highPriority = "highPriority",
  createdAt = "createdAt",
  actualDateOfExecution = "actualDateOfExecution",
  taskAuthorShortName = "taskAuthorShortName",
  statusForAuthor = "statusForAuthor",
  status = "status",
  group = "group",
}

export const getTableColumns = (t: TFunction): GridColDef[] => {
  return [
    {
      field: "internalRegistrationNumber",
      headerName: t("feedback.reg_number"),
      sortable: true,
      filterable: false,
      width: COLUMN_SIZE * 1.7,
      minWidth: COLUMN_SIZE * 1.7,
    },
    {
      field: "textOfTheTask",
      headerName: t("feedback.request_content"),
      sortable: false,
      filterable: false,
      minWidth: COLUMN_SIZE * 5.3,
      width: COLUMN_SIZE * 5.3,
    },
    {
      field: "briefTextOrganizationName",
      headerName: t("feedback.organization"),
      sortable: true,
      filterable: false,
      minWidth: COLUMN_SIZE * 3,
      width: COLUMN_SIZE * 3,
    },
    {
      field: "taskAuthorShortName",
      headerName: t("author"),
      sortable: true,
      filterable: false,
      minWidth: COLUMN_SIZE * 2.5,
    },
    {
      field: "statusForAuthor",
      headerName: t("status"),
      sortable: true,
      filterable: false,
      minWidth: COLUMN_SIZE * 2.5,
    },
    {
      field: "id",
      headerName: t("feedback.int_number"),
      sortable: true,
      filterable: false,
      minWidth: COLUMN_SIZE,
    },
    {
      field: "createdAt",
      headerName: t("feedback.date_of_appointment"),
      sortable: true,
      filterable: false,
      minWidth: COLUMN_SIZE * 2,
    },
    {
      field: "highPriority",
      headerName: t("priority"),
      sortable: true,
      filterable: false,
      minWidth: COLUMN_SIZE * 1.7,
    },
    {
      field: "actualDateOfExecution",
      headerName: t("execution.date"),
      sortable: true,
      filterable: false,
      minWidth: COLUMN_SIZE * 2.3,
    },
    {
      field: "attachedFiles",
      headerName: "",
      sortable: true,
      filterable: false,
      minWidth: COLUMN_SIZE * 1.5,
    },
  ];
};

const TASKS_FILTERABLE_FIELDS = [
  {
    title: "priority",
    field: "highPriority",
    options: [],
    optionType: "radio",
    value: "",
  },
  {
    title: "assignment.date",
    field: "createdAt",
    options: [],
    value: "",
    optionType: "range",
  },
  {
    title: "execution.date",
    field: "actualDateOfExecution",
    options: [],
    value: "",
    optionType: "range",
  },
  {
    title: "status.for.author",
    field: "statusForAuthor",
    options: [],
    value: "",
    multiSelect: true,
  },
];

export const getTasksFilterOptions = (
  t: TFunction,
  employees: { items: IEmployee[] } | undefined
) => {
  return TASKS_FILTERABLE_FIELDS.map((item) => {
    const { field, title } = item;

    return {
      ...item,
      title: t(title),
      options: getTaskOptions(field, t, employees),
    };
  });
};

const findIdByName = (
  name: TasksFieldsEnum,
  value: string,
  t: TFunction,
  employees: { items: IEmployee[] } | undefined
) => {
  let result: number | string | undefined;
  switch (name) {
    case TasksFieldsEnum.highPriority:
      if (!value) break;
      result = value === t("priority.high") ? "true" : "false";
      break;
    case TasksFieldsEnum.taskAuthorShortName:
      result = employees?.items?.find(
        (employee) => employee.personalData.shortName === value
      )?.id;
      break;
    case TasksFieldsEnum.createdAt:
    case TasksFieldsEnum.actualDateOfExecution:
      result = formatDateToQuery(value);
      break;

    case TasksFieldsEnum.statusForAuthor:
      result = Object.values(StatusForAuthorEnum).find(
        (type) => t(`statuses.${type}`) === value
      );
      break;
    case TasksFieldsEnum.group:
      result = Object.values(TaskCategoryEnum).find((type) => type === value);
      break;
  }

  if (!result) return;

  return result;
};

export const getTasksFilterValues = (
  selectedFilters: string[],
  t: TFunction,
  employees: { items: IEmployee[] } | undefined
) => {
  if (!selectedFilters?.length) return [];

  return selectedFilters.map((filter) => {
    const parts = filter.split("=");
    const filterKey = parts[0];
    const filterValue = parts[1];
    const values = filterValue.split(",");

    let query = `${filterKey}=`;

    values.forEach((value, index) => {
      const isLast = index === values.length - 1;
      const idOfFilter = findIdByName(
        filterKey as TasksFieldsEnum,
        value,
        t,
        employees
      );

      if (idOfFilter) {
        query = query + idOfFilter;
        if (!isLast) query = query + ",";
      }
    });

    return query;
  });
};
