import styled from "styled-components";
import { FLEX_COLUMN, FLEX_COLUMN_FULL } from "ui-kit";

import StructuralUnitBackground from "../../../../../assets/backgrounds/structural_unit_background.png";

export const FormWrapper = styled.form`
  ${FLEX_COLUMN};
  flex: 1;
  .MuiDataGrid-footerContainer {
    display: none;
  }
`;

export const EllipsisTextWrapper = styled.div<{ $height?: number }>`
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      cursor: pointer
    },
}
`;

export const PreviewImg = styled.div`
  ${FLEX_COLUMN_FULL};
  background-image: url(${StructuralUnitBackground});
  background-position: center;
  height: 255px;
  width: 486px;
  background-repeat: no-repeat;
  margin-top: ${({ theme }) => theme.spacing(4)};
`;
