import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useGetEmployeesInHolding } from "api";
import { Modal } from "ui-kit";

import {
  Autocomplete,
  Grid,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { hexToRgba } from "@sbm/fe-utils";
import { Button, FormSelect, Input } from "@sbm/ui-components";
import { IAddAgreement } from "@types";

export const AddAgreementRouteApproverPopUp = ({
  open,
  onClose,
  data,
}: {
  open: boolean;
  onClose: () => void;
  data: {
    data: {
      duration: number;
      queueCount: number;
      onUpdate: any;
    };
  };
}) => {
  const { t } = useTranslation("correspondence");
  const theme = useTheme();

  const { duration, queueCount, onUpdate } = data.data;

  const { register, control, handleSubmit, setValue, getValues } =
    useForm<IAddAgreement>({
      defaultValues: {
        duration,
      },
    });

  const { data: employeesList } = useGetEmployeesInHolding({
    limit: 249,
  });

  const queueOptionsList = useMemo(() => {
    const arr = [];
    if (queueCount) {
      for (let i = 1; i <= queueCount; ++i) {
        arr.push({
          option: `${i}`,
          value: i,
        });
      }
    }
    return arr;
  }, [queueCount]);

  const employeesOption =
    employeesList?.items?.map((employee) => {
      return {
        title: employee.personalData.longName,
        id: employee.employeeId,
        positionName: employee.structuralUnit?.positionName,
        nameOfStructuralUnit: employee.structuralUnit?.nameOfStructuralUnit,
      };
    }) || [];

  const handleInputChange = (
    _: React.SyntheticEvent,
    newValue: {
      title: string | null;
      id: number;
      positionName?: string;
      nameOfStructuralUnit: string;
    } | null
  ) => {
    if (
      newValue &&
      newValue.id &&
      newValue.title &&
      newValue.nameOfStructuralUnit
    ) {
      setValue("employeeId", newValue.id, { shouldDirty: true });
      setValue("positionName", newValue?.positionName, { shouldDirty: true });
      setValue("nameOfStructuralUnit", newValue.nameOfStructuralUnit, {
        shouldDirty: true,
      });
      setValue("title", newValue.title, { shouldDirty: true });
    } else {
      setValue("employeeId", null);
      setValue("positionName", undefined);
      setValue("title", "");
      setValue("nameOfStructuralUnit", "");
    }
  };

  const onSubmit = handleSubmit(async () => {
    onUpdate({
      employeeId: getValues("employeeId"),
      positionName: getValues("positionName"),
      duration: getValues("duration"),
      title: getValues("title"),
      nameOfStructuralUnit: getValues("nameOfStructuralUnit"),
    });
    onClose();
  });

  const content = {
    modalTitle: t("add_approver"),
    body: (
      <Stack mb={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              options={employeesOption}
              onChange={handleInputChange}
              getOptionLabel={(option) => option?.title || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  color="secondary"
                  size="medium"
                  label={t("employee_name")}
                  required
                />
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  <Stack flexDirection="column" justifyContent="center" gap={1}>
                    <Typography
                      color="text.primary"
                      variant="subtitle1"
                      sx={{ lineHeight: "16px" }}
                    >
                      {option.title}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color={hexToRgba(theme.palette.text.primary, 0.7)}
                      sx={{ lineHeight: "16px" }}
                    >
                      {option.id}
                    </Typography>
                  </Stack>
                </li>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FormSelect
              label={t("approval_queue")}
              name="queueNumber"
              control={control}
              values={queueOptionsList}
            />
          </Grid>

          <Grid item xs={6}>
            <Input
              variant="outlined"
              size="medium"
              label={t("duration")}
              type="number"
              {...register("duration")}
            />
          </Grid>
        </Grid>
      </Stack>
    ),

    mainButton: (
      <Stack flexDirection="row" justifyContent="end" width="100%" gap={2}>
        <Button
          autoFocus
          variant="outlined"
          color="secondary"
          onClick={onClose}
        >
          {t("cancel")}
        </Button>
        <Button
          autoFocus
          variant="contained"
          color="secondary"
          onClick={onSubmit}
        >
          {t("add")}
        </Button>
      </Stack>
    ),
  };

  return (
    <Modal isOpen={open} onClose={onClose} content={content} updatedVersion />
  );
};
