import React from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "app";

import { Stack, Tooltip, Typography, useTheme } from "@mui/material";

import { ApprovalSubjectActionsMenu } from "./ApprovalSubjectActionsMenu";

interface Props {
  name: string;
  position: string;
  approverType: string;
  amountOfDays: number;
  changeDuration?: () => number;
}

export const ApprovalSubject = ({
  name,
  position,
  approverType,
  amountOfDays,
}: Props) => {
  const { t } = useTranslation("correspondence");

  const dispatch = useAppDispatch();
  const theme = useTheme();

  return (
    <Stack className="ellipsis" sx={{ width: "100%" }}>
      <Stack direction="row" justifyContent="space-between">
        <Tooltip arrow placement="top" title={name}>
          <Typography variant="body1" fontWeight={600} className="ellipsis">
            {name}
          </Typography>
        </Tooltip>
        <ApprovalSubjectActionsMenu
          amountOfDays={amountOfDays}
          //todo queueNumber need implement after actions implementation
          queueNumber={2}
        />
      </Stack>

      <Tooltip arrow placement="top" title={position}>
        <Typography className="ellipsis" variant="body2" color="text.secondary">
          {position}
        </Typography>
      </Tooltip>

      <Tooltip arrow placement="top" title={approverType}>
        <Typography
          component="p"
          variant="body4"
          fontWeight={700}
          color="text.disabled"
          className="ellipsis"
        >
          {approverType}
        </Typography>
      </Tooltip>

      <Stack direction="row" alignItems="center" gap={1}>
        <Typography variant="body4" fontWeight={700} color="text.disabled">
          {amountOfDays}
        </Typography>
        <Typography variant="body4" fontWeight={700} color="text.disabled">
          {t("day")}
        </Typography>
      </Stack>
    </Stack>
  );
};
