import {
  FileAttachedToCorrespondenceFileStatusEnum,
  FileAttachedToCorrespondenceTypeOfAttachmentEnum,
} from "@types";

export interface IAttachesCorrespondenceFileForm {
  fileAppointment?: FileAttachedToCorrespondenceTypeOfAttachmentEnum;
  files: File[] | undefined;
  descriptionForAttachment?: string;
  fileStatus?: FileAttachedToCorrespondenceFileStatusEnum;
}

export const FILES_MAX_COUNT = 10;
