import React, { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useAppApproverMutation, useGetConfirmedApprovalRoutes } from "api";
import { TransitionPrompt, useAppDispatch, getEmployees } from "app";
import { Drawer } from "ui-kit";

import { Grid } from "@mui/material";
import { getPersonalDataName } from "@sbm/fe-utils";
import {
  FormAutocomplete,
  FormSelect,
  NumericInput,
  Button,
} from "@sbm/ui-components";
import {
  IOrganization,
  IEmployee,
  IPosition,
  IAddApproverForm,
  TypeOfObjectInternalApprovalEnum,
} from "@types";

import { ActionsWrapper } from "./styles";

interface Props {
  onClose: () => void;
  id: number;
  currentQueueNumber?: number;
  isDraft?: boolean;
  type: TypeOfObjectInternalApprovalEnum;
}

export const AddApproverContainer: React.FC<Props> = ({
  id,
  onClose,
  currentQueueNumber = 1,
  isDraft,
  type,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [transitionPromptOpen, setTransitionPromptOpen] = React.useState(false);

  const [inputEmployeeValue, setInputEmployeeValue] = useState("");
  const [employeeOptions, setEmployeeOptions] = useState<
    {
      title: string;
      id: number;
      organizationId?: number;
      positionId?: number;
      organization?: IOrganization;
      position?: IPosition;
    }[]
  >([]);

  const { data: confirmedRoutes } = useGetConfirmedApprovalRoutes({
    id,
    type,
  });

  const { mutate: addApprover, isLoading: isAddApproverLoading } =
    useAppApproverMutation(onClose);

  const {
    control,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    reset,
    formState: { errors, dirtyFields },
  } = useForm<IAddApproverForm>({
    defaultValues: {
      termSingleApproval: 2,
    },
  });

  const { employeeId, queueNumber } = watch();

  const currentEmployee = employeeOptions.find((i) => i.id === employeeId);

  const getEmployeeDefaultValue = React.useCallback(
    (id?: number) => {
      if (!id || !employeeOptions.length) return;

      const employee = employeeOptions.find((i) => i.id === id);
      if (!employee) return;

      return employee.title;
    },
    [employeeOptions]
  );

  const fetchEmployees = React.useCallback(
    async (search: string) => {
      const { items } = await dispatch(getEmployees(search));

      return items
        .map((i: IEmployee) => ({
          title: getPersonalDataName(i.personalData),
          id: i.id,
          organization: i.staffUnit?.organization,
          organizationId: i.staffUnit?.organizationId,
          positionId: i.staffUnit?.positionId,
          position: i.staffUnit?.position,
        }))
        .filter((i: { title: string | undefined }) => Boolean(i.title));
    },
    [dispatch]
  );

  const handleEmployeeInputChange = (val: string, reason: string) => {
    if (val === inputEmployeeValue) return;

    setInputEmployeeValue(val);

    // Delete action
    if (reason === "clear") {
      clearErrors("employeeId");
      setValue("employeeId", undefined, { shouldDirty: true });
      setValue("organizationId", undefined);
      setValue("positionId", undefined);
      return;
    }

    // Action is select from the list
    if (reason === "reset") {
      const option = employeeOptions.find((i) => i.title === val);
      if (!option) return;

      setValue("employeeId", option.id, { shouldDirty: true });
      setValue("organizationId", option.organizationId);
      setValue("positionId", option.positionId);
    }
  };

  const organizationOptions = useMemo(() => {
    return [
      {
        option: currentEmployee?.organization?.briefTextOrganizationName || "",
        value: currentEmployee?.organization?.id,
      },
    ];
  }, [currentEmployee]);

  const positionOptions = useMemo(() => {
    return [
      {
        option: currentEmployee?.position?.namePosition,
        value: currentEmployee?.position?.id,
      },
    ];
  }, [currentEmployee]);

  const queueOptions = useMemo(() => {
    if (!confirmedRoutes?.approvers) return [];

    const approversQueueNumbers = confirmedRoutes.approvers.map((item) =>
      String(item.queueNumber)
    );
    const result = [...new Set(approversQueueNumbers)].filter((i) =>
      currentQueueNumber === 1 && isDraft
        ? Number(i) >= currentQueueNumber
        : Number(i) > currentQueueNumber
    );

    return result.map((queue) => ({
      option: queue,
      value: Number(queue),
    }));
  }, [confirmedRoutes, currentQueueNumber, isDraft]);

  const handleDiscardTransition = () => {
    setTransitionPromptOpen(false);
  };

  const handleConfirmTransition = () => {
    reset();
    onClose();
  };

  const handleClose = () => {
    if (Object.keys(dirtyFields).length > 0) {
      return setTransitionPromptOpen(true);
    }

    onClose();
  };

  const onSubmit = handleSubmit(async (fields) => {
    const fieldsCopy = fields;

    delete fieldsCopy["organizationId"];
    delete fieldsCopy["positionId"];

    fieldsCopy["objectInternalApprovalProcessId"] = confirmedRoutes?.id;

    addApprover(fieldsCopy);
  });

  return (
    <Drawer
      open
      anchor="right"
      onClose={handleClose}
      resizable={false}
      title={t("add.approver")}
      actions={
        <ActionsWrapper>
          <Button color="secondary" variant="outlined" onClick={handleClose}>
            {t("close")}
          </Button>

          <Button
            color="secondary"
            variant="contained"
            autoFocus
            onClick={onSubmit}
            disabled={!employeeId || !queueNumber || isAddApproverLoading}
            loading={isAddApproverLoading}
          >
            {t("add")}
          </Button>
        </ActionsWrapper>
      }
    >
      <TransitionPrompt
        open={transitionPromptOpen}
        onClose={handleDiscardTransition}
        onConfirm={handleConfirmTransition}
      />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FormAutocomplete
            required
            inputValue={inputEmployeeValue}
            freeSolo={false}
            label={t("employee.fullname")}
            fetchData={fetchEmployees}
            defaultSelected={getEmployeeDefaultValue(employeeId)}
            onFetchDataSuccess={setEmployeeOptions}
            onInputChange={handleEmployeeInputChange}
            error={!!errors.employeeId}
          />
        </Grid>

        <Grid item xs={12}>
          <FormSelect
            readOnly
            label={t("organization")}
            name="organizationId"
            control={control}
            values={organizationOptions}
          />
        </Grid>
        <Grid item xs={12}>
          <FormSelect
            readOnly
            label={t("position")}
            name="positionId"
            control={control}
            values={positionOptions}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormSelect
            label={t("approval.queue")}
            name="queueNumber"
            control={control}
            values={queueOptions}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="termSingleApproval"
            control={control}
            render={({ field }) => {
              return (
                <NumericInput
                  {...field}
                  allowNegative={false}
                  label={t("approval.duration")}
                  onKeyDown={(e) => e.key === "." && e.preventDefault()} // disable decimal numbers input
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </Drawer>
  );
};
