export interface IMessageForm {
  messageTo?: string;
  messageSubject: string;
  employeeId: number[];
  messageBody: string;
  files: File[] | undefined;
  isImportant: boolean;
}

export const MESSAGE_DEFAULT_VALUES: IMessageForm = {
  messageTo: "",
  employeeId: [],
  messageSubject: "",
  messageBody: "",
  files: undefined,
  isImportant: false,
};

export const FILES_MAX_COUNT = 10;
export const FILES_MAX_SIZE = 5 * 1024 * 1024; //5MB
