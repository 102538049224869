import React from "react";
import { useTranslation } from "react-i18next";

import { useMedia } from "app";
import { Icon } from "ui-kit";

import { Grid, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import {
  getLocaleDateFormat,
  getStorageItem,
  hexToRgba,
  STORAGE_KEYS,
} from "@sbm/fe-utils";
import {
  CorrespondenceTypeEnum,
  IDocumentById,
  StructuralUnitTypesForCorrespondenceEnum,
} from "@types";

import { CorrespondenceStatusChip } from "../CorrespondenceStatusChip";
import { cardImage } from "./helpers";
import {
  EllipsisTextWrapper,
  DocumentCardImage,
  DocumentCardWrapper,
} from "./styles";

interface DocumentCardProps {
  data: IDocumentById;
  isInDrawer?: boolean;
}

export const DocumentCard: React.FC<DocumentCardProps> = ({
  data,
  isInDrawer,
}) => {
  const { t } = useTranslation("correspondence");
  const theme = useTheme();
  const [{ lgDown, tabletDown }] = useMedia();

  const locale = getStorageItem(STORAGE_KEYS.lang);
  const isEnglish = locale === "en";

  const {
    statusForCorrespondence,
    sender,
    recipients,
    internalRegistrationNumber,
    topicForTheCorrespondence,
    correspondenceSubtype,
    dateOfRegistration,
    correspondenceType,
    registrationDate,
    createdAt,
    textForTopic,
    id,
  } = data;

  const isTypeServiceNote =
    data.correspondenceType === CorrespondenceTypeEnum.serviceNote;

  const recipient = recipients[0];
  const otherRecipients = recipients.slice(1);

  const showStructuralUnit = (type?: {
    nameOfStructuralUnit: string;
    structuralUnitType: StructuralUnitTypesForCorrespondenceEnum;
  }) => {
    if (!type) return false;
    if (
      type.structuralUnitType ===
        StructuralUnitTypesForCorrespondenceEnum.goveringBody ||
      type.structuralUnitType ===
        StructuralUnitTypesForCorrespondenceEnum.mainGoverningBody
    ) {
      return false;
    }
    return true;
  };

  const tooltipContent = otherRecipients?.map((r, index) => {
    return (
      <Stack
        flexDirection="column"
        key={index}
        borderTop={!index ? 0 : 1}
        borderColor={hexToRgba(theme.palette.secondary.contrastText, 0.7)}
        my={2}
        pt={!index ? 0 : 2}
      >
        <Tooltip
          arrow
          placement="top"
          title={`${r.personalDataLongName} / ${r.position}`}
          disableInteractive
        >
          <EllipsisTextWrapper $height={17}>
            <Stack flexDirection="column">
              <Typography
                variant="body3"
                color={hexToRgba(theme.palette.primary.contrastText, 0.7)}
              >
                {`${
                  r.personalDataShortName
                    ? r.personalDataShortName
                    : r.personalDataLongName
                }${r.position ? " / " : " "}
                `}

                {r.position || `(${t("viewDocument.individual")})`}
              </Typography>
            </Stack>
          </EllipsisTextWrapper>
        </Tooltip>
        {isTypeServiceNote && showStructuralUnit(r.structuralUnit) && (
          <EllipsisTextWrapper $height={17}>
            <Typography
              variant="body3"
              fontWeight="bold"
              color={hexToRgba(theme.palette.primary.contrastText, 0.7)}
            >
              {r.structuralUnit && r.structuralUnit.nameOfStructuralUnit}
            </Typography>
          </EllipsisTextWrapper>
        )}

        <EllipsisTextWrapper $height={17}>
          <Typography
            variant="body3"
            fontWeight="bold"
            color={hexToRgba(theme.palette.primary.contrastText, 0.7)}
          >
            {r.briefTextOrganizationName}
          </Typography>
        </EllipsisTextWrapper>
      </Stack>
    );
  });

  return (
    <DocumentCardWrapper>
      <Stack
        width="100%"
        maxWidth={lgDown || tabletDown || isInDrawer ? "100%" : "60%"}
      >
        <Stack flexDirection="row" alignItems="flex-start" gap={4}>
          <Icon name="Mail" size={48} />
          <Stack gap={1}>
            <div>
              <Typography
                variant="h8_bold"
                sx={{ color: theme.palette.primary.contrastText }}
              >
                {t(`document.${correspondenceType}`)}
              </Typography>
              {!isTypeServiceNote && (
                <Typography
                  variant="h8_bold"
                  fontWeight={400}
                  sx={{ color: theme.palette.primary.contrastText }}
                >
                  {` (${t(`documentType.${correspondenceSubtype}`)})`}
                </Typography>
              )}
            </div>

            <Typography
              variant="body3"
              sx={{ color: hexToRgba(theme.palette.primary.contrastText, 0.7) }}
            >
              {`${internalRegistrationNumber} ${t(
                "createLetterDrawer.registration_area.from"
              )} ${getLocaleDateFormat(registrationDate)}`}
            </Typography>
          </Stack>
          <Stack>
            <CorrespondenceStatusChip status={statusForCorrespondence} />
          </Stack>
        </Stack>

        <Stack mt={8} mb={4}>
          <Typography
            variant="subtitle2"
            fontWeight={600}
            sx={{ color: theme.palette.primary.contrastText }}
          >
            {t("correspondenceCard.topic")}
          </Typography>
          <Tooltip
            title={t(textForTopic || topicForTheCorrespondence || "")}
            arrow
          >
            <EllipsisTextWrapper>
              <Typography
                variant="body3"
                component="div"
                className="ellipsis"
                color={hexToRgba(theme.palette.primary.contrastText, 0.7)}
              >
                {t(textForTopic || topicForTheCorrespondence || "")}
              </Typography>
            </EllipsisTextWrapper>
          </Tooltip>
        </Stack>
        <Grid container spacing={8}>
          <Grid item xs={6} md={3} xl={3}>
            <Stack>
              <Typography
                variant="subtitle2"
                fontWeight={600}
                sx={{ color: theme.palette.primary.contrastText }}
              >
                {t("correspondenceCard.sender_from")}
              </Typography>
              {sender.legalEntity ? (
                <Typography
                  variant="body3"
                  sx={{
                    color: hexToRgba(theme.palette.primary.contrastText, 0.7),
                  }}
                >
                  <Stack flexDirection="column">
                    <Tooltip
                      arrow
                      placement="top"
                      title={`${sender.personalDataLongName} / ${sender.position}`}
                      disableInteractive
                    >
                      <EllipsisTextWrapper $height={17}>
                        <Typography
                          variant="body3"
                          component="div"
                          color={hexToRgba(
                            theme.palette.primary.contrastText,
                            0.7
                          )}
                        >
                          {`${
                            sender.personalDataShortName
                              ? sender.personalDataShortName
                              : sender.personalDataLongName
                          } / ${sender.position}`}
                        </Typography>
                      </EllipsisTextWrapper>
                    </Tooltip>
                    {isTypeServiceNote &&
                      showStructuralUnit(sender.structuralUnit) && (
                        <Tooltip
                          arrow
                          placement="top"
                          title={
                            sender.structuralUnit &&
                            sender.structuralUnit.nameOfStructuralUnit
                          }
                          disableInteractive
                        >
                          <EllipsisTextWrapper $height={17}>
                            <Typography
                              variant="body3"
                              component="div"
                              color={hexToRgba(
                                theme.palette.primary.contrastText,
                                0.7
                              )}
                            >
                              {sender.structuralUnit &&
                                sender.structuralUnit.nameOfStructuralUnit}
                            </Typography>
                          </EllipsisTextWrapper>
                        </Tooltip>
                      )}

                    <Tooltip
                      arrow
                      placement="top"
                      title={sender.briefTextOrganizationName}
                      disableInteractive
                    >
                      <EllipsisTextWrapper $height={17}>
                        <Typography
                          variant="body3"
                          fontWeight="bold"
                          color={hexToRgba(
                            theme.palette.primary.contrastText,
                            0.7
                          )}
                        >
                          {sender.briefTextOrganizationName}
                        </Typography>
                      </EllipsisTextWrapper>
                    </Tooltip>
                  </Stack>
                </Typography>
              ) : (
                <Typography
                  variant="body3"
                  sx={{
                    color: hexToRgba(theme.palette.primary.contrastText, 0.7),
                  }}
                >
                  <Stack flexDirection="column">
                    <Tooltip
                      arrow
                      placement="top"
                      title={`${sender.personalDataLongName} ${
                        sender.position ? "/" : ""
                      }${
                        sender.position || `(${t("viewDocument.individual")})`
                      }`}
                      disableInteractive
                    >
                      <EllipsisTextWrapper $height={17}>
                        <Typography
                          variant="body3"
                          component="div"
                          color={hexToRgba(
                            theme.palette.primary.contrastText,
                            0.7
                          )}
                        >
                          {`${
                            sender.personalDataShortName
                              ? sender.personalDataShortName
                              : sender.personalDataLongName
                          } ${sender.position ? "/" : ""} ${
                            sender.position ||
                            `(${t("viewDocument.individual")})`
                          }`}
                        </Typography>
                      </EllipsisTextWrapper>
                    </Tooltip>
                    {isTypeServiceNote &&
                      showStructuralUnit(sender.structuralUnit) && (
                        <Tooltip
                          arrow
                          placement="top"
                          title={
                            sender.structuralUnit &&
                            sender.structuralUnit.nameOfStructuralUnit
                          }
                          disableInteractive
                        >
                          <EllipsisTextWrapper $height={17}>
                            <Typography
                              variant="body3"
                              component="div"
                              color={hexToRgba(
                                theme.palette.primary.contrastText,
                                0.7
                              )}
                            >
                              {sender.structuralUnit &&
                                sender.structuralUnit.nameOfStructuralUnit}
                            </Typography>
                          </EllipsisTextWrapper>
                        </Tooltip>
                      )}
                    <Tooltip
                      arrow
                      placement="top"
                      title={sender.briefTextOrganizationName}
                      disableInteractive
                    >
                      <EllipsisTextWrapper $height={17}>
                        <Typography
                          variant="body3"
                          fontWeight="bold"
                          color={hexToRgba(
                            theme.palette.primary.contrastText,
                            0.7
                          )}
                        >
                          {sender.briefTextOrganizationName}
                        </Typography>
                      </EllipsisTextWrapper>
                    </Tooltip>
                  </Stack>
                </Typography>
              )}
            </Stack>
          </Grid>
          <Grid item xs={6} md={4} xl={4}>
            <Stack>
              <Stack display="flex" flexDirection="row" gap={2}>
                <Typography
                  variant="subtitle2"
                  fontWeight={600}
                  sx={{ color: theme.palette.primary.contrastText }}
                >
                  {t("correspondenceCard.recipient_to")}
                </Typography>
                {recipients.length - 1 > 0 ? (
                  <Tooltip
                    arrow
                    placement="top"
                    title={tooltipContent}
                    disableInteractive
                  >
                    <div className="recipientCount">
                      <Typography
                        variant="subtitle2"
                        fontWeight={600}
                        sx={{ color: theme.palette.primary.contrastText }}
                      >
                        {`+${recipients.length - 1}`}
                      </Typography>
                    </div>
                  </Tooltip>
                ) : null}
              </Stack>

              <Typography
                variant="body3"
                sx={{
                  color: hexToRgba(theme.palette.primary.contrastText, 0.7),
                }}
              >
                <Stack flexDirection="column">
                  <Tooltip
                    arrow
                    placement="top"
                    title={`${
                      recipient.personalDataShortName
                        ? recipient.personalDataShortName
                        : recipient.personalDataLongName
                    }${recipient.position ? " / " : " "}
                   ${
                     recipient.position || `(${t("viewDocument.individual")})`
                   }`}
                    disableInteractive
                  >
                    <EllipsisTextWrapper $height={17}>
                      <div>
                        <Typography
                          variant="body3"
                          color={hexToRgba(
                            theme.palette.primary.contrastText,
                            0.7
                          )}
                        >
                          {`${
                            recipient.personalDataShortName
                              ? recipient.personalDataShortName
                              : recipient.personalDataLongName
                          }${recipient.position ? " / " : " "}
                `}
                          {recipient.position ||
                            `(${t("viewDocument.individual")})`}
                        </Typography>
                      </div>
                    </EllipsisTextWrapper>
                  </Tooltip>
                  {isTypeServiceNote &&
                    showStructuralUnit(recipient.structuralUnit) && (
                      <Tooltip
                        arrow
                        placement="top"
                        title={`${
                          isTypeServiceNote &&
                          recipient.structuralUnit &&
                          recipient.structuralUnit.nameOfStructuralUnit
                        }`}
                        disableInteractive
                      >
                        <EllipsisTextWrapper $height={17}>
                          <div>
                            <Typography
                              variant="body3"
                              color={hexToRgba(
                                theme.palette.primary.contrastText,
                                0.7
                              )}
                            >
                              {isTypeServiceNote &&
                                recipient.structuralUnit &&
                                recipient.structuralUnit.nameOfStructuralUnit}
                            </Typography>
                          </div>
                        </EllipsisTextWrapper>
                      </Tooltip>
                    )}
                  <Tooltip
                    arrow
                    disableInteractive
                    placement="top"
                    title={recipient.briefTextOrganizationName}
                  >
                    <EllipsisTextWrapper $height={17}>
                      <Typography
                        variant="body3"
                        fontWeight="bold"
                        color={hexToRgba(
                          theme.palette.primary.contrastText,
                          0.7
                        )}
                      >
                        {recipient.briefTextOrganizationName}
                      </Typography>
                    </EllipsisTextWrapper>
                  </Tooltip>
                </Stack>
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={6} md={2.5} xl={2.5}>
            <Stack>
              <Typography
                variant="subtitle2"
                fontWeight={600}
                sx={{ color: theme.palette.primary.contrastText }}
              >
                {t("correspondenceCard.reg_number")}
              </Typography>
              <Typography
                variant="body3"
                sx={{
                  color: hexToRgba(theme.palette.primary.contrastText, 0.7),
                }}
              >
                {id}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={6} md={2.5} xl={2.5}>
            <Stack>
              <Typography
                variant="subtitle2"
                fontWeight={600}
                sx={{ color: theme.palette.primary.contrastText }}
              >
                {t("correspondenceCard.registration_date")}
              </Typography>
              <Typography
                variant="body3"
                sx={{
                  color: hexToRgba(theme.palette.primary.contrastText, 0.7),
                }}
              >
                {getLocaleDateFormat(createdAt)}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Stack>

      {lgDown || tabletDown || isInDrawer ? null : (
        <Stack>
          <DocumentCardImage
            src={cardImage(correspondenceType, isEnglish)}
            alt="task card icon"
          />
        </Stack>
      )}
    </DocumentCardWrapper>
  );
};
