import styled from "styled-components";
import { FLEX_ROW_ALIGN_CENTER, FLEX_ROW_FULL } from "ui-kit";

export const CellWrapper = styled.div<{ $isSentToSBM: boolean }>`
  ${FLEX_ROW_ALIGN_CENTER};
  gap: ${({ theme }) => theme.spacing()};
  font-weight: ${({ $isSentToSBM }) => ($isSentToSBM ? 700 : 400)};
`;

export const AbbreviationWrapper = styled.div`
  ${FLEX_ROW_FULL};
  border: 1px solid ${({ theme }) => theme.palette.text.primary};
  height: 20px;
  min-width: 30px;
  border-radius: ${({ theme }) => theme.spacing()};
  gap: ${({ theme }) => theme.spacing(2)};
`;
