import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useConfirmApprovalRoute, useGetDefaultApprovalRoute } from "api";
import {
  closeDrawer,
  resetTaskCreationStep,
  setModal,
  TransitionPrompt,
  UI_CONFIG,
  useAppDispatch,
  useAppSelector,
} from "app";
import { Drawer, Icon, Table } from "ui-kit";

import { Stack, Typography, useTheme } from "@mui/material";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid-pro";
import {
  COLUMN_SIZE,
  getPersonalDataName,
  SnackbarMessages,
} from "@sbm/fe-utils";
import { Button } from "@sbm/ui-components";
import { useQueryClient } from "@tanstack/react-query";
import {
  ApproverActions,
  CorrespondenceTypeEnum,
  IAddAgreement,
  IDocumentApprovalRoute,
  ModalVariants,
} from "@types";

import { getAgreementQueueTableColumns } from "../../../../agreements/helpers";
import { ApprovalSubject } from "./ApprovalSubject";
import { TitleWrapper, Wrapper } from "./styles";

interface Props {
  correspondenceId: number | undefined;
  correspondenceType: CorrespondenceTypeEnum;
}

export const AdjustAgreementRoute = ({
  correspondenceId,
  correspondenceType,
}: Props) => {
  const { t } = useTranslation("correspondence");
  const { t: tCommon } = useTranslation("");
  const [localAgreementRows, setLocalAgreementRows] = useState<
    GridValidRowModel[]
  >([]);
  const { data, isLoading } = useGetDefaultApprovalRoute(correspondenceId);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const queryClient = useQueryClient();

  const { drawer } = useAppSelector((state) => state.global);

  const onSubmitSuccess = async () => {
    toast.success(SnackbarMessages.success);
    await queryClient.invalidateQueries(["get_default_approval_route"]);

    handleClose();
  };

  const onErrorSuccess = () => {
    toast.error(SnackbarMessages.error);
    handleClose();
  };

  const { mutate: confirmRoute, isLoading: isConfirmApprovalLoading } =
    useConfirmApprovalRoute(onSubmitSuccess, onErrorSuccess);

  const [showPrompt, setShowPrompt] = useState(false);

  const handleCloseDrawer = () => {
    dispatch(resetTaskCreationStep());
    dispatch(closeDrawer());
  };

  const handleClose = () => {
    handleCloseDrawer();
  };

  const handleClosePrompt = () => {
    setShowPrompt(false);
  };

  const handleCancel = () => {
    handleClose();
  };

  const createReqBody = (data: IDocumentApprovalRoute[]) => {
    const body = data.map((item) => {
      return {
        queueNumber: item.queueNumber,
        employeeId: item.externalSubstitutionId || item.employeeId,
        amountOfDays: item.amountOfDays,
      };
    });
    if (correspondenceType === CorrespondenceTypeEnum.outgoingDocument) {
      return {
        type: correspondenceType,
        outgoingDocumentId: correspondenceId,
        approvers: body,
      };
    }
    if (correspondenceType === CorrespondenceTypeEnum.serviceNote) {
      return {
        type: correspondenceType,
        serviceNoteId: correspondenceId,
        approvers: body,
      };
    }
  };

  const handleConfirm = () => {
    const requestBody = createReqBody(localAgreementRows);
    confirmRoute(requestBody);
  };

  const rows: GridValidRowModel[] = React.useMemo(() => {
    return data
      ? data.approvers.map((item, index) => ({
          ...item,
          id: index,
          approverType: t(item.approverMission),
          queueNumber: item.queueNumber,
          structuralUnitId: item.structuralUnitId || undefined,
          position: t(item.positionName),
          longName: item.personalData
            ? getPersonalDataName(item.personalData)
            : "",
          status: t(item?.status?.statusSingleApproval as string),
          singleApprovalId: item?.status?.singleApprovalId,
        }))
      : [];
  }, [data, t]);

  useEffect(() => {
    setLocalAgreementRows(rows);
  }, [data, rows]);

  const isApprovalEnded = Boolean(
    data?.approvers?.every((item) => {
      return item.status?.statusSingleApproval === ApproverActions.approved;
    })
  );

  const handleChangeDuration = () => {
    console.log("");
  };

  const columns: GridColDef[] = React.useMemo(() => {
    const defaultColumns: GridColDef[] = [
      {
        field: "position",
        minWidth: COLUMN_SIZE * 5,
        headerName: t("approval_subject"),
        sortable: false,
        filterable: false,
        cellClassName: "approval-table__position-cell",
        renderCell: (params) => {
          return (
            <ApprovalSubject
              name={params.row.longName}
              position={params.row.position}
              approverType={params.row.approverType}
              amountOfDays={params.row.amountOfDays}
            />
          );
        },
      },
    ];

    if (!isApprovalEnded) {
      const queueColumns = getAgreementQueueTableColumns(rows, tCommon, theme);

      return [...defaultColumns, ...queueColumns];
    }

    return [...defaultColumns];
  }, [t, isApprovalEnded, rows, theme, tCommon]);

  const handleUpdateLocalApprovalRoutes = (arg: IAddAgreement) => {
    const newItem = [{}]; //todo need use arg as new item, and set localAgreementRows
    setLocalAgreementRows([...localAgreementRows]);
  };

  const addApprover = () => {
    dispatch(
      setModal({
        open: true,
        variant: ModalVariants.addAgreementRouteApprover,
        content: {
          data: {
            duration: 1,
            queueCount: columns.length - 2, //-2 ==> position column + last column,
            onUpdate: (arg: IAddAgreement) =>
              handleUpdateLocalApprovalRoutes(arg),
          },
        },
      })
    );
  };

  return (
    <>
      {showPrompt && (
        <TransitionPrompt
          open={showPrompt}
          onClose={handleClosePrompt}
          onConfirm={handleCloseDrawer}
        />
      )}

      <Drawer
        title={t("set_up_approval")}
        open={Boolean(drawer)}
        onClose={handleCancel}
        width={UI_CONFIG.rightDrawerFullWidth}
        actions={
          <Stack flexDirection="row" justifyContent="flex-end" gap={4}>
            <Button
              variant="outlined"
              onClick={handleCancel}
              color="secondary"
              size="large"
            >
              {t("cancel")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={handleConfirm}
            >
              {t("confirm")}
            </Button>
          </Stack>
        }
      >
        <Wrapper>
          <TitleWrapper>
            <Typography variant="h6" color="text.secondary" fontWeight={600}>
              {t("approval_route")}
            </Typography>
            <Button
              variant="text"
              color="secondary"
              onClick={addApprover}
              startIcon={
                <Icon name="UserPlus2" color={theme.palette.secondary.main} />
              }
            >
              {t("add_approver")}
            </Button>
          </TitleWrapper>

          <Table
            rows={localAgreementRows}
            columns={columns}
            hasPagination={false}
            checkboxSelection={false}
            loading={isLoading}
            getRowHeight={() => "auto"}
          />
        </Wrapper>
      </Drawer>
    </>
  );
};
