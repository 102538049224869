enum MimeTypesEnum {
  pdf = "application/pdf",
  doc = "application/msword",
  docx = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  jpg = "image/jpg",
  jpeg = "image/jpeg",
  png = "image/png",
  bmp = "image/bmp",
  dwg = "image/x-dwg",
  octec = "application/octet-stream",
  xls = "application/vnd.ms-excel",
  xlsx = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
}

export const compareObject = (obj1: any, obj2: any) => {
  let equal = true;

  for (const key in obj1) {
    equal = obj1[key] === obj2[key];

    if (!equal) return false;
  }

  return true;
};

export function isEmpty(obj: any) {
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      return false;
    }
  }
  return true;
}

export const arrayEquals = (a: any[], b: any[], deepCompare = false) => {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => {
      if (deepCompare) return compareObject(val, b[index]);

      return val === b[index];
    })
  );
};

export function downloadFile(url: string, fileName: string) {
  fetch(url)
    .then((resp) => (resp.status === 200 ? resp.blob() : Promise.reject("something went wrong")))
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((e) => console.error("failed download", e));
}

export const PREVIEW_MIME_TYPES = [
  MimeTypesEnum.pdf,
  MimeTypesEnum.jpg,
  MimeTypesEnum.jpeg,
  MimeTypesEnum.png,
  MimeTypesEnum.bmp,
  MimeTypesEnum.dwg,
];

export const MS_OFFICE_MIME_TYPES = [MimeTypesEnum.doc, MimeTypesEnum.docx, MimeTypesEnum.xls, MimeTypesEnum.xlsx];

export function getUniqueListBy(arr: any[], key: string) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}
