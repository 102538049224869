import { axiosService } from "app";

export const fetchLinkToFile = async (filename: string | undefined) => {
  if (!filename) return "";

  const { data } = await axiosService({
    endpoint: `contract-attached-files/get-link`,
    body: { filename },
  });

  return data;
};
