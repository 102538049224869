import React from "react";
import { useTranslation } from "react-i18next";

import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { IApprovalRoute } from "@types";

import { Wrapper } from "../ApprovalContainer/styles";
import { ViewChosenApproversGanttChart } from "./components";

interface Props {
  data?: IApprovalRoute[] | undefined;
  selectedRow: IApprovalRoute | null | undefined;
  setSelectedRow: (arg: IApprovalRoute) => void;
}

export const ApprovalRouteChangeContainer: React.FC<Props> = ({
  data,
  selectedRow,
  setSelectedRow,
}) => {
  const { t } = useTranslation();

  const handleSelectRoute = (row: IApprovalRoute) => {
    setSelectedRow(row);
  };

  return (
    <Wrapper>
      <FormControl sx={{ mb: 8 }}>
        <FormLabel>{t("route")}</FormLabel>

        <RadioGroup defaultValue={selectedRow?.id} sx={{ pl: 2 }}>
          {data?.map((item, index) => {
            return (
              <FormControlLabel
                key={item.id + index}
                value={item.id}
                onClick={() => handleSelectRoute(item)}
                control={<Radio color="secondary" />}
                sx={{ alignItems: "start" }}
                label={
                  <Stack mt={2}>
                    <Typography variant="body1">{item.routeNumber}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {item.additionalInformation}
                    </Typography>
                  </Stack>
                }
              />
            );
          })}
        </RadioGroup>
      </FormControl>

      <ViewChosenApproversGanttChart selectedRow={selectedRow} data={data} />
    </Wrapper>
  );
};
