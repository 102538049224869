import {
  FileAttachedToCorrespondenceFileStatusEnum,
  FileAttachedToCorrespondenceTypeOfAttachmentEnum,
} from "@types";

import { IAttachesCorrespondenceFileForm } from "./constants";

export const createRequestBody = (body: IAttachesCorrespondenceFileForm) => {
  const formData = new FormData();
  if (body.files?.length) {
    body.files?.forEach((file) => {
      formData.append("files", file);
    });
  }
  if (body.descriptionForAttachment) {
    formData.append("descriptionForAttachment", body.descriptionForAttachment);
  }
  if (body.fileAppointment) {
    formData.append("fileAssignment", body.fileAppointment);
  }
  if (body.fileStatus) {
    formData.append("fileStatus", body.fileStatus);
  }
  return formData;
};

export const getDisableState = (
  fileAppointment: FileAttachedToCorrespondenceTypeOfAttachmentEnum | undefined,
  type: FileAttachedToCorrespondenceFileStatusEnum
) => {
  if (!fileAppointment) return;
  switch (type) {
    case FileAttachedToCorrespondenceFileStatusEnum.draftDocument:
      switch (fileAppointment) {
        case FileAttachedToCorrespondenceTypeOfAttachmentEnum.supportingFile:
          return true;
        default:
          return false;
      }
    case FileAttachedToCorrespondenceFileStatusEnum.signedDocument:
      switch (fileAppointment) {
        case FileAttachedToCorrespondenceTypeOfAttachmentEnum.supportingFile:
          return true;
        default:
          return false;
      }
    default:
      return false;
  }
};
