import { createSlice } from "@reduxjs/toolkit";
import { TasksTabEnum, TasksViewEnum } from "@types";

interface ITask {
  taskCreationStep: number;
  currentTab: TasksTabEnum;
  currentTaskView: TasksViewEnum;
}

export const initialStateTask: ITask = {
  taskCreationStep: 0,
  currentTab: TasksTabEnum.incoming,
  currentTaskView: TasksViewEnum.table,
};

export const taskSlice = createSlice({
  name: "task",
  initialState: initialStateTask,
  reducers: {
    resetTaskCreationStep: (state) => {
      state.taskCreationStep = 0;
    },
    setChangeTaskCreationStep: (state, action) => {
      state.taskCreationStep = state.taskCreationStep + action.payload;
    },
    setTaskTab: (state, action) => {
      state.currentTab = action.payload;
    },
    setTaskViewTab: (state, action) => {
      state.currentTaskView = action.payload;
    },
  },
});

export const {
  setChangeTaskCreationStep,
  resetTaskCreationStep,
  setTaskTab,
  setTaskViewTab,
} = taskSlice.actions;

export default taskSlice.reducer;
