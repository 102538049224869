import { useEffect, useRef } from "react";
import { useAuth } from "react-oidc-context";

import { io, Socket } from "socket.io-client";

import { environment } from "../../../environments/environment";

export function useSocket() {
  const auth = useAuth();
  const socketInstance = useRef<Socket | null>(null);

  useEffect(() => {
    if (!socketInstance.current) {
      const token = auth.user?.access_token;

      const socket = io(environment.WSApiUrl, {
        reconnectionDelayMax: 10000,
        auth: {
          token,
        },
      });

      socketInstance.current = socket;
    }
  }, [auth.user?.access_token]);

  useEffect(() => {
    if (!socketInstance.current) return;

    socketInstance.current.io.on("error", (error) => {
      console.log(error, "socket error");
    });
  }, []);

  return { socket: socketInstance.current };
}
