import {
  EnumEmployeeType,
  IStructuralUnit,
  TypeEmploymentRelationshipEnum,
} from "./organizations";

export enum StructuralUnitTypesEnum {
  mainGoverningBody = "main_govering_body",
  goveringBody = "govering_body",
  functionalBlock = "functional_block",
  department = "department",
  division = "division",
  sector = "sector",
  directoriateForManufactoring = "directoriate_for_manufactoring",
  unitForManufactoring = "unit_for_manufactoring",
  groupForManufactoring = "group_for_manufactoring",
}

export enum ScheduleTreeItemStatus {
  vacancy = "vacancy",
  busy = "busy",
  externalOverlap = "external-overlap",
}

export const structuralUnitConfigurations = {
  [StructuralUnitTypesEnum.mainGoverningBody]: {
    allowedChildren: [
      StructuralUnitTypesEnum.goveringBody,
      StructuralUnitTypesEnum.functionalBlock,
      StructuralUnitTypesEnum.department,
      StructuralUnitTypesEnum.division,
      StructuralUnitTypesEnum.sector,
      StructuralUnitTypesEnum.directoriateForManufactoring,
      StructuralUnitTypesEnum.unitForManufactoring,
      StructuralUnitTypesEnum.groupForManufactoring,
    ],
  },
  [StructuralUnitTypesEnum.goveringBody]: {
    allowedChildren: [
      StructuralUnitTypesEnum.functionalBlock,
      StructuralUnitTypesEnum.department,
      StructuralUnitTypesEnum.division,
      StructuralUnitTypesEnum.sector,
      StructuralUnitTypesEnum.directoriateForManufactoring,
      StructuralUnitTypesEnum.unitForManufactoring,
      StructuralUnitTypesEnum.groupForManufactoring,
    ],
  },
  [StructuralUnitTypesEnum.functionalBlock]: {
    allowedChildren: [
      StructuralUnitTypesEnum.department,
      StructuralUnitTypesEnum.division,
      StructuralUnitTypesEnum.sector,
      StructuralUnitTypesEnum.directoriateForManufactoring,
      StructuralUnitTypesEnum.unitForManufactoring,
      StructuralUnitTypesEnum.groupForManufactoring,
    ],
  },
  [StructuralUnitTypesEnum.department]: {
    allowedChildren: [
      StructuralUnitTypesEnum.division,
      StructuralUnitTypesEnum.sector,
      StructuralUnitTypesEnum.directoriateForManufactoring,
      StructuralUnitTypesEnum.unitForManufactoring,
      StructuralUnitTypesEnum.groupForManufactoring,
    ],
  },
  [StructuralUnitTypesEnum.division]: {
    allowedChildren: [StructuralUnitTypesEnum.sector],
  },
  [StructuralUnitTypesEnum.sector]: {
    allowedChildren: [],
  },
  [StructuralUnitTypesEnum.directoriateForManufactoring]: {
    allowedChildren: [
      StructuralUnitTypesEnum.unitForManufactoring,
      StructuralUnitTypesEnum.groupForManufactoring,
    ],
  },
  [StructuralUnitTypesEnum.unitForManufactoring]: {
    allowedChildren: [StructuralUnitTypesEnum.groupForManufactoring],
  },
  [StructuralUnitTypesEnum.groupForManufactoring]: {
    allowedChildren: [],
  },
};

export interface ICreateStructuralUnit {
  additionalInformation?: string;
  organizationId: number;
  structuralUnitType: StructuralUnitTypesEnum;
  parentStructuralUnitId?: number;
  nameOfStructuralUnit: string;
  additionalOptions?: boolean;
  functions?: string[];
}

export interface ICreateStaffUnit {
  additionalInformation?: string;
  structuralUnitId?: number;
  positionId?: number;
  headOfStructuralUnit?: boolean;
  validity?: boolean;
  orders?: { staffUnitId: number; numberForTheList: number }[];
}

export interface IAddAgreement {
  employeeId: number | null;
  nameOfStructuralUnit?: string;
  duration: number;
  queueNumber: number;
  positionName?: string;
  title: string;
}

export interface ICreateEmployee {
  structuralUnitId?: number;
  positionId?: number;
  additionalInformation?: string;
  employeeType?: EnumEmployeeType;
  validity?: boolean;
  staffUnitId?: number;
  personalDataId?: number;
  employeeId?: number;
  employeeIdUpdate?: number;
  name?: string;
  nameOfPosition?: string;
  additionalOptions?: boolean;
  typeEmploymentRelationship?: TypeEmploymentRelationshipEnum;
}

export interface IStructuralUnitTree {
  treeBranch: {
    nodes: {
      id: number;
      parentId: number | null;
      node: any;
      children: IStructuralUnit[];
    }[];
  };
  parentWithChildren: null | {
    id: number;
    additionalInformation: string;
    nameOfStructuralUnit: string;
    structuralUnitType: string;
    numberForChildList: null | number;
    validity: boolean;
    children: {
      id: number;
      additionalInformation: string;
      nameOfStructuralUnit: string;
      structuralUnitType: string;
      numberForChildList: number;
      validity: boolean;
    }[];
  };
  currentStructuralUnit: {
    additionalInformation: string;
    id: number;
    functions: FunctionEnum[];
    nameOfStructuralUnit: string;
    numberForChildList: number | null;
    structuralUnitType: string;
    validity: boolean;
  };
}

export enum FunctionEnum {
  contractWork = "contract_work",
  legalSupport = "legal_support",
  supply = "supply",
  accounting = "accounting",
}
