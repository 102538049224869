import React from "react";
import { Control, UseFormSetValue, useWatch } from "react-hook-form";

import { Icon } from "ui-kit";

import { Grid, Typography, useTheme } from "@mui/material";
import { IAttachLink, ICreateTask, TypeOfAttachmentEnum } from "@types";

import { Wrapper, LinksItemWrapper } from "./styles";

interface Props {
  control: Control<ICreateTask>;
  setValue: UseFormSetValue<ICreateTask>;
}

export const AttachedLink = ({ control, setValue }: Props) => {
  const theme = useTheme();
  const { links } = useWatch({
    control,
  });

  const onRemoveAttachedLink = (id?: number) => {
    if (!id) {
      return;
    }
    const updatedList = links?.filter((link) => link.id !== id);
    setValue("links", updatedList as IAttachLink[]);
  };

  return (
    <Wrapper>
      {links?.length
        ? links.map((link) => {
            return (
              <LinksItemWrapper key={link.id}>
                <Grid container>
                  <Grid item xs={1} className="link_delete_icon">
                    <Icon name="Link" color={theme.palette.secondary.main} />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography
                      variant="body2"
                      fontWeight={600}
                      color="secondary.main"
                    >
                      {link.documentName}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} className="link_delete_icon">
                    <Icon
                      name="X"
                      color={theme.palette.primary.main}
                      disabled={
                        link.typeOfAttachment ===
                        TypeOfAttachmentEnum.taskSubject
                      }
                      onClick={() =>
                        link.typeOfAttachment ===
                        TypeOfAttachmentEnum.taskSubject
                          ? undefined
                          : onRemoveAttachedLink(link.id)
                      }
                    />
                  </Grid>
                </Grid>
              </LinksItemWrapper>
            );
          })
        : null}
    </Wrapper>
  );
};
