import React, { useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  useDeleteTaskMutation,
  useEditDraftTask,
  useEditTask,
  useGetPotentialExecutors,
} from "api";
import {
  closeDrawer,
  setModal,
  TransitionPrompt,
  useAppDispatch,
  useAppSelector,
} from "app";
import { Drawer, Icon } from "ui-kit";

import {
  Autocomplete,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { arrayEquals } from "@sbm/fe-utils";
import { Button, Input } from "@sbm/ui-components";
import {
  IEditTask,
  ITaskById,
  ModalVariants,
  StatusForAuthorEnum,
} from "@types";

import { orderTextMaxLength } from "./constants";
import {
  createEditDraftTaskRequestBody,
  createEditTaskRequestBody,
  getFieldsDisableState,
} from "./helpers";
import {
  IWorkingDateData,
  WorkingDay,
} from "./steps/Step1SelectOfExecuters/sections/ExecutionRequirements/WorkingDay";
import { CardWrapper, Wrapper } from "./styles";

interface Props {
  taskData: ITaskById;
}

export const EditTaskForm = ({ taskData }: Props) => {
  const { t } = useTranslation("tasks");
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { id: parentTaskId } = useParams();

  const { drawer, drawerType } = useAppSelector((state) => state.global);

  const [showPrompt, setShowPrompt] = useState(false);
  const { contractDirectories } = useAppSelector((state) => state.contracts);
  const form = useForm<IEditTask>({
    defaultValues: {
      highPriority: taskData.registration.priority,
      constructionComplexAffiliation:
        taskData.registration.constructionComplexAffiliation,
      affiliationConstructionComplex: Boolean(
        taskData.registration.constructionComplexAffiliation.length
      ),
      textOfTheTask: taskData.executionRequirements.textOfTheTask,
      personalControl: taskData.executionRequirements.personalControl,
      reportRequired: taskData.executionRequirements.reportRequired,
      scheduledDateOfExecution: taskData.executionRequirements.controlDate,
      scheduledTimeOfExecution: taskData.executionRequirements.controlTime,
      numberOfWorkingDays: taskData.executionRequirements.numberOfWorkingDays,
      calendarDaysForExecution:
        taskData.executionRequirements.numberOfCalendarDays,
      executor: {
        title: taskData.executorOfTheTask.employee.fullName,
        id: taskData.executorOfTheTask.employee.id,
      },
    },
  });

  const organizationId = taskData?.executorOfTheTask.organization.id;

  const onDeleteSuccess = () => {
    handleCloseDrawer();
  };

  const { mutate: deleteTask, isLoading: isRemoving } =
    useDeleteTaskMutation(onDeleteSuccess);

  const {
    reset,
    handleSubmit,
    control,
    getValues,
    setValue,
    register,
    formState: { errors, dirtyFields, isSubmitting },
  } = form;

  const handleCloseDrawer = () => {
    reset();
    dispatch(closeDrawer());
  };

  const handleClose = () => {
    handleCloseDrawer();
  };

  const handleClosePrompt = () => {
    setShowPrompt(false);
  };
  const fields = useWatch({ control });

  const {
    highPriority,
    affiliationConstructionComplex,
    constructionComplexAffiliation,
    textOfTheTask,
    personalControl,
    reportRequired,
    scheduledDateOfExecution,
    scheduledTimeOfExecution,
    numberOfWorkingDays,
    calendarDaysForExecution,
    executor,
  } = fields;

  const handleCancel = () => {
    handleClose();
  };

  const disableSTateForFields = useMemo(
    () =>
      getFieldsDisableState(
        taskData.typeOfTheTask,
        taskData.statusForAuthor,
        taskData.statusForExecutor,
        taskData.dateOfViewingOfTheTask
      ),
    [taskData]
  );

  const isDisableSaveButton = useMemo(() => {
    const fieldsToCheck = [
      {
        field: "textOfTheTask",
        value: textOfTheTask,
        comparison: taskData.executionRequirements.textOfTheTask,
        isEqual: textOfTheTask === taskData.executionRequirements.textOfTheTask,
      },
      {
        field: "scheduledDateOfExecution",
        value: scheduledDateOfExecution,
        comparison: taskData.executionRequirements.controlDate,
        isEqual:
          scheduledDateOfExecution ===
            taskData.executionRequirements.controlDate ||
          scheduledTimeOfExecution ===
            taskData.executionRequirements.controlTime,
      },
      {
        field: "scheduledTimeOfExecution",
        value: scheduledTimeOfExecution,
        comparison: taskData.executionRequirements.controlTime,
        isEqual:
          scheduledTimeOfExecution ===
          taskData.executionRequirements.controlTime,
      },
      {
        field: "reportRequired",
        value: reportRequired,
        comparison: taskData.executionRequirements.reportRequired,
        isEqual:
          reportRequired === taskData.executionRequirements.reportRequired,
      },
      {
        field: "numberOfWorkingDays",
        value: numberOfWorkingDays,
        comparison: taskData.executionRequirements.numberOfWorkingDays,
        isEqual:
          numberOfWorkingDays ===
          taskData.executionRequirements.numberOfWorkingDays,
      },
      {
        field: "calendarDaysForExecution",
        value: calendarDaysForExecution,
        comparison: taskData.executionRequirements.numberOfCalendarDays,
        isEqual:
          calendarDaysForExecution ===
          taskData.executionRequirements.numberOfCalendarDays,
      },
      {
        field: "highPriority",
        value: highPriority,
        comparison: taskData.registration.priority,
      },
      {
        field: "personalControl",
        value: personalControl,
        comparison: taskData.executionRequirements.personalControl,
        isEqual:
          personalControl === taskData.executionRequirements.personalControl,
      },
    ];
    const changedFieldsArr = fieldsToCheck.filter((field) => {
      if (field.value) {
        if (field.isEqual === false) {
          return field.value;
        }
      }
    });

    if (changedFieldsArr.length) {
      return false;
    }

    if (executor?.id !== taskData.executorOfTheTask.employee.id) {
      return false;
    }
    if (
      !constructionComplexAffiliation?.length &&
      affiliationConstructionComplex
    ) {
      return true;
    }
    if (
      !disableSTateForFields.constructionComplexAffiliationDisableState &&
      !arrayEquals(
        constructionComplexAffiliation as number[],
        taskData.registration.constructionComplexAffiliation
      )
    ) {
      return false;
    }
    return true;
  }, [
    highPriority,
    affiliationConstructionComplex,
    constructionComplexAffiliation,
    textOfTheTask,
    personalControl,
    reportRequired,
    scheduledDateOfExecution,
    numberOfWorkingDays,
    calendarDaysForExecution,
    executor,
    taskData,
    disableSTateForFields,
    scheduledTimeOfExecution,
  ]);

  const handleDeleteTask = () => {
    dispatch(
      setModal({
        open: true,
        variant: ModalVariants.removeTask,
        content: {
          data: {
            onRemove: () => {
              deleteTask(Number(parentTaskId));
            },
            state: {
              hasOneSiblingTask: taskData?.hasOneSiblingTask,
              areAllSiblingTasksDone: taskData?.areAllSiblingTasksDone,
              hasValidChildTasks: taskData?.hasValidChildTasks,
              parentTaskId: taskData?.parentTaskId,
            },
          },
        },
      })
    );
  };

  const onEditSuccess = () => {
    handleCloseDrawer();
  };
  const { mutate: editDraftTask, isLoading } = useEditDraftTask(onEditSuccess);

  const { mutate: editTask, isLoading: isLoadingEditTask } =
    useEditTask(onEditSuccess);

  const { data = [] } = useGetPotentialExecutors();

  const getExecutorsData = useMemo(() => {
    if (!organizationId) {
      return data;
    } else
      return data.filter((item) => item.organization.id === organizationId);
  }, [organizationId, data]);

  const options = getExecutorsData.map((i) => ({
    title: i.personalData.fullName || "",
    id: i.employeeId,
  }));

  const onSave = handleSubmit(async (data: IEditTask) => {
    if (
      taskData.statusForAuthor === StatusForAuthorEnum.draft ||
      (taskData.statusForAuthor === StatusForAuthorEnum.sentToExecutor &&
        !taskData.dateOfViewingOfTheTask)
    ) {
      const requestBody = createEditDraftTaskRequestBody(data);
      editDraftTask({
        data: requestBody,
        taskId: taskData.registration.taskNumber,
      });
    } else {
      const requestBody = createEditTaskRequestBody(
        data,
        taskData.typeOfTheTask,
        taskData.statusForAuthor,
        taskData.statusForExecutor
      );
      editTask({ data: requestBody, taskId: taskData.registration.taskNumber });
    }
  });

  const constructionComplexesIDS = useMemo(() => {
    if (!contractDirectories) return [];

    return contractDirectories.constructionComplex.map((i) => ({
      value: i.id,
      title: i?.briefTextConstructionComplexName,
    }));
  }, [contractDirectories]);

  const getDefaultValues = () => {
    const defaultConstructionComplexAffiliation = (
      constructionComplexAffiliation as {
        id: number;
        briefTextConstructionComplexName: string;
      }[]
    )?.map((i) => i.id);
    return (
      constructionComplexesIDS.filter((complex) =>
        defaultConstructionComplexAffiliation?.includes(complex.value)
      ) || []
    );
  };

  const handleConstructionComplexChange = (
    _: React.SyntheticEvent,
    values: {
      value: number;
      title: string;
    }[]
  ) => {
    const idsToSet = values.map((i) => i.value) as number[];

    setValue("constructionComplexAffiliation", idsToSet, { shouldDirty: true });
  };

  const handleWorkingDateChanged = (data: IWorkingDateData) => {
    setValue("scheduledDateOfExecution", data.date);
    setValue("scheduledTimeOfExecution", data.time);
    setValue("numberOfWorkingDays", data.workingDays);
    setValue("calendarDaysForExecution", data.calendarDays);
  };

  const handleInputChange = (
    _: React.SyntheticEvent,
    newValue: { title?: string; id?: number } | null
  ) => {
    if (newValue && newValue.id && newValue.title) {
      setValue("executor", newValue);
    }
  };

  const getConstructionFieldShow = () => {
    if (typeof affiliationConstructionComplex === "boolean") {
      return affiliationConstructionComplex;
    } else return Boolean(constructionComplexAffiliation?.length);
  };

  useEffect(() => {
    if (
      typeof affiliationConstructionComplex === "boolean" &&
      !affiliationConstructionComplex
    ) {
      setValue("constructionComplexAffiliation", []);
    }
  }, [affiliationConstructionComplex, setValue]);

  const scheduledDateOfExecutionParser = (date?: string) => {
    if (!date) return;
    const parseDate = date.split(".");
    const newDate = `${parseDate[2]}-${parseDate[1]}-${parseDate[0]}`;

    return newDate;
  };

  return (
    <>
      {showPrompt && (
        <TransitionPrompt
          open={showPrompt}
          onClose={handleClosePrompt}
          onConfirm={handleCloseDrawer}
        />
      )}

      <Drawer
        title={t("editTask")}
        open={Boolean(drawer)}
        onClose={handleCancel}
        actions={
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Stack>
              <Button
                variant="text"
                size="medium"
                color="error"
                sx={{ fontSize: "15px" }}
                onClick={handleDeleteTask}
                loading={isRemoving}
                disabled={disableSTateForFields.deleteBtnDisableState}
                startIcon={
                  <Icon name="Trash2" color={theme.palette.secondary.main} />
                }
              >
                {t("delete")}
              </Button>
            </Stack>
            <Stack direction="row" spacing={3}>
              <Button
                variant="outlined"
                onClick={handleCancel}
                color="secondary"
                size="large"
              >
                {t("cancel")}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                loading={isLoadingEditTask}
                onClick={onSave}
                disabled={isDisableSaveButton}
              >
                {t("save.task")}
              </Button>
            </Stack>
          </Stack>
        }
      >
        <Stack position="relative">
          <form noValidate>
            <CardWrapper>
              <Typography
                variant="h9_semiBold"
                color="text.disabled"
                sx={{ marginBottom: "8px" }}
              >
                {t("execution_requirements")}
              </Typography>
              <Stack mt={4}>
                {data && (
                  <Autocomplete
                    options={options}
                    defaultValue={executor}
                    disabled={disableSTateForFields.executorDisableState}
                    onChange={handleInputChange}
                    getOptionLabel={(option) => option?.title || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        color="secondary"
                        size="medium"
                        label="Исполнитель(и) *"
                      />
                    )}
                  />
                )}
              </Stack>
            </CardWrapper>
            <CardWrapper>
              <Typography variant="h9_semiBold" color="text.disabled">
                {t("execution_requirements")}
              </Typography>
              <Wrapper>
                <Input
                  label={t("order_text")}
                  variant="outlined"
                  disabled={disableSTateForFields.textOfTheTaskDisableState}
                  required
                  maxLength={orderTextMaxLength}
                  size="medium"
                  multiline
                  {...register("textOfTheTask")}
                />
                <WorkingDay
                  scheduledDateOfExecution={scheduledDateOfExecutionParser(
                    scheduledDateOfExecution
                  )}
                  scheduledTimeOfExecution={scheduledTimeOfExecution}
                  workingDaysForExecution={numberOfWorkingDays as number}
                  calendarDaysForExecution={calendarDaysForExecution}
                  onChange={handleWorkingDateChanged}
                  editMode={disableSTateForFields.workingDaysEditMode}
                  onChangeTime={(data) => {
                    setValue(`scheduledTimeOfExecution`, data);
                  }}
                />
                <Grid container>
                  <Grid item>
                    <FormControlLabel
                      label={t("personal_control")}
                      disabled={
                        disableSTateForFields.personalControlDisableState
                      }
                      control={
                        <Switch
                          color="secondary"
                          {...register("personalControl")}
                          defaultChecked={personalControl}
                        />
                      }
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      label={t("report_required")}
                      disabled={
                        disableSTateForFields.reportRequiredDisableState
                      }
                      control={
                        <Switch
                          color="secondary"
                          {...register("reportRequired")}
                          defaultChecked={reportRequired}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Wrapper>
            </CardWrapper>

            <CardWrapper>
              <Typography variant="h9_semiBold" color="text.disabled">
                {t("additional_information")}
              </Typography>
              <Wrapper>
                <FormControlLabel
                  label={t("higher_priority")}
                  disabled={
                    disableSTateForFields.highPrioritySwitchDisableState
                  }
                  control={
                    <Switch
                      color="secondary"
                      {...register("highPriority")}
                      defaultChecked={highPriority}
                    />
                  }
                />
                <FormControlLabel
                  label={t("affiliation_to_construction_complex")}
                  disabled={
                    disableSTateForFields.affiliationConstructionComplexDisableState
                  }
                  control={
                    <Switch
                      color="secondary"
                      {...register("affiliationConstructionComplex")}
                      defaultChecked={affiliationConstructionComplex}
                    />
                  }
                />
              </Wrapper>

              {getConstructionFieldShow() ? (
                <Autocomplete
                  multiple
                  options={constructionComplexesIDS}
                  disabled={
                    disableSTateForFields.constructionComplexAffiliationDisableState
                  }
                  onChange={handleConstructionComplexChange}
                  getOptionLabel={(option) => option.title}
                  defaultValue={getDefaultValues()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      color="secondary"
                      size="medium"
                      label={t("construction.complex.affiliation")}
                    />
                  )}
                />
              ) : null}
            </CardWrapper>
          </form>
        </Stack>
      </Drawer>
    </>
  );
};
