import React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useGetDocumentById } from "api";
import { BasicTabs, DotsLoader } from "ui-kit";

import { Stack } from "@mui/material";
import { getAPIErrorMessage } from "@sbm/fe-utils";
import {
  CorrespondenceTypeEnum,
  StatusForCorrespondenceEnum,
  TypeOfObjectInternalApprovalEnum,
} from "@types";

import { useAppSelector, useTabNavigation } from "../../../hooks";
import { AgreementContainer } from "../../agreements";
import { DocumentCard, DocumentDetails } from "../components";
import { CorrespondenceAttachmentsAndFiles } from "../components/CorrespondenceAttachmentsAndFiles";
import { CorrespondenceEventsContainer } from "../CorrespondenceEventsContainer";

const STATUS_TO_SHOW_AGREEMENT = [
  StatusForCorrespondenceEnum.draft,
  StatusForCorrespondenceEnum.atSigning,
  StatusForCorrespondenceEnum.toBeAgreed,
];

export const ViewDocumentContainer = () => {
  const { id } = useParams();
  const { tab, handleChange } = useTabNavigation();
  const { t } = useTranslation("correspondence");

  const { userProfile } = useAppSelector((state) => state.auth);

  const { data, isLoading, error } = useGetDocumentById(id, {
    enabled: Boolean(id),
  });

  if (error) {
    const errorMSG = getAPIErrorMessage(error);
    if (errorMSG) {
      toast.error(errorMSG);
    }
  }

  const isAuthor =
    userProfile?.personalDataId === data?.registeredByPersonalDataId || false;

  const isOutgoing =
    data?.correspondenceType === CorrespondenceTypeEnum.outgoingDocument;
  const isServiceNote =
    data?.correspondenceType === CorrespondenceTypeEnum.serviceNote;

  const memoizedTabs = React.useMemo(() => {
    if (!data) return [];

    const showAgreementTab =
      STATUS_TO_SHOW_AGREEMENT.includes(data.statusForCorrespondence) &&
      data?.hasObjectInternalApprovalProcess &&
      (isOutgoing || isServiceNote);

    const tabs = [
      {
        label: t("viewDocument.document_details"),
        component: <DocumentDetails data={data} />,
      },
      {
        label: t("viewDocument.attachments_&_files"),
        component: (
          <CorrespondenceAttachmentsAndFiles
            correspondenceId={data.id}
            type={data.correspondenceType}
            isAuthor={isAuthor}
          />
        ),
      },
      {
        label: t("viewDocument.event_log"),
        component: (
          <CorrespondenceEventsContainer
            registrationDate={data.registrationDate}
            documentNumber={data.internalRegistrationNumber}
          />
        ),
      },
      {
        label: isOutgoing
          ? t("viewDocument.send")
          : isServiceNote
          ? t("viewDocument.resolutions")
          : t("viewDocument.storage_accounting"),
        component: <div />,
      },
    ];

    if (showAgreementTab) {
      tabs.push({
        label: t("viewDocument.approval"),
        component: (
          <AgreementContainer
            data={data}
            type={
              data.correspondenceType as unknown as TypeOfObjectInternalApprovalEnum
            }
          />
        ),
      });
    }

    return tabs;
  }, [data, isAuthor, isOutgoing, isServiceNote, t]);

  if (isLoading || !data) return <DotsLoader />;

  return (
    <Stack gap={6}>
      <DocumentCard data={data} />

      <BasicTabs
        tabIndex={tab}
        onChange={handleChange}
        sticky={false}
        tabs={memoizedTabs}
      />
    </Stack>
  );
};

export default ViewDocumentContainer;
