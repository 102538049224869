import React from "react";
import { useTranslation } from "react-i18next";

import { fetchLinkToFile, useGetCorrespondenceFilesById } from "api";
import { AttachedFileContainer, openDrawer, useAppDispatch } from "app";
import { Table } from "ui-kit";

import { Button, Stack, Typography, useTheme } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid-pro";
import {
  getLocaleDateFormat,
  hexToRgba,
  MS_OFFICE_MIME_TYPES,
  PREVIEW_MIME_TYPES,
} from "@sbm/fe-utils";
import {
  CorrespondenceTypeEnum,
  DrawerVariantsEnum,
  MimeTypesEnum,
} from "@types";

import { TABLE_COLUMNS_FILES } from "./constants";
import { TableActions, Wrapper, PageWrapper } from "./styles";

interface CorrespondenceAttachmentProps {
  correspondenceId: number | undefined;
  isAuthor: boolean;
  type: CorrespondenceTypeEnum;
}

export const CorrespondenceFilesTable: React.FC<
  CorrespondenceAttachmentProps
> = ({ correspondenceId, isAuthor, type }) => {
  const { t } = useTranslation("correspondence");

  const dispatch = useAppDispatch();

  const theme = useTheme();

  const { data, isLoading } = useGetCorrespondenceFilesById(correspondenceId);

  const TableRows = React.useMemo(() => {
    return (
      data?.files?.flatMap((item, index) => {
        return {
          id: item.id,
          sequenceNumber: index + 1,
          convertedPdfFileName: item.convertedPdfFileName,
          status:
            item.fileStatus && t(`attachmentFilesAndLinks.${item.fileStatus}`),
          fileDestination: t(
            `attachmentFilesAndLinks.${item.typeOfAttachment}`
          ),
          fileFormat: item.fileFormat,
          fileName: item.fileNameForUsers,
          uploadedDate: getLocaleDateFormat(item.fileUploadDate, "hh:mm"),
          uploadedBy: item.fileAttachedBy.shortName,
          additionalInformation: item.additionalInformation,
          fileNameForSystem: item.fileNameForSystem,
        };
      }) || []
    );
  }, [data, t]);

  const TableColumns: GridColDef[] = React.useMemo(() => {
    return TABLE_COLUMNS_FILES.map((item) => ({
      ...item,
      headerName: t(item.headerName || ""),
    }));
  }, [t]);

  const handleCellClick = async (params: GridCellParams) => {
    if (!params) return;

    if (params.field === "fileFormat") {
      let linkToFile = "";

      if (MS_OFFICE_MIME_TYPES.includes(params.value as MimeTypesEnum)) {
        linkToFile = await fetchLinkToFile(params.row.convertedPdfFileName);
      } else if (PREVIEW_MIME_TYPES.includes(params.value as MimeTypesEnum)) {
        linkToFile = await fetchLinkToFile(params.row.fileNameForSystem);
      }

      if (linkToFile) {
        window.open(linkToFile, "_blank");
      }
    }
  };

  const handleAddFile = () => {
    dispatch(openDrawer(DrawerVariantsEnum.attachFileInCorrespondence));
  };

  return (
    <PageWrapper $hasData={Boolean(data?.files.length)}>
      <Wrapper>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            variant="h6_semiBold"
            color={hexToRgba(theme.palette.primary.main, 0.7)}
            sx={{ width: "100%", mb: 6 }}
          >
            {t("attachmentFilesAndLinks.files")}
          </Typography>

          <TableActions>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              onClick={handleAddFile}
              disabled={!isAuthor}
            >
              {t("attachmentFilesAndLinks.add_file")}
            </Button>
          </TableActions>
        </Stack>
        <Stack height={data?.files.length ? "100%" : "280px"}>
          <Table
            rows={TableRows}
            columns={TableColumns}
            loading={isLoading}
            onCellClick={handleCellClick}
            sx={{ maxHeight: "280px" }}
            hideFooterRowCount
            textForEmptyTable={t("attachmentFilesAndLinks.no_attached_files")}
          />
        </Stack>
        <AttachedFileContainer
          correspondenceId={correspondenceId}
          type={type}
        />
      </Wrapper>
    </PageWrapper>
  );
};
