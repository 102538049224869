import React from "react";

import {
  DialogActions,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { Icon } from "../Icon";
import {
  DialogContentWrapper,
  DialogTitleWrapper,
  DialogWrapper,
} from "./styles";

export interface ModalProps {
  isOpen: boolean;
  disableMobileFullScreen?: boolean;
  closeIcon?: boolean;
  onClose?: () => void;
  content?: {
    title?: string;
    modalTitle?: string;
    body?: string | JSX.Element;
    secondaryButton?: JSX.Element | null;
    mainButton?: JSX.Element | null;
  } | null;
  disableContentPadding?: boolean;
  sizeLarge?: boolean;
  //todo this prop will be removed after updating the modal component
  updatedVersion?: boolean;
  clickAwayListener?: boolean;
}

export const Modal = (props: ModalProps) => {
  const {
    disableMobileFullScreen = false,
    closeIcon = true,
    isOpen,
    onClose,
    content,
    sizeLarge,
    updatedVersion,
    clickAwayListener,
  } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  if (!content) return null;

  const { title, body, secondaryButton, mainButton, modalTitle } = content;

  return (
    <DialogWrapper
      fullScreen={fullScreen && !disableMobileFullScreen}
      open={isOpen}
      onClose={clickAwayListener ? () => null : onClose}
      sx={{
        ".MuiPaper-root": {
          minWidth: sizeLarge ? "75% !important" : 600,
        },
      }}
    >
      {updatedVersion ? (
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          pb={6}
        >
          {title ? (
            <Typography variant="h7" fontWeight={700}>
              {title}
            </Typography>
          ) : null}
          {modalTitle ? (
            <Typography variant="h7" fontWeight={600}>
              {modalTitle}
            </Typography>
          ) : null}
          <Stack className="close__button">
            <Icon
              name="X"
              color={theme.palette.primary.main}
              onClick={onClose}
            />
          </Stack>
        </Stack>
      ) : (
        <>
          {closeIcon && (
            <div className="close__button">
              <Icon
                name="X"
                color={theme.palette.primary.main}
                onClick={onClose}
              />
            </div>
          )}
          {title ? (
            <DialogTitleWrapper>
              <Typography
                variant="body1"
                fontWeight={600}
                sx={{ textAlign: "center" }}
              >
                {title}
              </Typography>
            </DialogTitleWrapper>
          ) : null}
        </>
      )}

      <DialogContentWrapper $updatedVersion={Boolean(updatedVersion)}>
        {typeof body === "string" ? (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ textAlign: "center" }}
          >
            {body}
          </Typography>
        ) : (
          body
        )}
      </DialogContentWrapper>

      {secondaryButton || mainButton ? (
        <DialogActions sx={{ justifyContent: "center" }}>
          {secondaryButton}
          {mainButton}
        </DialogActions>
      ) : null}
    </DialogWrapper>
  );
};
