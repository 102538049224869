import React from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "ui-kit";

import { Button } from "@mui/material";

export interface TransitionPromptProps {
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  customText?: string;
}

export const TransitionPrompt = ({
  open,
  onClose,
  onConfirm,
  customText,
}: TransitionPromptProps) => {
  const { t } = useTranslation();

  const handleDiscardChanges = () => {
    onConfirm?.();
  };

  const handleClose = () => {
    onClose();
  };

  const content = {
    title: t("transitionPrompt.title"),
    body: customText || t("transitionPrompt.description"),
    secondaryButton: (
      <Button
        size="large"
        color="secondary"
        variant="outlined"
        onClick={handleClose}
      >
        {t("no")}
      </Button>
    ),
    mainButton: (
      <Button
        size="large"
        color="secondary"
        variant="contained"
        autoFocus
        onClick={handleDiscardChanges}
      >
        {t("yes")}
      </Button>
    ),
  };

  return <Modal isOpen={open} onClose={onClose} content={content} />;
};
