// @ts-nocheck
import React from "react";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Icon } from "ui-kit";

import {
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { EMAIL_REGEX } from "@sbm/fe-utils";
import { FormRadio, FormSelect, Input, NumericInput } from "@sbm/ui-components";
import {
  IOrganization,
  IOrganizationEmployee,
  OperatesOnBasisEnum,
  OrganizationEmployeeOptionEnum,
} from "@types";

import { EmployeeAutocomplete } from "../EmployeeAutocomplete";

interface Props {
  control: Control<IOrganization>;
  register: UseFormRegister<IOrganization>;
  errors: FieldErrors<IOrganization>;
  setValue: UseFormSetValue<IOrganization>;
  employees: IOrganizationEmployee[];
  onRemove: (position: number) => void;
  organizationId: number;
}

export const OrganizationEmployeesForm: React.FC<Props> = ({
  control,
  register,
  errors,
  employees,
  onRemove,
  organizationId,
  setValue,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const isFirstPersonSelected = employees?.some(
    (e) => e.typeStaff === OrganizationEmployeeOptionEnum.firstPerson
  );
  const indexFirstPersonSelected = employees?.findIndex(
    (e) => e.typeStaff === OrganizationEmployeeOptionEnum.firstPerson
  );

  const typeOptions = Object.values(OrganizationEmployeeOptionEnum).map(
    (i) => ({
      option: t(`editOrganization.employees.${i}`),
      value: i,
    })
  );

  const operatesOnBasisOptions = React.useMemo(() => {
    return [
      { option: t("charter"), value: OperatesOnBasisEnum.charter },
      {
        option: t("power.of.attorney"),
        value: OperatesOnBasisEnum.powerOfAttorney,
      },
      {
        option: t("not_required"),
        value: OperatesOnBasisEnum.notRequired,
      },
      {
        option: t("order"),
        value: OperatesOnBasisEnum.order,
      },
      {
        option: t("directive"),
        value: OperatesOnBasisEnum.directive,
      },
    ];
  }, [t]);

  const typeOptionsFiltered = typeOptions.filter(
    (option) => option.value !== OrganizationEmployeeOptionEnum.firstPerson
  );

  return (
    <Stack gap={6}>
      {employees.map((employee, index) => {
        return (
          <Card key={employee.id}>
            <CardContent>
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                gap={2}
                mb={4}
              >
                <Typography variant="h9_semiBold" color="text.disabled">
                  {t(
                    `editOrganization.employees.${OrganizationEmployeeOptionEnum.employee}`
                  )}{" "}
                  {index + 1}
                </Typography>

                <Button
                  size="small"
                  variant="text"
                  color="error"
                  onClick={() => onRemove(employee.id)}
                  startIcon={
                    <Icon
                      name="Trash"
                      color={theme.palette.error.main}
                      size={18}
                    />
                  }
                >
                  {t("delete")}
                </Button>
              </Stack>

              <Stack gap={4}>
                <FormRadio
                  name={`employees[${index}].typeStaff`}
                  control={control}
                  values={
                    isFirstPersonSelected && indexFirstPersonSelected !== index
                      ? typeOptionsFiltered
                      : typeOptions
                  }
                />

                <EmployeeAutocomplete
                  organizationId={organizationId}
                  setValue={setValue}
                  position={index}
                  employees={employees}
                />

                {employee.typeStaff !==
                  OrganizationEmployeeOptionEnum.firstPerson && (
                  <Input
                    label={t("division")}
                    variant="outlined"
                    size="medium"
                    maxLength={500}
                    {...register(
                      `employees[${index}].divisionExternalOrganization`
                    )}
                  />
                )}

                <Input
                  label={t("position")}
                  variant="outlined"
                  size="medium"
                  maxLength={500}
                  {...register(
                    `employees[${index}].positionExternalOrganization`
                  )}
                />

                {employee.typeStaff !==
                  OrganizationEmployeeOptionEnum.employee && (
                  <FormSelect
                    label={t("operates.on.basis")}
                    name={`employees[${index}].operatesOnBasis`}
                    control={control}
                    values={operatesOnBasisOptions}
                  />
                )}

                <Controller
                  name={`employees[${index}].phone`}
                  control={control}
                  rules={{
                    validate: (value) => {
                      if (!value) return;

                      if (
                        String(value).length >= 7 &&
                        String(value).length <= 15
                      )
                        return;

                      return t("organization.employees.phone.validation");
                    },
                  }}
                  render={({ field }) => {
                    return (
                      <NumericInput
                        {...field}
                        value={field.value as number}
                        variant="outlined"
                        allowNegative={false}
                        label={t("phone.number")}
                        decimalScale={0}
                        minLength={7}
                        maxLength={15}
                        error={Boolean(errors.employees?.[index]?.phone)}
                        helperText={errors.employees?.[index]?.phone?.message}
                      />
                    );
                  }}
                />

                <Input
                  label={t("email")}
                  variant="outlined"
                  size="medium"
                  maxLength={100}
                  error={Boolean(errors.employees?.[index]?.email)}
                  helperText={
                    errors.employees?.[index]?.email &&
                    t("organization.employees.email.validation")
                  }
                  {...register(`employees[${index}].email`, {
                    pattern: EMAIL_REGEX,
                  })}
                />

                <Input
                  label={t("note")}
                  variant="outlined"
                  size="medium"
                  maxLength={500}
                  {...register(`employees[${index}].additionalInformation`)}
                />
              </Stack>
            </CardContent>
          </Card>
        );
      })}
    </Stack>
  );
};
