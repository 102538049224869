import {
  IOrganization,
  IOrganizationEmployee,
  OrganizationEmployeeOptionEnum,
} from "@types";

export const EMPLOYEES_REQUIRED_FIELDS = ["personalDataId"];

export const defaultValues: IOrganization = {
  briefTextOrganizationName: "",
  fullTextOrganizationName: "",
  inn: "",
  kpp: "",
  ogrn: "",
  okpo: "",
  category: "",
  taxSystem: "",
  inHolding: false,
  validity: true,
  widgetOn: false,
  isActive: false,
  additionalOptions: false,
  employees: [],
  belongingToTheEvGroup: false,
  phone: [],
  email: [],
  address: [],
  website: "",
};

export const initialEmployeeFields: IOrganizationEmployee = {
  personalDataId: undefined,
  operatesOnBasis: undefined,
  positionExternalOrganization: "",
  divisionExternalOrganization: "",
  phone: "",
  email: "",
  typeStaff: OrganizationEmployeeOptionEnum.employee,
};

export enum innLength {
  min = 8,
  max = 12,
}

export enum kppLength {
  min = 9,
  max = 9,
}

export enum ogrnLength {
  min = 13,
  max = 15,
}

export enum okpoLength {
  min = 8,
  max = 10,
}
