import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useGetMessageInfo } from "api";
import { MessageInfoContainer, useAppSelector, useSocket } from "app";
import { Icon } from "ui-kit";

import { Popover, useTheme } from "@mui/material";
import { getLocaleDateFormat } from "@sbm/fe-utils";
import { useQueryClient } from "@tanstack/react-query";
import { DrawerVariantsEnum, IMessageInfo, INotification } from "@types";

import NotificationSound from "../../../../../assets/audio/notification.mp3";
import { InboxNotifications } from "./InboxNotifications";
import { InboxWrapper, IconWrapper, Wrapper } from "./styles";

const audio = new Audio(NotificationSound);

export const Inbox = () => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { socket } = useSocket();
  const queryClient = useQueryClient();

  const { drawer } = useAppSelector((state) => state.global);

  const [notifications, setNotifications] = React.useState<INotification[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [currentMessageId, setCurrentMessageId] = React.useState<
    number | undefined
  >(undefined);

  const open = Boolean(anchorEl);
  const id = open ? "inbox-popover" : undefined;
  const newMessagesCount = notifications.length ?? 0;

  const { data: messageData, isLoading } = useGetMessageInfo({
    id: currentMessageId,
  });

  const currentMessageData = React.useMemo(() => {
    if (!messageData) return null;

    const isFromSystem = messageData.author && !messageData.author.userId;
    return {
      messageText: messageData.textOfTheMessage,
      topic: messageData.topicOfTheMessage,
      correspondent: messageData.messageRecipients.map((recipient) => {
        if (isFromSystem) return { type: "system" };

        return {
          userId: recipient.userId,
          ...recipient.personalData,
        };
      }),
      messageDate: getLocaleDateFormat(
        messageData.dateOfTheMessage,
        "hh:mm",
        true
      ),
      viewingDate: "",
      messageNumber: messageData.id,
      additionalInformation: messageData.additionalInformation,
      messageType: messageData.document.typeOfTheDocument,
      messageViewed: false,
      isImportant: messageData.importantMessage,
    } as unknown as IMessageInfo;
  }, [messageData]);

  const togglePopover = (event: React.MouseEvent<HTMLDivElement>) => {
    if (anchorEl) {
      handleClose();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFocusTab = React.useCallback(() => {
    window.focus();
  }, []);

  const handleMessageView = (id: number) => {
    setNotifications((prevState) =>
      prevState.filter((item) => item.messageNumber !== id)
    );
  };

  useEffect(() => {
    if (!socket) return;

    socket.on("message", (data: INotification[] | INotification) => {
      audio.addEventListener("canplaythrough", () => {
        // There is a new Google Policy, which is blocking audio autoplay
        // https://developer.chrome.com/blog/autoplay/
      });

      void queryClient.invalidateQueries(["get_user_messages"]);
      void queryClient.invalidateQueries(["get_user_chat_list"]);

      if (Array.isArray(data)) {
        setNotifications(data);
      } else {
        setNotifications((prev) => [...prev, data]);
        void audio.play();

        const notification = new Notification(t("new.message"), {
          body: data.messageText,
        });

        notification.addEventListener("click", handleFocusTab);

        setTimeout(() => {
          notification.close();
          notification.removeEventListener("click", () => handleFocusTab);
        }, 3000);
      }
    });
  }, [socket, t, handleFocusTab, queryClient]);

  useEffect(() => {
    if (newMessagesCount > 0) {
      document.title = `SBM (${newMessagesCount})`;
    } else {
      document.title = "SBM";
    }
  }, [newMessagesCount]);

  return (
    <>
      <Wrapper $hide={!newMessagesCount}>
        <InboxWrapper onClick={togglePopover}>
          <IconWrapper>
            <Icon name="Bell" size={24} className="cursor-pointer" />
          </IconWrapper>
        </InboxWrapper>

        <Popover
          disablePortal
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          PaperProps={{
            sx: {
              background: palette.primary.main,
              color: palette.primary.contrastText,
              borderRadius: "8px",
              marginRight: "8px",
              width: 400,
              maxHeight: 296,
              px: 3,
              py: 2,
            },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <InboxNotifications
            notifications={notifications}
            onMessageView={handleMessageView}
            onClose={handleClose}
            setCurrentMessageId={setCurrentMessageId}
          />
        </Popover>
      </Wrapper>

      {!isLoading &&
        currentMessageData &&
        drawer === DrawerVariantsEnum.viewNotification && (
          <MessageInfoContainer messageInfo={currentMessageData} />
        )}
    </>
  );
};
