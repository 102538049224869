import React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import {
  axiosService,
  getContractData,
  IContractForm,
  setModal,
  useAppDispatch,
} from "app";
import { Modal } from "ui-kit";

import { Button } from "@mui/material";
import { SnackbarMessages } from "@sbm/fe-utils";

interface Props {
  open: boolean;
  onClose: () => void;
  data: {
    data: {
      contractData: IContractForm;
    };
  };
}

export const ExistingContractWarningPopup: React.FC<Props> = ({
  open,
  onClose,
  data: { data },
}) => {
  const { contractData } = data;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleSubmit = async () => {
    try {
      await axiosService({
        endpoint: `contracts`,
        method: "POST",
        body: contractData,
      });

      await dispatch(getContractData());
      toast.success(SnackbarMessages.success);
    } catch (err) {
      toast.error(SnackbarMessages.error);
    } finally {
      dispatch(setModal(null));
    }
  };

  const content = {
    title: "",
    body: t("existing_contract_warning_title"),
    secondaryButton: (
      <Button
        onClick={onClose}
        variant="outlined"
        size="large"
        color="secondary"
      >
        {t("no")}
      </Button>
    ),
    mainButton: (
      <Button
        autoFocus
        variant="contained"
        color="secondary"
        size="large"
        onClick={handleSubmit}
      >
        {t("yes")}
      </Button>
    ),
  };

  return <Modal isOpen={open} onClose={onClose} content={content} />;
};
