import { ITaskById, TypeOfTheTaskEnum } from "@types";

export const InitialTaskFields = {
  highPriority: false,
  personalControl: false,
  reportRequired: false,
  scheduledDateOfExecution: "",
  scheduledTimeOfExecution: "",
  workingDaysForExecution: 2,
  calendarDaysForExecution: undefined,
  constructionComplexes: [],
  affiliationConstructionComplex: false,
  disabledConstructionComplexes: [],
};

export const getInitialTaskFields = (initialValues: ITaskById | null) => {
  if (!initialValues) {
    return InitialTaskFields;
  }

  const getDate = () => {
    const date = new Date();
    const isoString = date.toISOString();
    const date1 = new Date(isoString);
    const date2 = new Date(initialValues.executionRequirements.controlDate);
    if (date1.getTime() < date2.getTime()) {
      return initialValues.executionRequirements.controlDate;
    }
    return date1;
  };

  return {
    ...InitialTaskFields,
    textOfTheTask:
      initialValues.typeOfTheTask === TypeOfTheTaskEnum.resolutionForTheDocument
        ? ""
        : initialValues.executionRequirements.textOfTheTask,
    personalControl: initialValues.executionRequirements.personalControl,
    reportRequired: initialValues.executionRequirements.reportRequired,
    highPriority: initialValues.registration.priority,
    scheduledDateOfExecution: getDate(),
    scheduledTimeOfExecution: "",
    workingDaysForExecution:
      initialValues.executionRequirements.numberOfWorkingDays,
    calendarDaysForExecution:
      initialValues.executionRequirements.numberOfCalendarDays,
    affiliationConstructionComplex: Boolean(
      initialValues.registration.constructionComplexAffiliation.length
    ),
    constructionComplexes:
      initialValues.registration.constructionComplexAffiliation.map(
        (i) => i.id
      ),
    disabledConstructionComplexes:
      initialValues.registration.constructionComplexAffiliation.map(
        (i) => i.id
      ),
  };
};

export const orderTextMaxLength = 3000;
