import toast from "react-hot-toast";

import {
  fetchCreateEmployee,
  fetchEmployeesInHolding,
  fetchFunctionalDirectionOfTheUnit,
  fetchOrganizationById,
  fetchOrganizationsList,
  fetchOrganizationStaffAllocation,
  fetchUpdateOrganization,
  getOrganizationFileById,
  getOrganizationListById,
  getOrgStaffAllocationDetails,
  getPersonalData,
  postExternalOrganizationStaff,
  postPersonalData,
  uploadOrganizationFile,
} from "api";
import { closeDrawerWithData, useAppDispatch } from "app";
import { AxiosError } from "axios";

import {
  getAPIErrorMessage,
  getFiltersQueryCacheKey,
  SnackbarMessages,
} from "@sbm/fe-utils";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  FunctionEnum,
  GenderEnum,
  ICreateEmployee,
  ICurrentUserApprovals,
  IEmployeeInHolding,
  IMeta,
  IOrganization,
  IOrganizationEmployee,
  IOrganizationFile,
  IOrgStaffUnitDetails,
  IPersonalData,
  OrganizationsListItem,
} from "@types";

export const useGetOrganizationsList = (
  sortBy = "",
  search = "",
  filter: (string | undefined)[] = []
) => {
  const queryKeyFilter = getFiltersQueryCacheKey(filter);

  return useInfiniteQuery<{
    items: OrganizationsListItem[];
    meta: IMeta;
  }>(
    ["get_organizations_list", search, ...queryKeyFilter, sortBy],
    (params) => fetchOrganizationsList(params, sortBy, search, filter),
    {
      cacheTime: 0,
      staleTime: 0,
      retry: false,
      getPreviousPageParam: (params) => {
        const prevPage = params.meta.currentPage - 1;
        return prevPage <= 0 ? 0 : prevPage;
      },
      getNextPageParam: (params) => {
        if (!params?.meta) return 1;

        if (params.meta.currentPage < params.meta.totalPages) {
          return params.meta.currentPage + 1;
        }
      },
    }
  );
};

export const useGetStaffAllocations = (
  id: string | undefined,
  search: string,
  filters: (string | undefined)[]
) => {
  const queryKeyFilter = getFiltersQueryCacheKey(filters);

  return useQuery<ICurrentUserApprovals[]>(
    ["get_staff_allocations", id, search, ...queryKeyFilter],
    async () => await fetchOrganizationStaffAllocation(id, search, filters)
  );
};

export const useGetEmployeesInHolding = ({
  search,
  limit,
}: {
  search?: string;
  limit?: number;
}) => {
  return useQuery<{ items: IEmployeeInHolding[] }>(
    ["get_employees_in_holding", search, limit],
    async () => await fetchEmployeesInHolding(search, limit)
  );
};

export const useGetOrganizationById = (id: number) => {
  return useQuery<IOrganization>(
    ["get_organization", id],
    async () => await fetchOrganizationById(id),
    {
      retry: false,
      enabled: Boolean(id),
    }
  );
};

export const useGetFunctionalDirectionOfTheUnit = (id: number) => {
  return useQuery<FunctionEnum[]>(
    ["get_functional_direction_unit", id],
    async () => await fetchFunctionalDirectionOfTheUnit(id)
  );
};

export const useCreateEmployee = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: ICreateEmployee) => {
      await fetchCreateEmployee(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_staff_allocations"]);
        toast.success(SnackbarMessages.success);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useUpdateExternalOrganizationStaff = () => {
  const dispatch = useAppDispatch();

  return useMutation(
    async (body: {
      organizationId: number;
      staff: IOrganizationEmployee[];
    }) => {
      await postExternalOrganizationStaff(body);
    },
    {
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
      onSuccess: () => {
        dispatch(closeDrawerWithData());
      },
    }
  );
};

export const useUpdateOrganization = (organizationId: number | undefined) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    async (
      body: Omit<IOrganization, "employees" | "externalOrganizationStaff">
    ) => {
      await fetchUpdateOrganization(body, organizationId);
    },
    {
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
      onSuccess: () => {
        void queryClient.invalidateQueries([
          ["get_organization"],
          ["get_organizations_list"],
        ]);
        dispatch(closeDrawerWithData());
      },
    }
  );
};

export const useCreatePersonalDataMutation = () => {
  const dispatch = useAppDispatch();

  return useMutation(
    async (body: {
      name: string;
      surname: string;
      patronymic: string;
      gender: GenderEnum;
    }) => {
      await postPersonalData(body);
    },
    {
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useGetPersonalData = (search: string) => {
  return useQuery<{ items: IPersonalData[]; meta: IMeta }>(
    ["get_personal_data", search],
    async () => await getPersonalData(search),
    {
      retry: false,
    }
  );
};

export const useGetOrganizationListById = (organizationId: number) => {
  return useQuery<IOrganization>(
    ["get_organization_list_by_id"],
    async () => await getOrganizationListById(organizationId),
    {
      retry: false,
    }
  );
};

export const useUploadFileToOrganizationMutation = (
  onSuccess: () => void,
  onError: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: { requestBody: FormData; organizationId: number }) => {
      await uploadOrganizationFile(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_organization_files_by_id"]);
        await queryClient.invalidateQueries(["get_organization_file_by_id"]);
        onSuccess();
        toast.success(SnackbarMessages.success);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        onError();
        toast.error(errorMsg);
      },
    }
  );
};

export const useGetOrganizationFileById = (organizationId: number) => {
  return useQuery<IOrganizationFile>(
    ["get_organization_file_by_id"],
    async () => await getOrganizationFileById(organizationId),
    {
      retry: false,
    }
  );
};

export const useGetOrgStaffAllocationDetails = (body: {
  externalSubstitutionId?: number;
  employeeId?: number;
  staffUnitId: number;
}) => {
  return useQuery<IOrgStaffUnitDetails>(
    ["get_organization_staff_allocation_details"],
    async () => await getOrgStaffAllocationDetails(body),
    {
      retry: false,
    }
  );
};
