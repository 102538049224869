import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useGetMessageInfo } from "api";
import {
  closeDrawer,
  setChangeTab,
  setShowMessageDetails,
  UI_CONFIG,
  useAppDispatch,
  useAppSelector,
} from "app";
import { DotsLoader, Drawer, Icon } from "ui-kit";

import { Grid, Stack, Typography, useTheme } from "@mui/material";
import {
  getLocaleDateFormat,
  getPersonalDataName,
  isEmpty,
} from "@sbm/fe-utils";
import { stringAvatar } from "@sbm/ui-components";
import {
  DrawerTypes,
  IAuthor,
  IMessageInfo,
  MessageAttachmentsType,
  MessageViewResponse,
} from "@types";

import {
  ConversationSection,
  MessageAttachedFile,
  MessageAttachedLink,
  MessagePopover,
} from "../components";
import {
  AttachedFilesWrapper,
  AvatarWrapper,
  ContentWrapper,
  CorrespondentAvatarWrapper,
  DrawerTitleWrapper,
  TabsWrapper,
  Wrapper,
} from "./styles";

export const MessageInfoContainer = ({
  messageInfo,
}: {
  messageInfo: IMessageInfo | null;
}) => {
  const { t } = useTranslation();
  const { t: tAttachment } = useTranslation("attachments");
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [recipientsList, setRecipientsList] = useState<
    { option: string; value: number }[]
  >([]);
  const [messageData, setMessageData] = useState<MessageViewResponse>();

  const { currentMessageId, tab } = useAppSelector((state) => state.message);
  const { drawer } = useAppSelector((state) => state.global);
  const { userProfile } = useAppSelector((state) => state.auth);

  const { data: mData } = useGetMessageInfo({
    id: currentMessageId,
  });

  const handleConfirmTransition = () => {
    dispatch(closeDrawer());
    dispatch(setShowMessageDetails(null));
  };

  const handleClose = () => {
    handleConfirmTransition();
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleChangeTab = (attachmentType: MessageAttachmentsType) => {
    dispatch(setChangeTab(attachmentType));
  };

  const hasAttachedFilesAndLinks =
    messageData?.attachments.length && !isEmpty(messageData.document);

  const getAuthorName = (author: IAuthor) => {
    if (!author.userId) return t("sbm.assistant");

    return getPersonalDataName(author.personalData);
  };

  const getMessageType = (author?: IAuthor) => {
    if (!author) return;

    return (
      <Typography variant="h5" fontWeight={700}>
        {userProfile?.id === author.userId
          ? t("messages.outgoing_message")
          : t("messages.incoming_message")}
      </Typography>
    );
  };

  useEffect(() => {
    if (messageInfo?.messageNumber) {
      dispatch(setShowMessageDetails(messageInfo.messageNumber));
    }
  }, [dispatch, messageInfo?.messageNumber]);

  useEffect(() => {
    if (!messageData?.attachments.length && isEmpty(messageData?.document)) {
      dispatch(setChangeTab(MessageAttachmentsType.FILES));
    }
  }, [messageData, dispatch]);

  useEffect(() => {
    if (messageData) {
      const result = messageData.messageRecipients.map((e) => {
        if (
          e.userId &&
          getPersonalDataName(e.personalData) &&
          e.userId !== userProfile?.id
        ) {
          return {
            option: getPersonalDataName(e.personalData),
            value: e.userId,
          };
        }

        return undefined;
      });

      const filteredResult = result.filter(
        (e): e is { option: string; value: number } => !!e
      );

      setRecipientsList(filteredResult);
    }
  }, [messageData, userProfile?.id]);

  useEffect(() => {
    if (mData) {
      setMessageData(mData);
    }
  }, [mData]);

  return (
    <Drawer
      anchor="right"
      open={Boolean(drawer)}
      onClose={handleClose}
      type={DrawerTypes.messageView}
      width={UI_CONFIG.rightDrawerWidth}
      title={
        <DrawerTitleWrapper>
          {messageData?.importantMessage ? (
            <Icon
              name="FlagTriangleRight"
              size={20}
              color={theme.palette.secondary.main}
            />
          ) : null}
          <div>{getMessageType(messageData?.author)}</div>
        </DrawerTitleWrapper>
      }
    >
      <Stack height="100%">
        <Stack
          maxHeight={`${
            messageData?.attachments.length || isEmpty(messageData?.document)
              ? 400
              : 300
          }`}
        >
          <Stack p={6} pb={0}>
            {messageData && messageInfo ? (
              <Wrapper>
                <div>
                  <ContentWrapper>
                    <Grid container spacing={2}>
                      <Grid item xs={1}>
                        <CorrespondentAvatarWrapper
                          $fromSystem={
                            messageInfo.correspondent[0].type === "system"
                          }
                        >
                          {messageData.author && messageData.author.userId ? (
                            <Typography variant="body3" fontWeight={700}>
                              {stringAvatar(
                                getAuthorName(messageData.author) || ""
                              )}
                            </Typography>
                          ) : (
                            <AvatarWrapper
                              $fromSystem={
                                messageInfo.correspondent[0].type === "system"
                              }
                            />
                          )}
                        </CorrespondentAvatarWrapper>
                      </Grid>
                      <Grid item xs={7.5}>
                        <Typography variant="body1" fontWeight={700}>
                          {messageData?.author
                            ? getAuthorName(messageData.author)
                            : null}
                        </Typography>
                        <MessagePopover
                          onPopoverOpen={handlePopoverOpen}
                          onPopoverClose={handlePopoverClose}
                          anchorEl={anchorEl}
                          messageData={messageData}
                          author={
                            messageData?.author
                              ? getAuthorName(messageData.author)
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={3.5}>
                        <Typography
                          fontSize={10}
                          fontWeight={400}
                          color={"grey"}
                          sx={{ textAlign: "end" }}
                        >
                          {getLocaleDateFormat(messageData.dateOfTheMessage)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={1} />
                      <Grid
                        item
                        xs={11}
                        mt={6}
                        sx={{
                          maxHeight: "200px",
                          paddingBottom: "8px",
                          overflowY: "auto",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          color="primary.main"
                          sx={{ whiteSpace: "pre-line" }}
                        >
                          {messageData.textOfTheMessage?.replace(/\s+$/g, "")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ContentWrapper>

                  {hasAttachedFilesAndLinks ? (
                    <>
                      {
                        <TabsWrapper>
                          <Typography
                            variant="body2"
                            fontWeight={600}
                            className="attachment_tab"
                            sx={{
                              borderBottom: `2px solid ${
                                tab === MessageAttachmentsType.FILES
                                  ? theme.palette.secondary.main
                                  : "inherit"
                              }`,
                              paddingBottom:
                                tab === MessageAttachmentsType.FILES
                                  ? 0
                                  : "2px",
                            }}
                            color={
                              tab === MessageAttachmentsType.FILES
                                ? theme.palette.secondary.main
                                : ""
                            }
                            onClick={() =>
                              handleChangeTab(MessageAttachmentsType.FILES)
                            }
                          >
                            {`${tAttachment("attachedFiles")} `}
                          </Typography>
                          <Typography
                            variant="body2"
                            fontWeight={600}
                            className="attachment_tab"
                            color={
                              tab === MessageAttachmentsType.LINKS
                                ? theme.palette.secondary.main
                                : ""
                            }
                            sx={{
                              borderBottom: `2px solid ${
                                tab === MessageAttachmentsType.LINKS
                                  ? theme.palette.secondary.main
                                  : "inherit"
                              }`,
                              paddingBottom:
                                tab === MessageAttachmentsType.LINKS
                                  ? 0
                                  : "2px",
                            }}
                            onClick={() =>
                              handleChangeTab(MessageAttachmentsType.LINKS)
                            }
                          >
                            {`${tAttachment("attachedLinks")}`}
                          </Typography>
                        </TabsWrapper>
                      }
                      {tab === MessageAttachmentsType.FILES ? (
                        messageData.attachments.length ? (
                          <AttachedFilesWrapper>
                            <div className="attached__files">
                              {messageData.attachments.map((item, index) => (
                                <MessageAttachedFile
                                  key={item.fileName + index}
                                  fileFormat={item.fileFormat}
                                  fileName={item.fileName}
                                  fileNameForSystem={item.fileNameForSystem}
                                  convertedPdfFileName={
                                    item.convertedPdfFileName
                                  }
                                />
                              ))}
                            </div>
                          </AttachedFilesWrapper>
                        ) : (
                          <Typography
                            variant="body1"
                            fontWeight={600}
                            className="empty_text"
                          >
                            {tAttachment("noAttachedFiles")}
                          </Typography>
                        )
                      ) : null}
                      {tab === MessageAttachmentsType.LINKS ? (
                        <MessageAttachedLink document={messageData.document} />
                      ) : null}
                    </>
                  ) : messageData?.attachments.length ? (
                    <>
                      <Typography variant="body2" fontWeight={600}>
                        {`${tAttachment("attachedFiles")}`}
                      </Typography>

                      <AttachedFilesWrapper>
                        <div className="attached__files">
                          {messageData.attachments.map((item, index) => (
                            <MessageAttachedFile
                              key={item.fileName + index}
                              fileFormat={item.fileFormat}
                              fileName={item.fileName}
                              fileNameForSystem={item.fileNameForSystem}
                              convertedPdfFileName={item.convertedPdfFileName}
                            />
                          ))}
                        </div>
                      </AttachedFilesWrapper>
                    </>
                  ) : messageData.document.id ? (
                    <>
                      <Typography
                        variant="body2"
                        fontWeight={600}
                        className="attachment_tab"
                      >
                        {`${tAttachment("attachedLinks")}`}
                      </Typography>
                      <MessageAttachedLink document={messageData.document} />
                    </>
                  ) : null}
                </div>
              </Wrapper>
            ) : null}
          </Stack>
        </Stack>
        <Stack
          height="100%"
          bgcolor={theme.palette.background.paper}
          overflow="auto"
        >
          {messageData &&
          messageData.id &&
          messageData.author &&
          userProfile?.id ? (
            <ConversationSection
              authorUserID={messageData.author.userId!}
              userProfileID={userProfile.id}
              authorEmployeeID={messageData.author.employeeId}
              recipientsList={recipientsList}
              messageData={messageData}
              messageInfo={messageInfo}
            />
          ) : (
            <DotsLoader />
          )}
        </Stack>
      </Stack>
    </Drawer>
  );
};
