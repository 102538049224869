import styled from "styled-components";
import { FLEX_COLUMN } from "ui-kit";

import { Card } from "@mui/material";

export const Wrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(6)};
`;
export const CardWrapper = styled(Card)`
  ${FLEX_COLUMN};
  position: relative;
  padding: ${({ theme }) => theme.spacing(3, 4, 4, 4)};
  margin: ${({ theme }) => theme.spacing(4, 0)};
  border-radius: ${({ theme }) => theme.spacing(4)};
  gap: ${({ theme }) => theme.spacing(4)};
  z-index: 1;
`;
