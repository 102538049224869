import React from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "ui-kit";

import {
  ListItemIcon,
  useTheme,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

export const CorrespondenceAttachedLinksActions: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation("correspondence");

  const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null);

  const open = Boolean(anchorEl);

  const handlePopoverOpen = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent> | undefined
  ) => {
    if (event) {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Icon
        onClick={handlePopoverOpen}
        name="MoreVerticalIcon"
        color={theme.palette.primary.main}
      />

      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: "20ch",
            borderRadius: "16px",
          },
        }}
      >
        <MenuItem disabled key="edit" sx={{ px: 4, py: 3 }}>
          <ListItemIcon>
            <Icon name="PencilIcon" color={theme.palette.primary.main} />
          </ListItemIcon>
          <Typography variant="body1">{t("edit")}</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};
