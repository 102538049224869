import { IContractForm } from "app";

import {
  IDocumentById,
  TSupAgreement,
  TypeOfObjectInternalApprovalEnum,
} from "@types";

export const getAgreementStatus = (
  data: TSupAgreement | IContractForm | IDocumentById,
  type: TypeOfObjectInternalApprovalEnum
) => {
  switch (type) {
    case TypeOfObjectInternalApprovalEnum.contract:
      return (data as IContractForm).statusContract;
    case TypeOfObjectInternalApprovalEnum.supplementaryAgreement:
      return (data as TSupAgreement).statusSupplementaryAgreement;
    case TypeOfObjectInternalApprovalEnum.outgoingDocument:
    case TypeOfObjectInternalApprovalEnum.serviceNote:
      return (data as IDocumentById).statusForCorrespondence;
    default:
      return null;
  }
};

export const getAgreementDataForConfirm = (
  data: TSupAgreement | IContractForm | IDocumentById,
  type: TypeOfObjectInternalApprovalEnum
) => {
  switch (type) {
    case TypeOfObjectInternalApprovalEnum.contract:
      return {
        contractId: data.id,
        type,
      };
    case TypeOfObjectInternalApprovalEnum.supplementaryAgreement:
      return {
        supplementaryAgreementId: data.id,
        type,
      };
    case TypeOfObjectInternalApprovalEnum.outgoingDocument:
      return {
        outgoingDocumentId: data.id,
        type,
      };
    case TypeOfObjectInternalApprovalEnum.serviceNote:
      return {
        serviceNoteId: data.id,
        type,
      };
  }
};
