import { createSlice } from "@reduxjs/toolkit";
import { MessageAttachmentsType, MessengerModeType } from "@types";

interface IMessage {
  currentMessageId: any;
  tab: MessageAttachmentsType.FILES | MessageAttachmentsType.LINKS;
  messengerMode: MessengerModeType;
}

export const initialStateMessages: IMessage = {
  currentMessageId: null,
  tab: MessageAttachmentsType.FILES,
  messengerMode: MessengerModeType.CHAT,
};

export const messageSlice = createSlice({
  name: "message",
  initialState: initialStateMessages,
  reducers: {
    setShowMessageDetails: (state, action) => {
      state.currentMessageId = action.payload;
    },
    setChangeTab: (state, action) => {
      state.tab = action.payload;
    },
    setChangeMessengerMode: (state, action) => {
      state.messengerMode = action.payload;
    },
  },
});

export const { setShowMessageDetails, setChangeTab, setChangeMessengerMode } =
  messageSlice.actions;

export default messageSlice.reducer;
