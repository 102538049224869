import styled from "styled-components";
import { FLEX_ROW, FLEX_ROW_ALIGN_CENTER } from "ui-kit";

export const WidgetActionWrapper = styled.div`
  ${FLEX_ROW};
  align-items: end;
  justify-content: end;
  flex: 1;

  .manage_button {
    gap: ${({ theme }) => theme.spacing(2)};
    ${FLEX_ROW_ALIGN_CENTER};
  }
`;
