import * as React from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "ui-kit";

import {
  ListItemIcon,
  Typography,
  useTheme,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import { Button } from "@sbm/ui-components";
import { ChartOptionsEnum, TIconNames } from "@types";

const ITEM_HEIGHT = 48;

interface ChartActionsMenuProps {
  onAction: (optionLabel: ChartOptionsEnum) => void;
}

export const ChartActionsMenu: React.FC<ChartActionsMenuProps> = ({
  onAction,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const options = [
    {
      type: ChartOptionsEnum.expand,
      label: "expand",
      iconName: "UnfoldVertical",
    },
    {
      type: ChartOptionsEnum.collapse,
      label: "collapse",
      iconName: "FoldVertical",
    },
    {
      type: ChartOptionsEnum.showEmployees,
      label: "show_employees",
      iconName: "Eye",
    },
    {
      type: ChartOptionsEnum.hideEmployees,
      label: "hide_employees",
      iconName: "EyeOff",
    },
  ];

  const open = Boolean(anchorEl);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (event) {
      event.stopPropagation();
      setAnchorEl(event.currentTarget as unknown as HTMLElement);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (type: ChartOptionsEnum) => {
    onAction(type);
    handleClose();
  };

  return (
    <Stack display="flex">
      <Button
        color="secondary"
        size="large"
        endIcon={
          <Icon
            name={open ? "ChevronUp" : "ChevronDown"}
            className="cursor-pointer"
            color={theme.palette.secondary.main}
          />
        }
        onClick={handlePopoverOpen}
        variant="text"
      >
        {t("customize_view")}
      </Button>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            minWidth: "25ch",
            borderRadius: "16px",
          },
        }}
      >
        {options.map((option) => {
          return (
            <MenuItem
              key={option.type}
              sx={{ px: 4, py: 3 }}
              onClick={() => handleMenuItemClick(option.type)}
            >
              <Stack direction="row" gap={6}>
                <ListItemIcon>
                  <Icon
                    name={option.iconName as TIconNames}
                    color={theme.palette.primary.main}
                  />
                </ListItemIcon>
                <Typography variant="body1">{t(option.label)}</Typography>
              </Stack>
            </MenuItem>
          );
        })}
      </Menu>
    </Stack>
  );
};
