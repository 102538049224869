import React from "react";

import { Stack, Tooltip, Typography } from "@mui/material";

interface TaskDetailTextSectionProps {
  label: string;
  content: string | React.ReactNode;
  disableEllipsis?: boolean;
  withTooltip?: boolean;
  tooltipText?: string;
}

export const TaskDetailTextSection: React.FC<TaskDetailTextSectionProps> = ({
  label,
  content,
  disableEllipsis,
  withTooltip,
  tooltipText,
}) => {
  return (
    <Stack>
      <Typography variant="body4" color="text.secondary">
        {label}
      </Typography>
      {withTooltip ? (
        <Tooltip
          arrow
          disableInteractive
          placement="top-start"
          title={tooltipText ? tooltipText : content}
        >
          <div>
            <Typography
              variant="body2"
              fontWeight={600}
              className={disableEllipsis ? "" : "ellipsis"}
              sx={{ whiteSpace: "pre-line" }}
            >
              {content}
            </Typography>
          </div>
        </Tooltip>
      ) : (
        <Typography
          variant="body2"
          fontWeight={600}
          className={disableEllipsis ? "" : "ellipsis"}
          sx={{ whiteSpace: "pre-line" }}
        >
          {content}
        </Typography>
      )}
    </Stack>
  );
};
