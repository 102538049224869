import styled from "styled-components";
import { FLEX_COLUMN_FULL } from "ui-kit";

import StaffAllocationDetailsBackground from "../../../../assets/backgrounds/staff_allocation_details.png";

export const StaffAllocationImg = styled.div`
  ${FLEX_COLUMN_FULL};
  background-image: url(${StaffAllocationDetailsBackground});
  background-position: left;
  width: 552px;
  height: 513px;
  background-repeat: no-repeat;
  margin-top: ${({ theme }) => theme.spacing(4)};
`;
