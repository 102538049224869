import React from "react";
import { useNavigate } from "react-router-dom";

import { useViewTaskMutation } from "api";
import { Icon } from "ui-kit";

import { Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { IAttachDocument } from "@types";

import { AttachedLink } from "./styles";

interface Props {
  document: IAttachDocument;
}

export const MessageAttachedLink = ({ document }: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const {
    shortNameOfTheDocument,
    contractId,
    typeOfTheDocument,
    supplementaryAgreementId,
    taskId,
  } = document;
  const { mutate: viewTask } = useViewTaskMutation();

  const linkUrl = React.useMemo(() => {
    switch (typeOfTheDocument) {
      case "contract":
        return `/documents/contracts/${contractId}`;
      case "supplementary_agreement":
        return `/documents/contracts/${contractId}/sa/${supplementaryAgreementId}`;
      case "task":
        return `/tasks/${taskId}`;
      case "message":
        return "";
      default:
        return "";
    }
  }, [contractId, supplementaryAgreementId, taskId, typeOfTheDocument]);

  const handleNavigate = () => {
    navigate(linkUrl);
  };

  return (
    <AttachedLink>
      <Tooltip
        arrow
        placement="top"
        disableInteractive
        title={shortNameOfTheDocument}
      >
        <Stack className="attached_link_item" onClick={handleNavigate}>
          <Icon name="Link" color={theme.palette.secondary.main} />
          <Stack maxWidth={400}>
            <Typography
              ml={2}
              className="ellipsis"
              variant="body2"
              fontWeight={600}
              color="secondary.main"
            >
              {shortNameOfTheDocument}
            </Typography>
          </Stack>
        </Stack>
      </Tooltip>
    </AttachedLink>
  );
};
