import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Modal } from "ui-kit";

import { Grid, Stack, useTheme } from "@mui/material";
import { Button, Input } from "@sbm/ui-components";

interface IApprovalRoute {
  duration?: number;
  queueNumber?: number;
}

export const ChangeApprovalRoutePopup = ({
  open,
  onClose,
  data,
}: {
  open: boolean;
  onClose: () => void;
  data: {
    data: { duration: number; queueNumber: number; type: "queue" | "duration" };
  };
}) => {
  const { t } = useTranslation("correspondence");
  const theme = useTheme();

  const { duration, queueNumber, type } = data.data;

  const { register, control, handleSubmit, setValue, getValues } =
    useForm<IApprovalRoute>({
      defaultValues: {
        duration: duration,
        queueNumber: queueNumber,
      },
    });

  const onSubmit = handleSubmit(async (data) => {
    console.log(data);
  });

  const isDisabled = useMemo(() => {
    switch (type) {
      case "queue":
        return getValues("queueNumber");
      case "duration":
        return getValues("duration") && getValues("duration") !== duration;
      default:
        return false;
    }
  }, [type, getValues, duration]);

  const content = {
    modalTitle: type === "queue" ? t("change_queue") : t("change_duration"),
    body: (
      <Stack mb={6}>
        <Grid container spacing={2}>
          {type === "queue" ? (
            <h1>queue</h1>
          ) : (
            <Grid item xs={12}>
              <Input
                label={t("duration")}
                required
                variant="outlined"
                size="medium"
                {...register("duration")}
              />
            </Grid>
          )}
        </Grid>
      </Stack>
    ),

    mainButton: (
      <Stack flexDirection="row" justifyContent="end" width="100%" gap={2}>
        <Button
          autoFocus
          variant="outlined"
          color="secondary"
          onClick={onClose}
        >
          {t("cancel")}
        </Button>
        <Button
          autoFocus
          variant="contained"
          color="secondary"
          disabled={!!isDisabled}
          onClick={onSubmit}
        >
          {t("add")}
        </Button>
      </Stack>
    ),
  };

  return (
    <Modal isOpen={open} onClose={onClose} content={content} updatedVersion />
  );
};
