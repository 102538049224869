import React, { useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useSendMessageFromChatMutation } from "api";
import {
  AcceptedMimeTypes,
  setModal,
  useAppDispatch,
  useAppSelector,
} from "app";
import { Icon, MessengerDragAndDrop } from "ui-kit";

import { Grid, Stack, Tooltip, useTheme } from "@mui/material";
import { Input } from "@sbm/ui-components";
import {
  IAttachLink,
  ISendMessage,
  MessageViewResponse,
  ModalVariants,
  TheMessageRecipientEnum,
} from "@types";

import {
  FILES_MAX_COUNT,
  FILES_MAX_SIZE,
  IMessageForm,
  MESSAGE_DEFAULT_VALUES,
} from "../../CreateMessageForm/constants";
import {
  AttachmentIconsWrapper,
  SendIconWrapper,
  SendTextWrapper,
  Wrapper,
} from "./styles";

interface Props {
  recipientId?: number;
  authorUserID?: number;
  authorEmployeeID?: number;
  messageData: MessageViewResponse;
  handleSendMessage: (str: string, id?: number) => void;
}

export const SendingMessage = ({
  recipientId,
  authorUserID,
  messageData,
  authorEmployeeID,
  handleSendMessage,
}: Props) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [inputValue, setInputValue] = useState("");
  const { t } = useTranslation();
  const { userProfile } = useAppSelector((state) => state.auth);
  const [attachLinkList, setAttachList] = useState<IAttachLink | null>(null);

  const { reset, control, handleSubmit, setValue } = useForm<IMessageForm>({
    defaultValues: React.useMemo(() => MESSAGE_DEFAULT_VALUES, []),
  });

  const { files } = useWatch({
    control,
  });

  const handleConfirmTransition = (id?: number) => {
    handleSendMessage(inputValue, id);
    setInputValue("");
    setAttachList(null);
    reset();
  };

  const { mutate: sendMessage } = useSendMessageFromChatMutation(
    handleConfirmTransition,
    handleConfirmTransition
  );

  const messageRecipientEmployeeIds = useMemo(() => {
    if (authorUserID === userProfile?.id) {
      const currentRecipient = messageData.messageRecipients.filter(
        (m) => m.userId === recipientId
      );
      return currentRecipient[0]?.employeeId;
    }
    return authorEmployeeID;
  }, [messageData, authorUserID, userProfile, recipientId, authorEmployeeID]);

  const onSubmit = handleSubmit(async (data) => {
    if (recipientId) {
      const message = inputValue;
      const formData = new FormData();

      if (message) {
        formData.append("textOfTheMessage", message);
      } else {
        formData.delete("textOfTheMessage");
      }

      formData.append("importantMessage", JSON.stringify(false));
      formData.append(
        "typeOfTheMessageRecipient",
        TheMessageRecipientEnum.user
      );
      formData.append("isAttachedDocument", `${Boolean(attachLinkList)}`);
      if (attachLinkList?.id) {
        formData.append(
          "attachedDocumentId",
          attachLinkList.id as unknown as string
        );
        formData.set("attachedDocumentId", String(attachLinkList.id));
      }
      formData.append("typeOfTheMessageAuthor", TheMessageRecipientEnum.user);
      formData.append(
        "messageRecipientEmployeeIds[]",
        messageRecipientEmployeeIds as unknown as string
      );
      data.files?.forEach((file) => {
        formData.append("files", file);
      });

      sendMessage(formData as unknown as ISendMessage);
      handleConfirmTransition(messageRecipientEmployeeIds);
    }
  });

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        setInputValue((prev) => prev);
      } else {
        if (inputValue.trim()) {
          onSubmit();
        }
        e.preventDefault();
      }
    }
  };

  const handleFileSelect = (files: File | File[]) => {
    setValue("files", files as File[]);
  };

  const addAttachLink = (link: IAttachLink) => {
    setAttachList(link);
  };

  const handleAttachLink = () => {
    dispatch(
      setModal({
        open: true,
        variant: ModalVariants.attachLink,
        content: { setAttachList: addAttachLink },
      })
    );
  };

  return (
    <Wrapper>
      <Grid container sx={{ height: "100%" }}>
        <Grid item xs={1.5}>
          <AttachmentIconsWrapper>
            <Stack position="relative" bottom={0}>
              <Stack position="absolute" left={16} bottom={16}>
                {attachLinkList ? (
                  <Tooltip
                    arrow
                    placement="top"
                    title={attachLinkList.shortNameOfTheDocument}
                  >
                    <div className="attach_indicator" />
                  </Tooltip>
                ) : null}
              </Stack>
              <Icon
                name="Link"
                size={24}
                color={theme.palette.primary.main}
                onClick={handleAttachLink}
              />
            </Stack>

            <Stack position="relative" bottom={-2}>
              <Stack position="absolute" left={16} bottom={20}>
                {files ? (
                  <Tooltip
                    arrow
                    placement="top"
                    title={
                      <ul>
                        {files.map((f) => (
                          <li key={f.name}>{f.name}</li>
                        ))}
                      </ul>
                    }
                  >
                    <div className="attach_indicator" />
                  </Tooltip>
                ) : null}
              </Stack>
              {files && FILES_MAX_COUNT <= files.length ? (
                <Icon
                  name="Paperclip"
                  size={24}
                  color={theme.palette.text.disabled}
                  onClick={handleAttachLink}
                />
              ) : (
                <MessengerDragAndDrop
                  files={files}
                  buttonOnly
                  types={AcceptedMimeTypes}
                  disabled={files ? FILES_MAX_COUNT <= files.length : false}
                  maxFiles={FILES_MAX_COUNT}
                  maxSize={FILES_MAX_SIZE}
                  multiple
                  onChange={handleFileSelect}
                />
              )}
            </Stack>
          </AttachmentIconsWrapper>
        </Grid>
        <Grid item xs={9.5}>
          <SendTextWrapper>
            <Input
              label={`${t("messages.text")}...`}
              value={inputValue}
              variant="outlined"
              size="small"
              multiline
              maxLength={3000}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={handleKeyDown}
              maxRows={5}
              linesCount={1}
            />
          </SendTextWrapper>
        </Grid>
        <Grid item xs={1}>
          <SendIconWrapper>
            <div className="sending__icon">
              <Icon
                name="Send"
                size={24}
                color={theme.palette.secondary.main}
                onClick={onSubmit}
                disabled={
                  !inputValue && (!files?.length || Boolean(attachLinkList))
                }
              />
            </div>
          </SendIconWrapper>
        </Grid>
      </Grid>
    </Wrapper>
  );
};
