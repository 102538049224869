import styled from "styled-components";
import { FLEX_BETWEEN } from "ui-kit";

export const Wrapper = styled.div`
  .approval-table__position-cell {
    background-color: ${({ theme }) => theme.palette.info["4p"]};

    padding-top: ${({ theme }) => theme.spacing(2)};
    padding-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;

export const TitleWrapper = styled.div`
  ${FLEX_BETWEEN};
  margin-bottom: ${({ theme }) => theme.spacing(10)};
`;
