import React from "react";
import { useTranslation } from "react-i18next";

import { useCorrespondenceLinksById } from "api";
import { AttachedLinkContainer, openDrawer, useAppDispatch } from "app";
import { Table } from "ui-kit";

import { Button, Stack, Typography, useTheme } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { getLocaleDateFormat, hexToRgba } from "@sbm/fe-utils";
import { CorrespondenceTypeEnum, DrawerVariantsEnum } from "@types";

import { TABLE_COLUMNS_LINKS } from "./constants";
import { PageWrapper, TableActions, Wrapper } from "./styles";

interface CorrespondenceLinksProps {
  correspondenceId: number | undefined;
  isAuthor: boolean;
  type: CorrespondenceTypeEnum;
}

export const CorrespondenceLinksTable: React.FC<CorrespondenceLinksProps> = ({
  correspondenceId,
  isAuthor,
  type,
}) => {
  const { t } = useTranslation("correspondence");
  const dispatch = useAppDispatch();

  const theme = useTheme();

  const { data, isLoading } = useCorrespondenceLinksById(correspondenceId);

  const TableRows = React.useMemo(() => {
    return (
      data?.links?.flatMap((item, index: number) => {
        return {
          id: item.id,
          sequenceNumber: index + 1,
          assignment: t(`attachmentFilesAndLinks.${item.typeOfAttachment}`),
          link: item.shortNameOfTheDocument,
          linkAddedBy: item.employee.shortName,
          createdAt: getLocaleDateFormat(item.createdAt),
          note: item.descriptionForAttachment,
          linkedEntities: item.linkedEntities,
        };
      }) || []
    );
  }, [data, t]);

  const TableColumns: GridColDef[] = React.useMemo(() => {
    return TABLE_COLUMNS_LINKS.map((item) => ({
      ...item,
      headerName: t(item.headerName || ""),
    }));
  }, [t]);

  const handleAddLink = () => {
    dispatch(openDrawer(DrawerVariantsEnum.attachLinkInCorrespondence));
  };

  return (
    <PageWrapper $hasData={Boolean(data?.links.length)}>
      <Wrapper>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            variant="h6_semiBold"
            color={hexToRgba(theme.palette.primary.main, 0.7)}
            sx={{ width: "100%", mb: 6 }}
          >
            {t("attachmentFilesAndLinks.links")}
          </Typography>

          <TableActions>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              onClick={handleAddLink}
              disabled={!isAuthor}
            >
              {t("attachmentFilesAndLinks.add_link")}
            </Button>
          </TableActions>
        </Stack>
        <Stack height={data?.links.length ? "100%" : "280px"}>
          <Table
            rows={TableRows}
            columns={TableColumns}
            loading={isLoading}
            sx={{ maxHeight: "280px" }}
            hideFooterRowCount
            textForEmptyTable={t("attachmentFilesAndLinks.no_attached_links")}
          />
        </Stack>

        <AttachedLinkContainer
          correspondenceId={correspondenceId}
          type={type}
        />
      </Wrapper>
    </PageWrapper>
  );
};
