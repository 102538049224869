import React from "react";

import { MuiTelInput } from "mui-tel-input";
import { getStorageItem, STORAGE_KEYS } from "@sbm/fe-utils";
import { DefaultTFuncReturn } from "i18next";

interface PhoneInputProps {
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
  label?: string | DefaultTFuncReturn;
  helperText?: string;
  size?: "small" | "medium";
}
export const PhoneInput = React.forwardRef(({ required = false, size = "medium", ...rest }: PhoneInputProps, ref) => {
  const lang = getStorageItem(STORAGE_KEYS.lang) || "en";

  return (
    <MuiTelInput
      langOfCountryName={lang}
      defaultCountry="RU"
      inputRef={ref}
      required={required}
      size={size}
      fullWidth
      color="secondary"
      variant="outlined"
      {...rest}
    />
  );
});
