import styled from "styled-components";
import { FLEX_BETWEEN, FLEX_COLUMN_FULL } from "ui-kit";

import { hexToRgba } from "@sbm/fe-utils";

export const CardWrapper = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.spacing(2, 4, 2, 4)};
  margin: ${({ theme }) => theme.spacing(2, 0, 2, 0)};
  border-radius: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) =>
    hexToRgba(theme.palette.primary.main, 0.04)};
  box-shadow: ${({ theme }) => theme.shadows[2]};
  ${FLEX_BETWEEN};
    &:hover {
        box-shadow: ${({ theme }) => theme.shadows[4]};
        background-color: ${({ theme }) =>
          hexToRgba(theme.palette.primary.main, 0.08)};
    },
`;

export const UnreadMessagesCount = styled.div`
  position: absolute;
  padding: ${({ theme }) => theme.spacing(2)};
  top: -12px;
  left: -12px;
  ${FLEX_COLUMN_FULL};
  height: ${({ theme }) => theme.spacing(7)};
  width: ${({ theme }) => theme.spacing(7)};
  box-shadow: ${({ theme }) => theme.shadows[2]};
  background-color: ${({ theme }) => theme.palette.secondary.main};
  border-radius: 100%;
`;

export const EllipsisTextWrapper = styled.div<{ $height?: number }>`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  min-height: ${({ $height }) => `${$height}px`};
  max-height:  ${({ $height }) => `${$height}px`};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
      cursor: pointer
    },
}
`;
