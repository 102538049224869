import { UseFormReset } from "react-hook-form";
import toast from "react-hot-toast";

import { AxiosError } from "axios";

import { SnackbarMessages, getAPIErrorMessage } from "@sbm/fe-utils";
import { ModalVariants } from "@types";

import { IContractForm } from "../../containers";
import { IAttachmentForm } from "../../containers/attachments/AttachmentsContainer/components/ContractAttachmentForm/constants";
import { ISAAttachmentForm } from "../../containers/attachments/AttachmentsContainer/components/SAAttachmentForm/constants";
import { ISupAgreementForm } from "../../containers/sup-agreement/CreateSAContainer/types";
import { axiosService } from "../../services";
import {
  AppDispatch,
  closeDrawer,
  setAttachmentsAndFiles,
  setContractById,
  setContractData,
  setLoading,
  setModal,
  setPersonalData,
  setSupAgreementById,
} from "../index";

const directories = [
  "contractCategory",
  "contractType",
  "contractSubtype",
  "roleContractParty",
  "constructionComplex",
  "contractSubtypeTypicalSubjectOfTheContract",
  "organization",
  "conditionValidityBeginningContract",
  "conditionValidityExpiryContract",
  "conditionExecutionBeginningContract",
  "conditionExecutionExpiryContract",
  "pricingContract",
  "prepaymentCondition",
  "typicalRoleContractParty",
  "postpaymentCondition",
  "typeSupplementaryAgreement",
];

export const getContractData = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
      const { data } = await axiosService({
        endpoint: `directories`,
        body: {
          directories,
        },
      });

      const { data: employees } = await axiosService({
        endpoint: "employees/organizations-contract-departments",
      });

      dispatch(
        setContractData({
          ...data,
          responsibleForPreparationContractId: employees,
        })
      );
    } catch (err) {
      toast.error(SnackbarMessages.error);
    }

    try {
      const personalData = await axiosService({
        endpoint: `personal-data?limit=99`,
      });

      dispatch(setPersonalData(personalData.data));
    } catch (err) {
      const errorMsg = getAPIErrorMessage(err as AxiosError);
      toast.error(errorMsg);
    }

    return dispatch(setLoading(false));
  };
};

export const getContractById = (id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
      const { data } = await axiosService({
        endpoint: `contracts/${id}`,
      });

      dispatch(setContractById(data));
    } catch (err) {
      toast.error(SnackbarMessages.error);
    }

    return dispatch(setLoading(false));
  };
};

export const saveContract = (
  contract: IContractForm,
  reset: UseFormReset<IContractForm>
) => {
  const party1OrganizationId = contract?.parties?.[0]?.organizationId;
  const numberContract = contract?.numberContract;
  const dateContract = contract?.dateContract;

  const isCheckForExistingContractRequired = Boolean(
    numberContract && party1OrganizationId
  );

  return async (dispatch: AppDispatch) => {
    if (isCheckForExistingContractRequired) {
      const body: {
        numberContract: string | undefined;
        party1OrganizationId: number | undefined;
        dateContract?: Date | undefined;
      } = {
        numberContract,
        party1OrganizationId,
      };

      if (dateContract) {
        body["dateContract"] = dateContract;
      }

      const { data } = await axiosService({
        endpoint: `contracts/check`,
        body,
      });

      const isExistingContract = data?.exists;

      if (isExistingContract) {
        dispatch(
          setModal({
            open: true,
            variant: ModalVariants.existingContractWarning,
            content: {
              data: {
                contractData: contract,
              },
            },
          })
        );

        return;
      }
    }

    try {
      await axiosService({
        endpoint: `contracts`,
        method: "POST",
        body: contract,
      });

      await dispatch(getContractData());
      dispatch(closeDrawer());
      reset();
      toast.success(SnackbarMessages.success);
    } catch (err) {
      toast.error(SnackbarMessages.error);
    }
  };
};

export const editContract = (id: number, body: IContractForm) => {
  return async (dispatch: AppDispatch) => {
    try {
      await axiosService({
        endpoint: `contracts/${id}`,
        method: "PUT",
        body,
      });

      await dispatch(getContractData());
      await dispatch(getContractById(String(id)));
      dispatch(closeDrawer());
      toast.success(SnackbarMessages.success);
    } catch (err: unknown) {
      const errorMessage = getAPIErrorMessage(err as AxiosError);
      toast.error(errorMessage ?? SnackbarMessages.error);
    }
  };
};

/**
 * Supplementary agreements
 */

export const editSupAgreement = (id: number, sa: ISupAgreementForm) => {
  return async (dispatch: AppDispatch) => {
    try {
      await axiosService({
        endpoint: `supplementary-agreements/${id}`,
        method: "PUT",
        body: sa,
      });

      toast.success(SnackbarMessages.success);
    } catch (err) {
      const msg = getAPIErrorMessage(err as AxiosError);
      toast.error(msg);
    }
  };
};

/**
 * Attachments and files
 */
export const getContractAttachments = (
  page = 1,
  limit = 99,
  sortBy = "",
  contractId: string | undefined = undefined
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    const params = {
      sortBy: sortBy || ["fileStatus:ASC", "fileAppointment:ASC"],
      limit,
      page,
      "filter.contractId": contractId,
      "filter.supplementaryAgreementId": "null",
    };

    try {
      const { data } = await axiosService({
        endpoint: `/contract-attached-files`,
        body: params,
      });

      dispatch(setAttachmentsAndFiles(data));
    } catch (err) {
      toast.error(SnackbarMessages.error);
    }

    dispatch(setLoading(false));
  };
};

export const getSAAttachments = (
  page = 1,
  limit = 99,
  sortBy = "",
  saID: string | undefined = undefined
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    const params = {
      sortBy: sortBy || ["fileStatus:ASC", "fileAppointment:ASC"],
      limit,
      page,
      "filter.supplementaryAgreementId": saID,
    };

    try {
      const { data } = await axiosService({
        endpoint: `/contract-attached-files`,
        body: params,
      });

      dispatch(setAttachmentsAndFiles(data));
    } catch (err) {
      toast.error(SnackbarMessages.error);
    }

    dispatch(setLoading(false));
  };
};

export const addFile = (
  attachment: IAttachmentForm | ISAAttachmentForm,
  variant = "contract",
  isApplicationJson = false
) => {
  return async (dispatch: AppDispatch) => {
    const url = "/contract-attached-files";
    const route = variant === "sa" ? "/supplementary-agreement" : "/contract";

    const headers = isApplicationJson
      ? { "Content-Type": "application/json" }
      : { "Content-Type": "multipart/form-data" };

    try {
      await axiosService({
        endpoint: `${url}${route}`,
        method: "POST",
        body: attachment,
        headers,
      });

      dispatch(setAttachmentsAndFiles(null));
      toast.success(SnackbarMessages.success);
    } catch (err) {
      toast.error(SnackbarMessages.error);
    }
  };
};

export const getSupplementaryAgreement = (id: string | number) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
      const { data } = await axiosService({
        endpoint: `supplementary-agreements/${id}`,
      });

      dispatch(setSupAgreementById(data));
    } catch (err) {
      toast.error(SnackbarMessages.error);
    }

    dispatch(setLoading(false));
  };
};

/**
 * SIGNATORIES
 */

export const getOrganizations = (
  search = "",
  belongingToTheEvGroup?: boolean
) => {
  const params: {
    sortBy: string;
    limit: number;
    page: number;
    search: string;
    "filter.belongingToTheEvGroup"?: boolean;
  } = {
    sortBy: "",
    limit: 99,
    page: 1,
    search,
  };

  if (belongingToTheEvGroup) {
    params["filter.belongingToTheEvGroup"] = belongingToTheEvGroup;
  }

  return async () => {
    try {
      const { data } = await axiosService({
        endpoint: `organizations`,
        body: params,
      });

      return data;
    } catch (e) {
      toast.error(SnackbarMessages.error);
    }
  };
};

export const getOrganizationSignatories = (orgId: number, search?: string) => {
  const params = {
    sortBy: "",
    limit: 99,
    page: 1,
    search,
  };

  return async () => {
    try {
      const { data } = await axiosService({
        endpoint: `organizations/${orgId}/signatories`,
        body: params,
      });

      return data;
    } catch (e) {
      toast.error(SnackbarMessages.error);
    }
  };
};
