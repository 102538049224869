import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "app";
import { Table } from "ui-kit";

import { CardContent, Typography } from "@mui/material";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid-pro";
import {
  EnumApproverType,
  IApprovalRoute,
  IApproverTypicalApprovalRoute,
} from "@types";

import { getAgreementQueueColumnsByApproverType } from "../../helpers";
import { StyledCard } from "./styles";

interface Props {
  data?: IApprovalRoute[] | null | undefined;
  selectedRow?: IApprovalRoute | null;
}

export const ViewChosenApproversGanttChart: React.FC<Props> = ({
  data,
  selectedRow,
}) => {
  const { t } = useTranslation();

  const { selectedApprovalRoute } = useAppSelector((state) => state.contracts);

  const defaultRowData = data?.filter((item) => item.routeByDefault);

  const dataForChart: IApprovalRoute | null | undefined =
    selectedRow && data
      ? data.find((i) => i.id === selectedRow.id)
      : selectedApprovalRoute && data
      ? data.find((i) => i.id === selectedApprovalRoute.id)
      : defaultRowData?.[0];

  const approvers = useMemo(
    () =>
      data
        ? dataForChart?.approvers?.map(
            (item: IApproverTypicalApprovalRoute, index) => ({
              id: index,
              approverType:
                item.approverMission === EnumApproverType.structural_unit
                  ? item.structuralUnitName
                  : t(item.approverMission),
              queueNumber: item.queueNumber,
              structuralUnitId: item.structuralUnitId || undefined,
            })
          )
        : [],
    [data, t, dataForChart]
  );

  const rows: GridValidRowModel[] = React.useMemo(
    () => approvers || [],
    [approvers]
  );

  const columns: GridColDef[] = React.useMemo(
    () => getAgreementQueueColumnsByApproverType(approvers, t, "secondary"),
    [approvers, t]
  );

  return (
    <StyledCard>
      <CardContent>
        <Typography
          variant="body2"
          color="text.disabled"
          fontWeight={600}
          sx={{ mb: 2 }}
        >
          {t("agreement.queue")}
        </Typography>

        <Table rows={rows} columns={columns} hideFooterRowCount />
      </CardContent>
    </StyledCard>
  );
};
