import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useSendToArchiveMutation } from "api";
import { useAppDispatch, setModal } from "app";
import { Modal } from "ui-kit";

import { Button } from "@sbm/ui-components";

export const TaskSendToArchivePopup = ({
  open,
  onClose,
  data: { id },
}: {
  open: boolean;
  onClose: () => void;
  data: { id: string };
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { t: tTasks } = useTranslation("tasks");

  const { mutateAsync: sendToArchive, isLoading } = useSendToArchiveMutation();

  const handleSubmit = async () => {
    await sendToArchive(Number(id));
    dispatch(setModal(null));
    navigate("/");
  };

  const content = {
    title: tTasks("send_to_archive"),
    body: tTasks("send_to_archive_confirmation"),
    secondaryButton: (
      <Button
        onClick={onClose}
        variant="outlined"
        size="large"
        color="secondary"
      >
        {t("no")}
      </Button>
    ),
    mainButton: (
      <Button
        autoFocus
        variant="contained"
        color="secondary"
        size="large"
        loading={isLoading}
        onClick={handleSubmit}
      >
        {t("yes")}
      </Button>
    ),
  };

  return <Modal isOpen={open} onClose={onClose} content={content} />;
};
