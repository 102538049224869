import React from "react";
import { useTranslation } from "react-i18next";

import { getTaskStatusChipConfig, UI_CONFIG } from "app";

import { Chip, useTheme } from "@mui/material";
import { StatusForCorrespondenceEnum } from "@types";

interface CorrespondenceStatusChipProps {
  status: StatusForCorrespondenceEnum;
}

export const CorrespondenceStatusChip: React.FC<
  CorrespondenceStatusChipProps
> = ({ status }) => {
  const theme = useTheme();
  const { t } = useTranslation("tasks");

  const { backgroundColor, color } = getTaskStatusChipConfig(
    status,
    theme.palette,
    t,
    true
  );

  return (
    <Chip
      label={t(`statuses.${status}`)}
      sx={{
        width: "100%",
        minWidth: UI_CONFIG.chipWidth,
        fontWeight: 600,
        backgroundColor,
        color,
        borderColor: "transparent",
      }}
    />
  );
};
