import React, { useEffect, useState, useCallback } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useUploadFileToCorrespondenceMutation } from "api";
import {
  AcceptedMimeTypes,
  closeDrawer,
  resetTaskCreationStep,
  TransitionPrompt,
  useAppDispatch,
  useAppSelector,
} from "app";
import { DragAndDropUploader, Drawer } from "ui-kit";

import { Grid, Stack, Typography, useTheme } from "@mui/material";
import { Button, FormRadio, Input } from "@sbm/ui-components";
import {
  CorrespondenceTypeEnum,
  FileAttachedToCorrespondenceFileStatusEnum,
  FileAttachedToCorrespondenceTypeOfAttachmentEnum,
} from "@types";

import { FILES_MAX_COUNT, IAttachesCorrespondenceFileForm } from "./constants";
import { createRequestBody, getDisableState } from "./helpers";
import { LabelWrapper, Wrapper } from "./styles";

interface Props {
  correspondenceId: number | undefined;
  type: CorrespondenceTypeEnum;
}

export const AttachedCorrespondenceForm = ({
  correspondenceId,
  type,
}: Props) => {
  const { t: tAttachments } = useTranslation("attachments");
  const { t: tTasks } = useTranslation("tasks");
  const { t } = useTranslation("correspondence");

  const dispatch = useAppDispatch();
  const theme = useTheme();
  const ref = React.useRef<HTMLFormElement | null>(null);

  const isOutgoing = type === CorrespondenceTypeEnum.outgoingDocument;
  const isServiceNote = type === CorrespondenceTypeEnum.serviceNote;

  const { drawer } = useAppSelector((state) => state.global);

  const [showPrompt, setShowPrompt] = useState(false);

  const { reset, register, handleSubmit, control, getValues, setValue } =
    useForm<IAttachesCorrespondenceFileForm>();
  const fields = useWatch({ control });

  const { files, fileAppointment, fileStatus, descriptionForAttachment } =
    fields;

  const handleCloseDrawer = () => {
    reset();
    dispatch(resetTaskCreationStep());
    dispatch(closeDrawer());
  };

  const handleClose = () => {
    handleCloseDrawer();
  };

  const handleClosePrompt = () => {
    setShowPrompt(false);
  };

  const handleCancel = () => {
    if (
      Boolean(files?.length) ||
      getValues("descriptionForAttachment") ||
      getValues("fileAppointment")
    ) {
      setShowPrompt(true);
      return;
    }
    handleClose();
  };

  const handleConfirmTransition = () => {
    reset();
    handleClose();
  };

  const { mutate: uploadFile, isLoading } =
    useUploadFileToCorrespondenceMutation(
      handleConfirmTransition,
      handleConfirmTransition
    );

  const assignmentOptions = [
    {
      option: t("attachmentFilesAndLinks.main_document"),
      value: FileAttachedToCorrespondenceTypeOfAttachmentEnum.mainDocument,
    },
    {
      option: t("attachmentFilesAndLinks.attachment"),
      value: FileAttachedToCorrespondenceTypeOfAttachmentEnum.appendix,
    },
    {
      option: t("attachmentFilesAndLinks.auxiliary_file"),
      value: FileAttachedToCorrespondenceTypeOfAttachmentEnum.supportingFile,
    },
  ];

  const typesOptions = [
    {
      option: t("attachmentFilesAndLinks.signed_document"),
      value: FileAttachedToCorrespondenceFileStatusEnum.signedDocument,
      disabled: getDisableState(
        fileAppointment,
        FileAttachedToCorrespondenceFileStatusEnum.signedDocument
      ),
    },
    {
      option: t("attachmentFilesAndLinks.draft"),
      value: FileAttachedToCorrespondenceFileStatusEnum.draftDocument,
      disabled: getDisableState(
        fileAppointment,
        FileAttachedToCorrespondenceFileStatusEnum.draftDocument
      ),
    },
  ];

  const onSubmit = handleSubmit(async (body) => {
    if (!ref.current || !files || !correspondenceId) return;
    const requestBody = createRequestBody(body);
    uploadFile({ requestBody, correspondenceId });
  });

  const handleFileSelect = (files: File[] | File) => {
    setValue("files", files as File[]);
  };

  useEffect(() => {
    if (!isOutgoing && !isServiceNote) return;
    if (fileAppointment) {
      setValue("fileStatus", undefined);
    }
  }, [fileAppointment, setValue, isOutgoing, isServiceNote]);

  const getDisableStateForDropAreaAndBtn = useCallback(
    (forButton?: boolean) => {
      if (forButton && !files?.length) {
        return true;
      }
      if (!isOutgoing && !isServiceNote) {
        return !fileAppointment;
      }

      if (isOutgoing || isServiceNote) {
        if (forButton) {
          return (
            !fileAppointment ||
            (fileAppointment !==
              FileAttachedToCorrespondenceTypeOfAttachmentEnum.supportingFile &&
              !fileStatus)
          );
        }
        return (
          !fileAppointment ||
          (fileAppointment !==
            FileAttachedToCorrespondenceTypeOfAttachmentEnum.supportingFile &&
            !fileStatus) ||
          (files && Boolean(files?.length >= 10))
        );
      }
      if (!fileAppointment) {
        if (forButton) {
          return true;
        }
        return files && Boolean(files?.length >= 10);
      }
    },
    [fileAppointment, files, fileStatus, isOutgoing, isServiceNote]
  );

  return (
    <>
      {showPrompt && (
        <TransitionPrompt
          open={showPrompt}
          onClose={handleClosePrompt}
          onConfirm={handleCloseDrawer}
        />
      )}

      <Drawer
        title={t("attachmentFilesAndLinks.add_file")}
        open={Boolean(drawer)}
        onClose={handleCancel}
        actions={
          <Stack flexDirection="row" justifyContent="flex-end" gap={4}>
            <Button
              variant="outlined"
              onClick={handleCancel}
              color="secondary"
              size="large"
            >
              {t("cancel")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={onSubmit}
              disabled={getDisableStateForDropAreaAndBtn(true)}
              loading={isLoading}
            >
              {t("add")}
            </Button>
          </Stack>
        }
      >
        <Wrapper>
          <form onSubmit={onSubmit} noValidate ref={ref}>
            <Grid container spacing={4} sx={{ mb: 6 }}>
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  fontWeight={700}
                  color="primary.main"
                  mb={3}
                >
                  {t("attachmentFilesAndLinks.file_purpose")}
                </Typography>

                <FormRadio
                  name="fileAppointment"
                  control={control}
                  values={assignmentOptions}
                  className="radio__buttons"
                  flexDirection="column"
                  fullWidth={false}
                />
              </Grid>
              {(isOutgoing || isServiceNote) && (
                <Grid item xs={6}>
                  <Typography
                    variant="body1"
                    fontWeight={700}
                    color="primary.main"
                    mb={3}
                  >
                    {t("attachmentFilesAndLinks.file_type")}
                  </Typography>

                  <FormRadio
                    name="fileStatus"
                    control={control}
                    values={typesOptions}
                    className="radio__buttons"
                    flexDirection="column"
                    fullWidth={false}
                  />
                </Grid>
              )}
            </Grid>

            <DragAndDropUploader
              files={files}
              types={AcceptedMimeTypes}
              onChange={handleFileSelect}
              multiple
              maxFiles={FILES_MAX_COUNT}
              disabled={getDisableStateForDropAreaAndBtn()}
              label={
                !fileAppointment ? (
                  tTasks("upload.disabled")
                ) : (
                  <LabelWrapper>
                    <Typography variant="subtitle1">
                      {tAttachments("upload.default")}
                    </Typography>
                    <Typography
                      variant="h6"
                      fontWeight={700}
                      // @ts-ignore
                      sx={{ color: theme.palette.primary["70p"] }}
                    >
                      {tAttachments("upload.or")}
                    </Typography>
                  </LabelWrapper>
                )
              }
            />

            <Grid container spacing={4} sx={{ mt: 4 }}>
              <Grid item xs={12}>
                <Input
                  label={t("attachmentFilesAndLinks.description")}
                  variant="outlined"
                  size="medium"
                  {...register("descriptionForAttachment")}
                />
              </Grid>
            </Grid>
          </form>
        </Wrapper>
      </Drawer>
    </>
  );
};
