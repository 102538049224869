import * as React from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "ui-kit";

import {
  ListItemIcon,
  Typography,
  useTheme,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";

const ITEM_HEIGHT = 48;

interface StaffAllocationActionsProps {
  onEdit: () => void;
}

export const StaffAllocationActions: React.FC<StaffAllocationActionsProps> = ({
  onEdit,
}) => {
  const theme = useTheme();
  const { t } = useTranslation("");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handlePopoverOpen = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent> | undefined
  ) => {
    if (event) {
      event.stopPropagation();
      setAnchorEl(event.currentTarget as unknown as HTMLElement);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = () => {
    handleClose();
    onEdit();
  };

  return (
    <Stack display="flex">
      <Icon
        onClick={handlePopoverOpen}
        name="MoreVerticalIcon"
        color={theme.palette.text.primary}
      />
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            minWidth: "20ch",
            borderRadius: "16px",
          },
        }}
      >
        <MenuItem onClick={handleClick} sx={{ px: 4, py: 3 }}>
          <ListItemIcon>
            <Icon
              name="PencilIcon"
              color={theme.palette.primary.main}
              className="cursor-pointer"
            />
          </ListItemIcon>
          <Typography variant="body1">{t("edit")}</Typography>
        </MenuItem>
      </Menu>
    </Stack>
  );
};
