import React, { useEffect, useState } from "react";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  closeDrawer,
  resetTaskCreationStep,
  setModal,
  useAppDispatch,
  useAppSelector,
} from "app";
import { Icon } from "ui-kit";

import {
  Autocomplete,
  Chip,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Input } from "@sbm/ui-components";
import {
  TExecutionRequirement,
  ICreateTask,
  IExecutorOption,
  ModalVariants,
  TaskExecutor,
  TRegistration,
} from "@types";

import { WorkingDay } from "../Step1SelectOfExecuters/sections/ExecutionRequirements/WorkingDay";
import { CardTitleWrapper, CardWrapper } from "./styles";

interface Props {
  form: UseFormReturn<ICreateTask>;
  selectedOptions: IExecutorOption[] | [];
  setSelectedOptions: (arg: IExecutorOption[]) => void;
  requirements?: TExecutionRequirement | undefined;
  registration?: TRegistration | undefined;
}

export const SettingUpTasks = ({
  form,
  selectedOptions,
  setSelectedOptions,
  requirements,
  registration,
}: Props) => {
  const { t } = useTranslation("tasks");
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { control, register, setValue, reset, getValues } = form;
  const { fields, remove } = useFieldArray({
    control,
    name: "executorIds",
  });

  const [isFirstRender, setIsFirstRender] = useState(true);

  const defaultValues = form.watch("defaultValuesPerUser");

  const handleSwitchChange = (
    checked: boolean,
    index: number,
    field: string
  ) => {
    if (registration?.priority) {
      return;
    }
    const fieldsCopy: TaskExecutor[] = structuredClone(fields);
    if (field === "affiliationConstructionComplex" && !checked) {
      fieldsCopy[index]["prevConstructionComplexes"] =
        fieldsCopy[index]["constructionComplexes"];
      fieldsCopy[index]["constructionComplexes"] = [];
    }
    if (field === "affiliationConstructionComplex" && checked) {
      fieldsCopy[index]["constructionComplexes"] =
        fieldsCopy[index]["prevConstructionComplexes"];
      fieldsCopy[index]["prevConstructionComplexes"] = [];
    }

    // @ts-ignore
    fieldsCopy[index][field] = checked;
    setValue("executorIds", fieldsCopy);
  };

  useEffect(() => {
    if (!fields.length) {
      reset();
      dispatch(resetTaskCreationStep());
      dispatch(closeDrawer());
    }
  }, [fields, dispatch, reset]);

  React.useEffect(() => {
    const updatedFields = fields.map((field: TaskExecutor) => ({
      ...field,
      textOfTheTask: field.textOfTheTask ?? defaultValues?.textOfTheTask,
      personalControl: field.personalControl ?? defaultValues?.personalControl,
      reportRequired: field.reportRequired ?? defaultValues?.reportRequired,
      calendarDaysForExecution:
        field.calendarDaysForExecution ??
        defaultValues?.calendarDaysForExecution,
      scheduledDateOfExecution:
        field.scheduledDateOfExecution ??
        defaultValues?.scheduledDateOfExecution,
      scheduledTimeOfExecution:
        field.scheduledTimeOfExecution ??
        defaultValues?.scheduledTimeOfExecution,
      workingDaysForExecution:
        field.workingDaysForExecution ?? defaultValues?.workingDaysForExecution,
      highPriority: field.highPriority ?? defaultValues?.highPriority,
      affiliationConstructionComplex:
        field.affiliationConstructionComplex ??
        defaultValues?.affiliationConstructionComplex,
      constructionComplexes:
        field.constructionComplexes ?? defaultValues?.constructionComplexes,
    }));

    setValue("executorIds", updatedFields);
    setIsFirstRender(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { contractDirectories } = useAppSelector((state) => state.contracts);
  const disabledConstructionComplexes = form.watch(
    "defaultValuesPerUser.disabledConstructionComplexes"
  );

  const constructionComplexes = React.useMemo(() => {
    if (!contractDirectories) return [];

    return contractDirectories.constructionComplex.map((i) => ({
      value: i.id,
      title: i?.briefTextConstructionComplexName,
    }));
  }, [contractDirectories]);

  if (isFirstRender) {
    return null;
  }

  const handleDeleteExecutor = async (index: number, field: TaskExecutor) => {
    dispatch(
      setModal({
        open: true,
        variant: ModalVariants.removeExecutor,
        content: {
          data: {
            onRemove: () => {
              remove(index);
              const selectedIndex = selectedOptions.findIndex(
                (selectedOption) =>
                  selectedOption.uniqueId === field.uniqueId &&
                  selectedOption.position === field.position &&
                  selectedOption.organizationName === field.organizationName
              );
              const newSelected = [...selectedOptions];
              if (newSelected.length > 1) {
                newSelected.splice(selectedIndex, 1);
              }
              setSelectedOptions(newSelected);
            },
          },
        },
      })
    );
  };

  return (
    <>
      {fields.map((field, index) => {
        const selected = form.watch(
          `executorIds.${index}.constructionComplexes`
        );

        return (
          <CardWrapper key={index}>
            <CardTitleWrapper>
              <Typography variant="h9_semiBold" color="text.disabled">
                {field?.title}
              </Typography>
              <IconButton
                size="small"
                sx={{ borderRadius: 2, gap: 1 }}
                onClick={() => {
                  handleDeleteExecutor(index, field);
                }}
              >
                <Icon
                  size={18}
                  name="Trash2"
                  color={theme.palette.error.main}
                />
                <Typography
                  variant="caption"
                  color="error.main"
                  fontSize={13}
                  fontWeight={600}
                >
                  {t("delete")}
                </Typography>
              </IconButton>
            </CardTitleWrapper>
            <Input
              label={t("order_text")}
              variant="outlined"
              required
              size="medium"
              multiline
              defaultValue={defaultValues?.textOfTheTask}
              {...register(`executorIds.${index}.textOfTheTask`)}
            />
            <WorkingDay
              scheduledDateOfExecution={field.scheduledDateOfExecution}
              scheduledTimeOfExecution={field.scheduledTimeOfExecution}
              workingDaysForExecution={field.workingDaysForExecution}
              calendarDaysForExecution={field.calendarDaysForExecution}
              onChangeTime={(data) => {
                setValue(`executorIds.${index}.scheduledTimeOfExecution`, data);
              }}
              onChange={(data) => {
                setValue(
                  `executorIds.${index}.scheduledDateOfExecution`,
                  data.date
                );
                setValue(
                  `executorIds.${index}.scheduledTimeOfExecution`,
                  data.time
                );
                setValue(
                  `executorIds.${index}.workingDaysForExecution`,
                  data.workingDays
                );
                setValue(
                  `executorIds.${index}.calendarDaysForExecution`,
                  data.calendarDays
                );
              }}
              requirements={requirements}
            />
            <Stack flexDirection="column" my={4}>
              <FormControlLabel
                label={t("personal_control")}
                control={
                  <Switch
                    color="secondary"
                    {...register(`executorIds.${index}.personalControl`)}
                    onChange={(event, checked) =>
                      handleSwitchChange(checked, index, "personalControl")
                    }
                  />
                }
                checked={field.personalControl}
              />
              <FormControlLabel
                label={t("report_required")}
                control={
                  <Switch
                    color="secondary"
                    {...register(`executorIds.${index}.reportRequired`)}
                    onChange={(event, checked) =>
                      handleSwitchChange(checked, index, "reportRequired")
                    }
                  />
                }
                checked={field.reportRequired}
              />
              <FormControlLabel
                label={t("higher_priority")}
                sx={{
                  opacity: registration?.priority ? 0.5 : 1,
                }}
                control={
                  <Switch
                    color="secondary"
                    {...register(`executorIds.${index}.highPriority`)}
                    onChange={(event, checked) =>
                      handleSwitchChange(checked, index, "highPriority")
                    }
                  />
                }
                checked={field.highPriority}
              />
              <FormControlLabel
                label={t("affiliation_to_construction_complex")}
                sx={{ opacity: disabledConstructionComplexes.length ? 0.5 : 1 }}
                control={
                  <Switch
                    color="secondary"
                    {...register(
                      `executorIds.${index}.affiliationConstructionComplex`
                    )}
                    onChange={(event, checked) => {
                      if (!disabledConstructionComplexes.length) {
                        setValue(
                          `executorIds.${index}.affiliationConstructionComplex`,
                          checked
                        );
                        handleSwitchChange(
                          checked,
                          index,
                          "affiliationConstructionComplex"
                        );
                      }
                    }}
                    checked={field.affiliationConstructionComplex}
                  />
                }
              />
            </Stack>
            {field.affiliationConstructionComplex ? (
              <Autocomplete
                multiple
                options={constructionComplexes}
                value={constructionComplexes.filter((value) =>
                  selected?.includes(value.value)
                )}
                onChange={(event, newValue) => {
                  const idsToSet = [
                    ...disabledConstructionComplexes,
                    ...newValue
                      .filter(
                        (v) => !disabledConstructionComplexes.includes(v.value)
                      )
                      .map((i) => i.value),
                  ];
                  setValue(
                    `executorIds.${index}.constructionComplexes`,
                    idsToSet,
                    {
                      shouldDirty: true,
                    }
                  );
                }}
                getOptionLabel={(option) => option.title}
                defaultValue={constructionComplexes.filter((complex) =>
                  field.constructionComplexes?.includes(complex.value)
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    color="secondary"
                    size="medium"
                    label={t("affiliation_construction_complex")}
                  />
                )}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => {
                    return (
                      <div key={index}>
                        <Chip
                          label={option.title}
                          {...getTagProps({ index })}
                          disabled={disabledConstructionComplexes.includes(
                            option.value
                          )}
                        />
                      </div>
                    );
                  })
                }
              />
            ) : null}
          </CardWrapper>
        );
      })}
    </>
  );
};
