import { TFunction } from "i18next";

import { formatDateToQuery } from "@sbm/fe-utils";
import {
  IEmployee,
  StatusForAuthorEnum,
  StatusForExecutorEnum,
  TaskCategoryEnum,
  TasksViewEnum,
  TypeOfTheTaskEnum,
} from "@types";

import { TASKS_FILTERABLE_FIELDS, TasksFieldsEnum } from "./constants";

export const getTaskOptions = (
  fieldName: string,
  t: TFunction,
  employees: { items: IEmployee[] } | undefined
) => {
  switch (fieldName) {
    case TasksFieldsEnum.typeOfTheTask:
      return Object.values(TypeOfTheTaskEnum).map((type) => t(`types.${type}`));
    case TasksFieldsEnum.highPriority:
      return [t("priority.high"), t("priority.low")];
    case TasksFieldsEnum.overdue:
      return [t("overdue.yes"), t("overdue.no")];
    case TasksFieldsEnum.read:
      return [t("taskViewed.viewed"), t("taskViewed.unViewed")];
    case TasksFieldsEnum.taskExecutorEmployeeId:
    case TasksFieldsEnum.taskAuthorEmployeeId:
      return employees?.items?.map(
        (employee) => employee.personalData.longName
      );
    case TasksFieldsEnum.statusForExecutor:
      return Object.values(StatusForExecutorEnum).map((type) =>
        t(`statuses.${type}`)
      );
    case TasksFieldsEnum.statusForAuthor:
      return Object.values(StatusForAuthorEnum).map((type) =>
        t(`statuses.${type}`)
      );
    case TasksFieldsEnum.group:
      return Object.values(TaskCategoryEnum).map((type) =>
        t(`categories.${type}`)
      );
    default:
      return [];
  }
};

const findIdByName = (
  name: TasksFieldsEnum | TaskCategoryEnum,
  value: string,
  t: TFunction,
  employees: { items: IEmployee[] } | undefined,
  view?: TasksViewEnum
) => {
  let result: number | string | undefined;

  if (view === TasksViewEnum.calendar) {
    switch (name) {
      case TasksFieldsEnum.dateRange:
        result = formatDateToQuery(value);
        break;
    }
  } else {
    switch (name) {
      case TasksFieldsEnum.typeOfTheTask:
        result = Object.values(TypeOfTheTaskEnum).find(
          (type) => t(`types.${type}`) === value
        );
        break;
      case TasksFieldsEnum.highPriority:
        if (!value) break;
        result = value === t("priority.high") ? "true" : "false";
        break;
      case TasksFieldsEnum.read:
        if (!value) break;
        result = value === t("taskViewed.viewed") ? "true" : "false";
        break;
      case TasksFieldsEnum.overdue:
        if (!value) break;
        result = value && value === t("overdue.yes") ? "true" : "false";
        break;
      case TasksFieldsEnum.taskAuthorEmployeeId:
      case TasksFieldsEnum.taskExecutorEmployeeId:
        result = employees?.items?.find(
          (employee) => employee.personalData.longName === value
        )?.id;
        break;
      case TasksFieldsEnum.createdAt:
      case TasksFieldsEnum.actualDateOfExecution:
      case TasksFieldsEnum.scheduledDateOfExecution:
        result = formatDateToQuery(value);
        break;
      case TasksFieldsEnum.statusForExecutor:
        result = Object.values(StatusForExecutorEnum).find(
          (type) => t(`statuses.${type}`) === value
        );
        break;
      case TasksFieldsEnum.statusForAuthor:
        result = Object.values(StatusForAuthorEnum).find(
          (type) => t(`statuses.${type}`) === value
        );
        break;
      case TasksFieldsEnum.group:
        result = Object.values(TaskCategoryEnum).find((type) => type === value);
        break;
    }
  }

  if (!result) return;

  return result;
};

export const getTasksFilterOptions = (
  t: TFunction,
  employees: { items: IEmployee[] } | undefined
) => {
  return TASKS_FILTERABLE_FIELDS.map((item) => {
    const { field, title } = item;

    return {
      ...item,
      title: t(title),
      options: getTaskOptions(field, t, employees),
    };
  });
};

export const getTasksFilterValues = (
  selectedFilters: string[],
  t: TFunction,
  employees: { items: IEmployee[] } | undefined,
  view?: TasksViewEnum
) => {
  if (!selectedFilters?.length) return [];

  return selectedFilters.map((filter) => {
    const parts = filter.split("=");
    const filterKey = parts[0];
    const filterValue = parts[1];
    const values = filterValue.split(",");

    let query = `${filterKey}=`;

    values.forEach((value, index) => {
      const isLast = index === values.length - 1;

      const idOfFilter = findIdByName(
        filterKey as TasksFieldsEnum,
        value,
        t,
        employees,
        view
      );

      if (idOfFilter) {
        query = query + idOfFilter;
        if (!isLast) query = query + ",";
      }
    });

    return query;
  });
};
