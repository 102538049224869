import styled from "styled-components";

import {
  formControlLabelClasses,
  formGroupClasses,
  radioClasses,
} from "@mui/material";

export const Wrapper = styled.div`
  height: 100%;

  form {
    height: 100%;
  }

  .radio__buttons {
    > label {
      margin-left: 0;
    }
  }

  .${formGroupClasses.root} {
    .${formControlLabelClasses.root} {
      .${radioClasses.root} {
        flex: 0;
      }
    }
  }
`;
