import React, { useMemo } from "react";

import { useViewMessageMutation } from "api";
import { setShowMessageDetails, useAppDispatch, useAppSelector } from "app";
import { Icon } from "ui-kit";

import { Stack, Typography, useTheme } from "@mui/material";
import { getLocaleDateFormat } from "@sbm/fe-utils";
import { ConversationResponse } from "@types";

import { Wrapper } from "./styles";

interface Props {
  data: ConversationResponse;
  authorUserID: number;
  currentMessage: boolean;
}

export const ConversationCard = ({
  data,
  authorUserID,
  currentMessage,
}: Props) => {
  const theme = useTheme();
  const { message, user } = data;
  const { userProfile } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const { mutateAsync: viewMessage } = useViewMessageMutation();

  const isMessageOutgoing = useMemo(() => {
    const isOutgoingMessage = data.user.id === authorUserID;
    if (userProfile?.id === authorUserID) {
      return isOutgoingMessage;
    }
    return !isOutgoingMessage;
  }, [data.user.id, authorUserID, userProfile?.id]);

  const handleView = async (id: number, isMessageOutgoing: boolean) => {
    if (isMessageOutgoing) await viewMessage({ id, view: true });
  };

  return (
    <Stack
      onClick={() => handleView(message.id, isMessageOutgoing)}
      display="flex"
      width="100%"
      alignItems="center"
      flexDirection={isMessageOutgoing ? "row" : "row-reverse"}
    >
      <Wrapper
        $isOutgoing={isMessageOutgoing}
        $currentMessage={currentMessage}
        onClick={() => {
          dispatch(setShowMessageDetails(data.message.id));
        }}
      >
        <Typography
          variant={
            isMessageOutgoing && !currentMessage && !message?.messageViewed
              ? "body2"
              : "subtitle2"
          }
          color="primary.main"
          fontWeight={
            isMessageOutgoing && !currentMessage && !message?.messageViewed
              ? 700
              : 400
          }
          sx={{ whiteSpace: "pre-line" }}
        >
          {message.textOfTheMessage?.replace(/\s+$/g, "")}
        </Typography>
        <div className="message_description">
          <div>
            {message.hasAttachedFile ? (
              <Icon
                name="Paperclip"
                size={16}
                color={theme.palette.primary.main}
              />
            ) : (
              <div />
            )}
            {message.hasAttachedLink ? (
              <Icon name="Link" size={16} color={theme.palette.primary.main} />
            ) : (
              <div />
            )}
          </div>

          <Typography variant="body4">
            {message.inSendingProcess ? (
              "Sending..."
            ) : isMessageOutgoing ? (
              getLocaleDateFormat(message.createdAt, "hh:mm", true)
            ) : (
              <Stack direction="row" alignItems="center" gap={2}>
                <Typography variant="body4">
                  {getLocaleDateFormat(message.createdAt, "hh:mm", true)}
                </Typography>
                {message?.messageViewed ? (
                  <Icon
                    name="CheckCheck"
                    size={16}
                    strokeWidth={2}
                    color={theme.palette.secondary.main}
                  />
                ) : (
                  <Icon
                    name="Check"
                    size={16}
                    strokeWidth={2}
                    color={theme.palette.secondary.main}
                  />
                )}
              </Stack>
            )}
          </Typography>
        </div>
      </Wrapper>
    </Stack>
  );
};
