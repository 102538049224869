import React, { useEffect } from "react";
import { UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { Input } from "@sbm/ui-components";
import { IOrganization, StandartPhoneTypeEnum } from "@types";

interface IOptionType {
  inputValue?: string;
  title: StandartPhoneTypeEnum | string;
}

interface Props {
  position: number;
  defaultValue: StandartPhoneTypeEnum | string | undefined;
  setValue: UseFormSetValue<IOrganization>;
}

const filter = createFilterOptions<IOptionType>();

export const PhoneTypeAutocomplete: React.FC<Props> = ({
  position,
  setValue,
  defaultValue,
}) => {
  const { t } = useTranslation("common", {
    keyPrefix: "create_organization.contacts_section",
  });

  const [autocompleteValue, setAutocompleteValue] =
    React.useState<IOptionType | null>(null);

  const handleChange = (
    _: React.SyntheticEvent,
    newValue: string | IOptionType | null
  ) => {
    if (typeof newValue === "string") {
      setAutocompleteValue({
        title: newValue,
      });

      setValue(`phone.${position}.customPhoneType`, newValue, {
        shouldDirty: true,
      });
      setValue(`phone.${position}.phoneType`, undefined, {
        shouldDirty: true,
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setAutocompleteValue({
        title: newValue.inputValue,
      });
      setValue(`phone.${position}.customPhoneType`, newValue.inputValue, {
        shouldDirty: true,
      });
      setValue(`phone.${position}.phoneType`, undefined, {
        shouldDirty: true,
      });
    } else {
      const valueToSet = Object.values(StandartPhoneTypeEnum).find(
        (i) => t(`phone_types.${i}`) === newValue?.title
      );

      setValue(`phone.${position}.customPhoneType`, undefined, {
        shouldDirty: true,
      });
      setValue(`phone.${position}.phoneType`, valueToSet, {
        shouldDirty: true,
      });
      setAutocompleteValue(newValue);
    }
  };

  const options = Object.values(StandartPhoneTypeEnum).map((item) => ({
    title: t(`phone_types.${item}`),
    inputValue: "",
  }));

  useEffect(() => {
    if (defaultValue) {
      setAutocompleteValue({
        title: Object.values(StandartPhoneTypeEnum).includes(
          defaultValue as StandartPhoneTypeEnum
        )
          ? t(`phone_types.${defaultValue}`)
          : defaultValue,
      });
    }
  }, [defaultValue, t]);

  return (
    <Autocomplete
      freeSolo
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      value={autocompleteValue}
      onChange={handleChange}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue === option.title
        );
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            title: `${t("add_button_label")} "${inputValue}"`,
          });
        }

        return filtered;
      }}
      options={options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      renderOption={(props, option) => {
        return <li {...props}>{option.title}</li>;
      }}
      sx={{ width: "100%" }}
      renderInput={(params) => (
        <Input {...params} label={t("phone_type_input_label")} />
      )}
    />
  );
};
