import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useQueryParams } from "app";

export const useTabNavigation = () => {
  const { get, set } = useQueryParams();

  const initialTab = useMemo((): number => {
    const tabFromUrl = get("tab", false) as string;
    return tabFromUrl ? parseInt(tabFromUrl, 10) : 0;
  }, [get]);

  const [tab, setTab] = useState<number>(initialTab);

  const handleChange = useCallback(
    (_: SyntheticEvent, newValue: number) => {
      set("tab", String(newValue));
      setTab(newValue);
    },
    [set]
  );

  useEffect(() => {
    if (initialTab !== tab) {
      handleChange(
        new Event("change") as unknown as React.SyntheticEvent,
        initialTab
      );
    }
  }, [initialTab, handleChange, tab]);

  useEffect(() => {
    if (initialTab) {
      if (tab === initialTab) return;

      handleChange(
        new Event("change") as unknown as React.SyntheticEvent,
        initialTab
      );
    }
  }, [initialTab, handleChange, tab]);

  return { tab, handleChange };
};
