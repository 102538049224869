import * as React from "react";
import { useTranslation } from "react-i18next";

import { setModal, useAppDispatch } from "app";
import { Icon } from "ui-kit";

import {
  ListItemIcon,
  Typography,
  useTheme,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import { ModalVariants } from "@types";

import betweenHorizontalStart from "../../../../../../assets/images/icons/between-horizontal-start.svg";

const ITEM_HEIGHT = 48;

interface Props {
  amountOfDays: number;
  queueNumber: number;
}

export const ApprovalSubjectActionsMenu = ({
  amountOfDays,
  queueNumber,
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation("correspondence");
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handlePopoverOpen = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent> | undefined
  ) => {
    if (event) {
      event.stopPropagation();
      setAnchorEl(event.currentTarget as unknown as HTMLElement);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (type: string) => {
    dispatch(
      setModal({
        open: true,
        variant: ModalVariants.changeApprovalRoute,
        content: {
          data: {
            type,
            duration: amountOfDays,
            queueNumber: queueNumber,
          },
        },
      })
    );
  };

  return (
    <Stack display="flex">
      <Icon
        onClick={handlePopoverOpen}
        name="MoreVerticalIcon"
        color={theme.palette.primary.main}
      />
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            minWidth: "20ch",
            borderRadius: "16px",
          },
        }}
      >
        <MenuItem
          key="queue"
          disabled
          sx={{ px: 4, py: 3 }}
          onClick={() => handleChange("queue")}
        >
          <ListItemIcon>
            <img
              src={betweenHorizontalStart}
              alt="betweenHorizontalStart"
              draggable={false}
              width={24}
              height={24}
            />
          </ListItemIcon>
          <Typography variant="body1">{t("change_queue")}</Typography>
        </MenuItem>
        <MenuItem
          key="duration"
          disabled
          sx={{ px: 4, py: 3 }}
          onClick={() => handleChange("duration")}
        >
          <ListItemIcon>
            <Icon name="Calendar" color={theme.palette.primary.main} />
          </ListItemIcon>
          <Typography variant="body1">{t("change_duration")}</Typography>
        </MenuItem>
        <MenuItem key="delete" disabled sx={{ px: 4, py: 3 }}>
          <ListItemIcon>
            <Icon name="Trash2" color={theme.palette.error.main} />
          </ListItemIcon>
          <Typography variant="body1" color={"error.main"}>
            {t("delete")}
          </Typography>
        </MenuItem>
      </Menu>
    </Stack>
  );
};
