import styled from "styled-components";
import { FLEX_COLUMN_FULL } from "ui-kit";

export const MessageCountWrapper = styled.div`
  ${FLEX_COLUMN_FULL};
  width: 30px;
  height: 30px;
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding: ${({ theme }) => theme.spacing(2)};
  border-radius: 100%;
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: ${({ theme }) => theme.shadows[4]};
`;
