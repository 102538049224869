import { ContractStatusEnum } from "@types";

export const DEFAULT_SORT_MODEL = "dateOfSigningSupplementaryAgreement:DESC";
export const ATTACHMENTS_DEFAULT_SORT_MODEL = "";

export enum TabEnums {
  Contract,
  SupAgreements,
  Attachments,
  EventLog,
  Approval,
}

export const STATUSES_TO_SHOW_AGREEMENT = [
  ContractStatusEnum.inAgreement,
  ContractStatusEnum.underSigning,
];

export const STATUSES_TO_SHOW_SUP_AGREEMENT = [
  ContractStatusEnum.inAction,
  ContractStatusEnum.terminated,
  ContractStatusEnum.disputed,
  ContractStatusEnum.canceled,
  ContractStatusEnum.executed,
];
