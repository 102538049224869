export enum MimeTypesEnum {
  pdf = "application/pdf",
  doc = "application/msword",
  docx = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  jpg = "image/jpg",
  jpeg = "image/jpeg",
  png = "image/png",
  bmp = "image/bmp",
  dwg = "image/x-dwg",
  octec = "application/octet-stream",
  xls = "application/vnd.ms-excel",
  xlsx = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
}
