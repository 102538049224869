import styled from "styled-components";
import { FLEX_ROW_FULL } from "ui-kit";

export const CorrespondentAvatarWrapper = styled.div<{
  $fromSystem?: boolean;
}>`
  ${FLEX_ROW_FULL};
  width: 40px;
  height: 40px;
  color: ${({ theme, $fromSystem }) =>
    $fromSystem ? theme.palette.warning.dark : theme.palette.text.black};
  border-color: ${({ theme, $fromSystem }) =>
    $fromSystem ? theme.palette.warning.dark : theme.palette.primary.main};
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
`;
