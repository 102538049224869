import React, { useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useGetConversation } from "api";

import { Grid, Stack } from "@mui/material";
import { FormSelect } from "@sbm/ui-components";
import { IConversationList, IMessageInfo, MessageViewResponse } from "@types";

import { MessagesSection } from "./MessagesSection/MessagesSection";
import { SendingMessage } from "./SendMessage/SendingMessage";
import { Wrapper } from "./styles";

interface Props {
  authorUserID: number;
  authorEmployeeID: number;
  userProfileID: number;
  messageData: MessageViewResponse;
  recipientsList: { value: number; option: string }[];
  messageInfo: IMessageInfo | null;
}

export const ConversationSection = ({
  authorUserID,
  authorEmployeeID,
  recipientsList,
  messageData,
  userProfileID,
  messageInfo,
}: Props) => {
  const { t } = useTranslation();

  const getDefaultValues = useMemo(() => {
    return {
      recipients: messageInfo?.correspondent[0]?.userId,
    };
  }, [messageInfo]);

  const { control } = useForm<IConversationList>({
    defaultValues: getDefaultValues,
  });

  const fields = useWatch({ control });

  const { recipients } = fields;

  const isIncomingMessage = userProfileID === messageData.author?.userId;

  const isMessageFromSystem = messageInfo?.correspondent[0].type === "system";

  const { data: conversationData } = useGetConversation({
    userId: isMessageFromSystem
      ? userProfileID
      : authorUserID === userProfileID
      ? recipients || recipientsList[0]?.value
      : //Todo need remove this comment after approving by PO
        // ? userProfileID
        authorUserID,
    conversationId:
      authorUserID === userProfileID
        ? recipients || recipientsList[0]?.value
        : userProfileID,
    isMessageFromSystem,
  });
  const [localMessagesList, setLocalMessagesList] = useState(conversationData);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (conversationData) {
      setLocalMessagesList(conversationData);
    }
  }, [conversationData]);

  useEffect(() => {
    setProcessing(false);
  }, [recipients]);

  const handleSendMessage = (messageText: string, id?: number) => {
    if (conversationData && id) {
      setLocalMessagesList([
        ...conversationData,
        {
          message: {
            textOfTheMessage: messageText,
            hasAttachedFile: false,
            hasAttachedLink: false,
            inSendingProcess: true,
            id,
            createdAt: String(new Date()),
          },
          user: {
            id: isIncomingMessage ? id : authorUserID,
            personalData: {
              id: isIncomingMessage ? id : authorUserID,
              longName: "",
              surname: "",
              name: "",
              patronymic: "",
              additionalInformation: "",
              createdAt: String(new Date()),
              updatedAt: String(new Date()),
            },
          },
        },
      ]);
      setProcessing(true);
    }
  };

  return (
    <Wrapper>
      <Stack width="100%" px={6} mb={2} mt={-2}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {isIncomingMessage ? (
              recipientsList.length > 1 ? (
                <FormSelect
                  label={t("correspondent")}
                  name="recipients"
                  size="medium"
                  variant="standard"
                  values={recipientsList}
                  control={control}
                />
              ) : null
            ) : null}
          </Grid>
        </Grid>
      </Stack>
      {userProfileID ? (
        <MessagesSection
          conversationData={localMessagesList}
          authorUserID={authorUserID}
          processing={processing}
        />
      ) : null}
      {isMessageFromSystem ? null : (
        <SendingMessage
          recipientId={recipients}
          authorUserID={authorUserID}
          authorEmployeeID={authorEmployeeID}
          messageData={messageData}
          handleSendMessage={handleSendMessage}
        />
      )}
    </Wrapper>
  );
};
