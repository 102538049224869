import React from "react";
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Icon } from "ui-kit";

import {
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { EMAIL_REGEX, hexToRgba } from "@sbm/fe-utils";
import { Button, Input } from "@sbm/ui-components";
import { IOrganization } from "@types";

interface EmailsProps {
  register: UseFormRegister<IOrganization>;
  setValue: UseFormSetValue<IOrganization>;
  control: Control<IOrganization>;
  errors: FieldErrors<IOrganization>;
}

export const Emails: React.FC<EmailsProps> = ({
  register,
  setValue,
  control,
  errors,
}) => {
  const { palette } = useTheme();
  const { t } = useTranslation("common", {
    keyPrefix: "create_organization.contacts_section",
  });

  const { email = [] } = useWatch({ control });

  const handleAdd = () => {
    const dataToSet = [...email, { email: "" }] as IOrganization["email"];
    setValue("email", dataToSet, { shouldDirty: true });
  };

  const handleDelete = (position: number) => {
    const newEmails = (email?.filter((_, index) => index !== position) ||
      []) as IOrganization["email"];

    setValue("email", newEmails, {
      shouldDirty: true,
    });
  };

  return (
    <Card>
      <CardContent>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mb={email.length > 0 ? 4 : 0}
        >
          <Typography variant="h9_semiBold" color="text.disabled">
            {t("emails_section_title")}
          </Typography>

          <Button
            size="small"
            color="secondary"
            variant="text"
            onClick={handleAdd}
            startIcon={
              <Icon name="Plus" size={18} color={palette.secondary.main} />
            }
          >
            {t("add_button_label")}
          </Button>
        </Stack>

        <Stack gap={4}>
          {email.map((_, index) => {
            return (
              <Stack
                key={index}
                flexDirection="row"
                alignItems="center"
                gap={4}
              >
                <Input
                  required
                  label={t("email_input_label")}
                  maxLength={100}
                  error={Boolean(errors.email?.[index]?.email)}
                  {...register(`email.${index}.email`, {
                    required: true,
                    pattern: EMAIL_REGEX,
                  })}
                />

                <Input
                  label={t("email_type_input_label")}
                  maxLength={100}
                  {...register(`email.${index}.emailType`)}
                />

                <IconButton size="small" onClick={() => handleDelete(index)}>
                  <Icon
                    size={20}
                    name="XCircle"
                    className="cursor-pointer"
                    color={hexToRgba(palette.primary.main, 0.54)}
                  />
                </IconButton>
              </Stack>
            );
          })}
        </Stack>
      </CardContent>
    </Card>
  );
};
