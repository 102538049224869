import { Box, Button } from "@mui/material";
import styled from "styled-components";

export const LoadingLine = styled(Box)<{ loading: boolean; isHovered: boolean }>`
  position: absolute;
  height: 4px;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  width: 100%;
  bottom: 0;
  left: 0;

  animation: ${({ loading }) => (loading ? `loadingBar 3s linear forwards` : "none")};
  animation-play-state: ${({ isHovered }) => (isHovered ? "paused" : "running")};
  ${`
  @keyframes loadingBar {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
`}
`;

export const StyledButton = styled(Button)`
  overflow: hidden;
  min-width: 132px;
`;
