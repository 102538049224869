import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useAcceptExecution, useApproveExecution } from "api";
import { openDrawer, setModal, useAppDispatch } from "app";
import { Icon } from "ui-kit";

import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Button, DelayedButton } from "@sbm/ui-components";
import {
  DrawerVariantsEnum,
  ITaskById,
  ModalVariants,
  StatusForAuthorEnum,
  StatusForExecutorEnum,
  TIconNames,
  TypeOfTheTaskEnum,
} from "@types";

import { CreateTaskContainer } from "../../CreateTaskContainer";

interface TaskDetailsHeaderProps {
  type: TypeOfTheTaskEnum;
  isAuthor: boolean;
  isExecutor: boolean;
  status: StatusForAuthorEnum | StatusForExecutorEnum;
  data: ITaskById;
  registerSBMRequest?: boolean;
  authorStatus?: StatusForAuthorEnum;
}

export const TaskDetailsHeader: React.FC<TaskDetailsHeaderProps> = ({
  type,
  status,
  isAuthor,
  data,
  isExecutor,
  registerSBMRequest,
  authorStatus,
}) => {
  const { palette } = useTheme();
  const { t } = useTranslation("tasks");
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent> | undefined
  ) => {
    if (event) {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    }
  };

  const { mutate: approveExecution, isLoading: isApproveLoading } =
    useApproveExecution();

  const { mutate: acceptExecution, isLoading: isAcceptLoading } =
    useAcceptExecution();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCreateTask = useCallback(async () => {
    dispatch(openDrawer(DrawerVariantsEnum.task));
    setAnchorEl(null);
  }, [dispatch]);

  const handleConfirmSending = useCallback(() => {
    console.log("confirm sending");
  }, []);

  const handleRegisterRequest = useCallback(() => {
    console.log("Register request");
  }, []);

  const handleAddResolution = useCallback(() => {
    console.log("add resolution");
  }, []);

  const handleCompleteExecution = useCallback(() => {
    dispatch(
      setModal({
        open: true,
        variant: ModalVariants.completeExecution,
        content: { data: { id } },
      })
    );
    setAnchorEl(null);
  }, [dispatch, id]);

  const handleSendForRevision = useCallback(() => {
    dispatch(
      setModal({
        open: true,
        variant: ModalVariants.sendForRevision,
        content: { data: { id } },
      })
    );
    setAnchorEl(null);
  }, [dispatch, id]);

  const handleReject = useCallback(async () => {
    dispatch(
      setModal({
        open: true,
        variant: ModalVariants.rejectExecution,
        content: { data: { id } },
      })
    );
    setAnchorEl(null);
  }, [dispatch, id]);

  const handleAbortExecution = useCallback(async () => {
    dispatch(
      setModal({
        open: true,
        variant: ModalVariants.abortExecution,
        content: {
          data: {
            id,
          },
        },
      })
    );
    setAnchorEl(null);
  }, [dispatch, id]);

  const handleConfirmExecution = useCallback(async () => {
    dispatch(
      setModal({
        open: true,
        variant: ModalVariants.confirmExecution,
        content: {
          data: {
            id,
            reportRequired: data.executionRequirements.reportRequired,
          },
        },
      })
    );
    setAnchorEl(null);
  }, [dispatch, id, data]);

  const handleEdit = useCallback(async () => {
    dispatch(openDrawer(DrawerVariantsEnum.editTask));
  }, [dispatch]);

  const handleApproveExecution = useCallback(async () => {
    approveExecution(Number(id));
  }, [approveExecution, id]);

  const handleSendToArchive = useCallback(async () => {
    dispatch(
      setModal({
        open: true,
        variant: ModalVariants.sendToArchive,
        content: {
          data: {
            id,
          },
        },
      })
    );
  }, [dispatch, id]);

  const handleAcceptExecution = useCallback(async () => {
    acceptExecution({ id: Number(id) });
  }, [id, acceptExecution]);

  const renderActionButton = () => {
    if (type === TypeOfTheTaskEnum.requestToSupport) {
      if (isAuthor) {
        switch (status) {
          case StatusForAuthorEnum.sentToSupport:
          case StatusForAuthorEnum.rejected:
            return (
              <Button
                size="large"
                color="secondary"
                variant="contained"
                onClick={handleEdit}
                disabled
              >
                {t("edit")}
              </Button>
            );

          case StatusForAuthorEnum.removalFromControl:
            return (
              <DelayedButton
                size="large"
                color="secondary"
                variant="contained"
                onClick={handleApproveExecution}
                loading={isApproveLoading}
                disabled
              >
                {t("approve.execution")}
              </DelayedButton>
            );
          default:
            return null;
        }
      } else if (isExecutor) {
        // Executor scenario
        switch (status) {
          case StatusForExecutorEnum.newRequest:
          case StatusForExecutorEnum.returnForRevision:
            return (
              <DelayedButton
                size="large"
                color="secondary"
                variant="contained"
                onClick={handleAcceptExecution}
                disabled
                loading={isAcceptLoading}
              >
                {t("accept.for.execution")}
              </DelayedButton>
            );
          case StatusForExecutorEnum.acceptedForWork:
            return (
              <Button
                size="large"
                color="secondary"
                variant="contained"
                onClick={handleCreateTask}
                disabled
              >
                {t("create.task")}
              </Button>
            );
          default:
            return null;
        }
      } else if (
        registerSBMRequest &&
        authorStatus === StatusForAuthorEnum.sentToSupport
      ) {
        return (
          <Button
            size="large"
            color="secondary"
            variant="contained"
            onClick={handleRegisterRequest}
            disabled
          >
            {t("feedback.register_request")}
          </Button>
        );
      }
    } else if (
      type === TypeOfTheTaskEnum.directTask ||
      type === TypeOfTheTaskEnum.taskByTheDocument
    ) {
      // Author scenario
      if (isAuthor) {
        switch (status) {
          case StatusForAuthorEnum.draft:
          case StatusForAuthorEnum.sentToExecutor:
          case StatusForAuthorEnum.rejected:
            if (
              type === TypeOfTheTaskEnum.taskByTheDocument &&
              status === StatusForAuthorEnum.draft
            )
              return null;

            return (
              <Button
                size="large"
                color="secondary"
                variant="contained"
                onClick={handleEdit}
              >
                {t("edit")}
              </Button>
            );
          case StatusForAuthorEnum.removalFromControl:
            return (
              <DelayedButton
                size="large"
                color="secondary"
                variant="contained"
                onClick={handleApproveExecution}
                loading={isApproveLoading}
              >
                {t("approve.execution")}
              </DelayedButton>
            );
          case StatusForAuthorEnum.done:
          case StatusForAuthorEnum.canceled:
            return (
              <Button
                size="large"
                color="secondary"
                variant="contained"
                onClick={handleSendToArchive}
              >
                {t("send_to_archive")}
              </Button>
            );
          default:
            return null;
        }
      } else if (isExecutor) {
        // Executor scenario
        switch (status) {
          case StatusForExecutorEnum.newTask:
          case StatusForExecutorEnum.returnForRevision:
            return (
              <DelayedButton
                size="large"
                color="secondary"
                variant="contained"
                onClick={handleAcceptExecution}
                loading={isAcceptLoading}
              >
                {t("accept.for.execution")}
              </DelayedButton>
            );
          case StatusForExecutorEnum.acceptedForExecution:
            return (
              <Button
                size="large"
                color="secondary"
                variant="contained"
                onClick={handleConfirmExecution}
              >
                {t("confirm.execution")}
              </Button>
            );
          case StatusForExecutorEnum.delegated:
            return (
              <Button
                size="large"
                color="secondary"
                variant="contained"
                onClick={handleCreateTask}
              >
                {type === TypeOfTheTaskEnum.taskByTheDocument
                  ? t("create.task")
                  : t("delegate.task.again")}
              </Button>
            );
          default:
            return null;
        }
      }
    } else if (type === TypeOfTheTaskEnum.resolutionForTheDocument) {
      if (isExecutor) {
        switch (status) {
          case StatusForExecutorEnum.newTask:
            return (
              <Button
                size="large"
                color="secondary"
                variant="contained"
                onClick={handleCreateTask}
              >
                {t("create.resolution")}
              </Button>
            );
          case StatusForExecutorEnum.resolutionIssued:
            return (
              <Button
                size="large"
                color="secondary"
                variant="contained"
                onClick={handleAddResolution}
              >
                {t("add.resolution")}
              </Button>
            );
          case StatusForExecutorEnum.done:
          case StatusForExecutorEnum.executedWithoutResolution:
            return (
              <Button
                size="large"
                color="secondary"
                variant="contained"
                onClick={handleSendToArchive}
              >
                {t("send_to_archive")}
              </Button>
            );
        }
      }
    } else if (type === TypeOfTheTaskEnum.selfAssignment) {
      if (isExecutor) {
        switch (status) {
          case StatusForExecutorEnum.doneInPerson:
          case StatusForExecutorEnum.canceled:
            return (
              <Button
                size="large"
                color="secondary"
                variant="contained"
                onClick={handleSendToArchive}
              >
                {t("send_to_archive")}
              </Button>
            );
        }
      }
    }

    return null;
  };

  const MenuOptions: {
    icon: string;
    color: string;
    translation_key: string;
    onClick: () => void;
    disabled?: boolean;
  }[] = useMemo(() => {
    if (type === TypeOfTheTaskEnum.requestToSupport) {
      // Author scenario
      if (isAuthor) {
        switch (status) {
          case StatusForAuthorEnum.sentToSupport:
            return registerSBMRequest
              ? [
                  {
                    icon: "Send",
                    color: palette.primary.main,
                    translation_key: "feedback.register_request",
                    onClick: handleSendForRevision,
                    disabled: true,
                  },
                ]
              : [];
          case StatusForAuthorEnum.removalFromControl:
            return [
              {
                icon: "ArrowUpRight",
                color: palette.primary.main,
                translation_key: "send.for.revision",
                onClick: handleSendForRevision,
                disabled: true,
              },
            ];
          default:
            return [];
        }
      } else if (isExecutor) {
        // Executor scenario
        switch (status) {
          case StatusForExecutorEnum.newRequest:
          case StatusForExecutorEnum.returnForRevision:
            return [
              {
                icon: "XCircle",
                color: palette.error.main,
                translation_key: "reject",
                onClick: handleReject,
                disabled: true,
              },
            ];
          case StatusForExecutorEnum.acceptedForWork:
            return [
              {
                icon: "XCircle",
                color: palette.error.main,
                translation_key: "abort.execution",
                onClick: handleAbortExecution,
                disabled: true,
              },
              {
                icon: "Check",
                color: palette.success.main,
                translation_key: "confirm.execution",
                onClick: handleConfirmExecution,
                disabled: true,
              },
            ];
          default:
            return [];
        }
      }
    } else if (
      type === TypeOfTheTaskEnum.directTask ||
      type === TypeOfTheTaskEnum.taskByTheDocument
    ) {
      // Author scenario
      if (isAuthor) {
        switch (status) {
          case StatusForAuthorEnum.draft:
            if (type === TypeOfTheTaskEnum.taskByTheDocument) return [];

            return [
              {
                icon: "Send",
                color: palette.primary.main,
                translation_key: "confirm.sending",
                onClick: handleConfirmSending,
              },
            ];
          case StatusForAuthorEnum.removalFromControl:
            return [
              {
                icon: "Send",
                color: palette.primary.main,
                translation_key: "send.for.revision",
                onClick: handleSendForRevision,
              },
            ];
          case StatusForAuthorEnum.done:
            return [
              {
                icon: "Send",
                color: palette.primary.main,
                translation_key: "send.for.revision",
                onClick: handleSendForRevision,
              },
            ];
          default:
            return [];
        }
      } else if (isExecutor) {
        // Executor scenario
        switch (status) {
          case StatusForExecutorEnum.newTask:
          case StatusForExecutorEnum.returnForRevision:
            return [
              {
                icon: "ArrowUpRight",
                color: palette.primary.main,
                translation_key: "delegate.task",
                onClick: handleCreateTask,
              },
              {
                icon: "XCircle",
                color: palette.error.main,
                translation_key: "reject",
                onClick: handleReject,
              },
            ];
          case StatusForExecutorEnum.acceptedForExecution:
            return [
              {
                icon: "XCircle",
                color: palette.error.main,
                translation_key: "abort.execution",
                onClick: handleAbortExecution,
              },
            ];
          default:
            return [];
        }
      }
    } else if (type === TypeOfTheTaskEnum.resolutionForTheDocument) {
      if (isExecutor) {
        switch (status) {
          case StatusForExecutorEnum.newTask:
            return [
              {
                icon: "XCircle",
                color: palette.primary.main,
                translation_key: "complete.execution",
                onClick: handleCompleteExecution,
              },
            ];
        }
      }
    }

    return [];
  }, [
    type,
    isAuthor,
    isExecutor,
    status,
    palette.primary.main,
    palette.error.main,
    palette.success.main,
    handleConfirmSending,
    handleSendForRevision,
    handleCreateTask,
    handleReject,
    handleAbortExecution,
    handleCompleteExecution,
    registerSBMRequest,
    handleConfirmExecution,
  ]);

  return (
    <>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h6_semiBold" color="text.secondary">
          {t(`types.${type}`)}
        </Typography>

        <Stack flexDirection="row" alignItems="center">
          {renderActionButton()}

          {MenuOptions.length > 0 ? (
            <IconButton size="large" disableRipple>
              <Icon
                name="MoreVertical"
                color={palette.secondary.main}
                onClick={handleOpen}
              />
            </IconButton>
          ) : null}
        </Stack>

        {MenuOptions.length > 0 ? (
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              style: {
                minWidth: "20ch",
                borderRadius: "16px",
              },
            }}
          >
            {MenuOptions.map((option) => {
              return (
                <MenuItem
                  key={option.translation_key}
                  onClick={option.onClick}
                  sx={{ px: 4, py: 3 }}
                  disabled={option.disabled}
                >
                  <ListItemIcon>
                    <Icon
                      name={option.icon as TIconNames}
                      color={option.color}
                    />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ml: 5 }}>
                    {t(option.translation_key)}
                  </Typography>
                </MenuItem>
              );
            })}
          </Menu>
        ) : null}
      </Stack>
      <CreateTaskContainer initialData={data} />
    </>
  );
};
