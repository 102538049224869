import React from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "ui-kit";

import { Button } from "@mui/material";
import { ModalVariants } from "@types";

import { useAppSelector } from "../../../../hooks";

export const ConfirmStructuralUnitDisablePopup = (props: {
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
}) => {
  const { open, onClose, onConfirm } = props;

  const { t } = useTranslation();

  const { modal } = useAppSelector((state) => state.modals);

  const modalType = modal?.variant;

  const body = React.useMemo(() => {
    switch (modalType) {
      case ModalVariants.confirmDisableStructuralUnit:
        return t("structural.unit.deactivate.description");
      case ModalVariants.confirmDisableStaffMember:
        return t("staff.member.deactivate.description");
      case ModalVariants.confirmDisableStaffUnit:
        return t("staff.unit.deactivate.description");
    }
  }, [modalType, t]);

  const content = {
    title: t("confirm.action"),
    body,
    secondaryButton: (
      <Button color="secondary" autoFocus onClick={onClose}>
        {t("cancel")}
      </Button>
    ),
    mainButton: (
      <Button
        variant="contained"
        color="secondary"
        autoFocus
        onClick={onConfirm}
      >
        {t("confirm")}
      </Button>
    ),
  };

  return <Modal isOpen={open} onClose={onClose} content={content} />;
};
