import styled from "styled-components";
import { FLEX_ROW_FULL } from "ui-kit";

export const Wrapper = styled.div<{ $hide: boolean }>`
  opacity: ${({ $hide }) => ($hide ? 0 : 1)};

  .inbox-link {
    font-weight: 600;
    color: ${({ theme }) => theme.palette.secondary.light};

    text-decoration: underline;
    cursor: pointer;

    pointer-events: all;
  }
`;

export const InboxWrapper = styled.div`
  position: fixed;
  bottom: ${({ theme }) => theme.spacing(18)};
  right: ${({ theme }) => theme.spacing(6)};

  padding-left: ${({ theme }) => theme.spacing()};

  z-index: 99999;
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  ${FLEX_ROW_FULL};

  background-color: ${({ theme }) => theme.palette.primary.main};
  color: white;

  padding: ${({ theme }) => theme.spacing(4)};

  border-radius: 50%;
`;
