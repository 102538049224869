import toast from "react-hot-toast";

import {
  addApprover,
  confirmApprovalRoute,
  fetchSingleApprovals,
  contractStatusChangeAction,
  getApprovalRoutesSelectForContract,
  getConfirmedApprovalRoutes,
  startApprovalProcess,
  fetchCurrentUserApprovals,
  returningRejectedAgreementAction,
  restartRejectedAgreementAction,
  getContractsList,
  getContractsTypicalApprovers,
  sendContractToDo,
  editContractsFile,
  deleteContractsFile,
  getContractHistory,
  deleteContract,
  assignContractResponsiblePerson,
  updateContractStatus,
  generateContractRegistrationNumber,
  deleteGeneratedRegistrationNumber,
  checkRegistrationNumber,
  updateNumberAndDate,
  addFileSplitting,
  changeContractResponsiblePerson,
} from "api";
import { openDrawerWithData, useAppDispatch } from "app";
import { AxiosError } from "axios";

import {
  getAPIErrorMessage,
  getFiltersQueryCacheKey,
  SnackbarMessages,
} from "@sbm/fe-utils";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  IApprovalRoute,
  IConfirmRoute,
  IAddApproverForm,
  IContractStatusChanging,
  IApprovalForHomepage,
  IContractEntity,
  IMeta,
  IContractType,
  IContractSubtype,
  TypeOfObjectInternalApprovalEnum,
  IContractFileEditing,
  IContractHistory,
  IResponsiblePersonForm,
  IUpdateStatus,
  IGenerateRegistrationNumber,
  DrawerWithDataVariantsEnum,
  IAgreementComments,
  IAttachmentForm,
  ISAAttachmentForm,
} from "@types";

export const useGetApprovalRoutesSelectForContract = ({
  id,
  type,
}: {
  id: number | undefined;
  type: TypeOfObjectInternalApprovalEnum;
}) => {
  return useQuery<IApprovalRoute[] | undefined>(
    ["get_select_for", type, id],
    async () => await getApprovalRoutesSelectForContract(id, type),
    { retry: false }
  );
};
export const useGetConfirmedApprovalRoutes = ({
  id,
  type,
}: {
  id: number | undefined;
  type: TypeOfObjectInternalApprovalEnum;
}) => {
  return useQuery<IApprovalRoute>(
    ["get_confirmed_approval_routes", id, type],
    async () => await getConfirmedApprovalRoutes(id, type),
    { retry: false }
  );
};

export const useGetContractHistory = (id: number | undefined) => {
  return useQuery<IContractHistory[]>(
    ["get_contract_history", id],
    async () => await getContractHistory(id),
    { retry: false }
  );
};

export const useConfirmApprovalRoute = (
  onSuccess: () => void,
  onError: (error: unknown) => void
) => {
  return useMutation(
    async (data: IConfirmRoute) => {
      return await confirmApprovalRoute(data);
    },
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: (error) => {
        onError(error);
      },
    }
  );
};

export const useLaunchApprovalRoute = (
  onSuccess: () => void,
  onError: (error: unknown) => void
) => {
  return useMutation(
    async (data: {
      objectInternalApprovalProcessId: number | null | undefined;
    }) => {
      return await startApprovalProcess(data);
    },
    {
      onMutate: async () => {
        // if needed set loading or do something in process of the request
      },
      onSuccess: () => {
        onSuccess();
      },
      onError: (error) => {
        onError(error);
      },
    }
  );
};

export const useAppApproverMutation = (onSuccess: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: IAddApproverForm) => {
      await addApprover(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_confirmed_approval_routes"]);
        toast.success(SnackbarMessages.success);
        onSuccess();
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useGetSingleApprovals = (approvalTab: boolean) => {
  return useInfiniteQuery<{ items: IApprovalForHomepage[]; meta: IMeta }>(
    ["get_single_approvals", approvalTab],
    (params) => fetchSingleApprovals(params, approvalTab),
    {
      getPreviousPageParam: (params) => {
        const prevPage = params.meta.currentPage - 1;
        return prevPage <= 0 ? 0 : prevPage;
      },
      getNextPageParam: (params) => {
        if (!params?.meta) return 1;

        if (params.meta.currentPage < params.meta.totalPages) {
          return params.meta.currentPage + 1;
        }
      },
    }
  );
};

export const useChangeContractStatusMutation = (onSuccess: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: IContractStatusChanging) => {
      await contractStatusChangeAction(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_confirmed_approval_routes"]);
        await queryClient.invalidateQueries(["get_user_approvals"]);
        toast.success(SnackbarMessages.success);
        onSuccess();
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useReturningRejectedAgreementMutation = (
  onSuccess: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: IContractStatusChanging) => {
      await returningRejectedAgreementAction(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_confirmed_approval_routes"]);
        await queryClient.invalidateQueries(["get_user_approvals"]);
        toast.success(SnackbarMessages.success);
        onSuccess();
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useRestartRejectedAgreementMutation = (onSuccess: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (body: IContractStatusChanging) => {
      await restartRejectedAgreementAction(body);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["get_confirmed_approval_routes"]);
        await queryClient.invalidateQueries(["get_user_approvals"]);
        toast.success(SnackbarMessages.success);
        onSuccess();
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useGetCurrentUserApprovals = (
  objectInternalApprovalProcessId: number
) => {
  return useQuery<IAgreementComments>(
    ["get_user_approvals", objectInternalApprovalProcessId],
    async () =>
      await fetchCurrentUserApprovals(objectInternalApprovalProcessId),
    {
      staleTime: 0,
      cacheTime: 0,
      retry: false,
    }
  );
};

export const useGetContractsList = (
  filter: (string | undefined)[] = [],
  sortBy = "",
  search = "",
  options?: Record<string, unknown>
) => {
  const queryKeyFilter = getFiltersQueryCacheKey(filter);

  return useInfiniteQuery<{
    items: IContractEntity[];
    meta: IMeta;
  }>(
    ["get_contracts_list", search, ...queryKeyFilter, sortBy],
    (params) => getContractsList(params, filter, sortBy, search),
    {
      ...options,
      cacheTime: 0,
      staleTime: 0,
      retry: false,
      getPreviousPageParam: (params) => {
        const prevPage = params.meta.currentPage - 1;
        return prevPage <= 0 ? 0 : prevPage;
      },
      getNextPageParam: (params) => {
        if (!params?.meta) return 1;

        if (params.meta.currentPage < params.meta.totalPages) {
          return params.meta.currentPage + 1;
        }
      },
    }
  );
};

export const useGetContractsTypicalApprovers = (sortBy = "", search = "") => {
  return useInfiniteQuery<{
    items: {
      id: number;
      organization: {
        id: number;
        fullTextOrganizationName: string;
      };
      routeNumber: string;
      contractTypes: IContractType[];
      contractSubtypes: IContractSubtype[];
      dateCreationRoute: string;
      usageScorer: number;
      additionalInformation: string;
    }[];
    meta: IMeta;
  }>(
    ["get_contracts_typical_approvers", search, sortBy],
    (params) => getContractsTypicalApprovers(params, sortBy, search),
    {
      cacheTime: 0,
      staleTime: 0,
      retry: false,
      getPreviousPageParam: (params) => {
        const prevPage = params.meta.currentPage - 1;
        return prevPage <= 0 ? 0 : prevPage;
      },
      getNextPageParam: (params) => {
        if (!params?.meta) return 1;

        if (params.meta.currentPage < params.meta.totalPages) {
          return params.meta.currentPage + 1;
        }
      },
    }
  );
};
export const useSendContractToDoMutation = (
  onSuccess: () => void,
  onError: () => void
) => {
  return useMutation(
    async (id: number) => {
      await sendContractToDo(id);
    },
    {
      onSuccess: async () => {
        onSuccess();
        toast.success(SnackbarMessages.success);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        onError();
        toast.error(errorMsg);
      },
    }
  );
};

export const useEditContractsFileMutation = (onSuccess: () => void) => {
  return useMutation(
    async ({ id, body }: { id: number; body: IContractFileEditing }) => {
      await editContractsFile(id, body);
    },
    {
      onSuccess: async () => {
        onSuccess();
        toast.success(SnackbarMessages.success);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useDeleteContractsFileMutation = (onSuccess: () => void) => {
  return useMutation(
    async (id: number) => {
      await deleteContractsFile(id);
    },
    {
      onSuccess: async () => {
        onSuccess();
        toast.success(SnackbarMessages.success);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useDeleteContractMutation = (onSuccess: () => void) => {
  return useMutation(
    async (id: number) => {
      await deleteContract(id);
    },
    {
      onSuccess: async () => {
        onSuccess();
        toast.success(SnackbarMessages.success);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useAssignContractResponsiblePerson = (
  onSuccess: () => void,
  onError: () => void
) => {
  return useMutation(
    async ({ id, body }: { id: string; body: IResponsiblePersonForm }) => {
      await assignContractResponsiblePerson(id, body);
    },
    {
      onSuccess: async () => {
        onSuccess();
        toast.success(SnackbarMessages.success);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
        onError();
      },
    }
  );
};

export const useChangeContractResponsiblePerson = (
  onSuccess: () => void,
  onError: () => void
) => {
  return useMutation(
    async ({ id, body }: { id: string; body: IResponsiblePersonForm }) => {
      await changeContractResponsiblePerson(id, body);
    },
    {
      onSuccess: async () => {
        onSuccess();
        toast.success(SnackbarMessages.success);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
        onError();
      },
    }
  );
};

export const useEditContractStatus = (onSuccess: () => void) => {
  return useMutation(
    async ({ id, body }: { id: number; body: IUpdateStatus }) => {
      await updateContractStatus(id, body);
    },
    {
      onSuccess: async () => {
        onSuccess();
        toast.success(SnackbarMessages.success);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useGenerateRegistrationNumber = (onSuccess?: () => void) => {
  const dispatch = useAppDispatch();
  return useMutation(
    async ({ body }: { body: IGenerateRegistrationNumber }) =>
      await generateContractRegistrationNumber(body),
    {
      onSuccess: async (data) => {
        dispatch(
          openDrawerWithData({
            variant: DrawerWithDataVariantsEnum.assignRegistrationNumber,
            content: data,
          })
        );
        toast.success(SnackbarMessages.success);
        onSuccess && onSuccess();
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useDeleteRegistrationNumber = () => {
  return useMutation(
    async (id: number) => {
      await deleteGeneratedRegistrationNumber(id);
    },
    {
      onSuccess: async () => {
        toast.success(SnackbarMessages.success);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useCheckRegistrationNumber = (body: {
  numberContract: string | number;
}) => {
  return useQuery(
    ["get_registration_codes"],
    async () => await checkRegistrationNumber(body),
    {
      retry: true,
    }
  );
};

export const useUpdateNumberAndDate = (onSuccess: () => void) => {
  return useMutation(
    async ({
      id,
      body,
    }: {
      id: number;
      body: {
        numberContract: string;
        dateContract: Date;
      };
    }) => {
      await updateNumberAndDate(id, body);
    },
    {
      onSuccess: async () => {
        toast.success(SnackbarMessages.success);
        onSuccess();
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};

export const useAddFileSplitting = (onSuccess: (id: number) => void) => {
  return useMutation(
    async (attachment: IAttachmentForm | ISAAttachmentForm) => {
      return await addFileSplitting(attachment);
    },
    {
      onSuccess: async (data) => {
        onSuccess(data.id);
        toast.success(SnackbarMessages.success);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
      },
    }
  );
};
