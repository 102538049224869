import styled from "styled-components";
import { FLEX_BETWEEN, FLEX_ROW_ALIGN_CENTER } from "ui-kit";

export const ContractEventWrapper = styled.div`
  ${FLEX_BETWEEN};
  flex-direction: row;
  border-bottom: 1px solid ${({ theme }) => theme.palette.other.divider};
  padding: ${({ theme }) => theme.spacing(2)};
`;

export const TitleWrapper = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  flex-direction: row;
  border-bottom: 1px solid ${({ theme }) => theme.palette.other.divider};
  height: 48px;
  gap: ${({ theme }) => theme.spacing(2)};
`;
