import React from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "app";
import { Icon } from "ui-kit";

import { Tooltip, Typography, useTheme } from "@mui/material";
import Popover from "@mui/material/Popover";
import { getLocaleDateFormat, getPersonalDataName } from "@sbm/fe-utils";
import { MessageViewResponse } from "@types";

import { InfoContent, InfoItem, Wrapper } from "./styles";

export interface Props {
  author: string | undefined;
  messageData: MessageViewResponse;
  anchorEl: any;
  onPopoverOpen: (event: React.MouseEvent<HTMLElement>) => void;
  onPopoverClose?: () => void;
}

export const MessagePopover: React.FC<Props> = ({
  author,
  messageData,
  anchorEl,
  onPopoverOpen,
  onPopoverClose,
}) => {
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const theme = useTheme();

  const {
    messageRecipients,
    messageId,
    id,
    topicOfTheMessage,
    dateOfTheMessage,
    dateOfViewingOfTheMessage,
  } = messageData;
  const { userProfile } = useAppSelector((state) => state.auth);

  const getViewingDate = () => {
    const me = messageData.messageRecipients.filter(
      (r) => r.userId === userProfile?.id
    );
    if (me.length) {
      return me[0].dateOfViewingOfTheMessage;
    }
  };

  const sendingUsersNamePreview = messageRecipients
    .map((item) => getPersonalDataName(item.personalData) || "unknown user")
    .join(", ");

  return (
    <Wrapper>
      <Typography variant="body3" color={theme.palette.primary.main}>
        {`${t("messages.to")}: `}{" "}
      </Typography>

      <Typography
        variant="body3"
        sx={{ pl: 1 }}
        className="ellipsis"
        color={theme.palette.primary.main}
      >
        {sendingUsersNamePreview}
      </Typography>
      <div
        onClick={onPopoverOpen}
        style={{
          transform: open ? "rotate(180deg)" : "rotate(0deg)",
          transition: "transform 0.3s ease",
          cursor: "pointer",
        }}
      >
        <Icon name="ChevronDown" color={theme.palette.primary.main} size={16} />
      </div>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onPopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            width: "300px",
            padding: "10px",
          },
        }}
      >
        <InfoContent>
          <InfoItem>
            <Typography color="grey" variant="body4">
              {t("messages.number")}
            </Typography>
            <Typography color="grey" variant="body4" fontWeight={600}>
              {messageId || id}
            </Typography>
          </InfoItem>
          <InfoItem>
            <Typography color="grey" variant="body4">
              {t("messages.topic")}:
            </Typography>
            <Tooltip title={topicOfTheMessage} disableInteractive arrow>
              <Typography
                color="grey"
                variant="body4"
                fontWeight={600}
                className="ellipsis"
              >
                {topicOfTheMessage}
              </Typography>
            </Tooltip>
          </InfoItem>
          <InfoItem>
            <Typography color="grey" variant="body4">
              {t("messages.from")}:
            </Typography>
            <Typography color="grey" variant="body4" fontWeight={600}>
              {author}
            </Typography>
          </InfoItem>
          <InfoItem>
            <Typography color="grey" variant="body4">
              {t("messages.to")}:
            </Typography>
            <div className="message__to">
              {messageRecipients.map((user, index) => (
                <div key={index} className="sending__user">
                  <div>
                    {user.messageViewed ? (
                      <Tooltip
                        title={t("messages.viewed")}
                        placement="top"
                        arrow
                        disableInteractive
                      >
                        <div>
                          <Icon
                            name="Check"
                            color={theme.palette.success.main}
                            size={12}
                          />
                        </div>
                      </Tooltip>
                    ) : null}
                  </div>
                  <Typography
                    color={user.messageViewed ? "black" : "grey"}
                    variant="body4"
                    fontWeight={600}
                  >
                    {getPersonalDataName(user.personalData)}
                  </Typography>
                </div>
              ))}
            </div>
          </InfoItem>
          <InfoItem>
            <Typography color="grey" variant="body4">
              {t("messages.sending.date")}:
            </Typography>
            <Typography color="grey" variant="body4" fontWeight={600}>
              {getLocaleDateFormat(dateOfTheMessage)}
            </Typography>
          </InfoItem>
          {getViewingDate() ? (
            <InfoItem>
              <Typography color="grey" variant="body4">
                {t("messages.viewing.date")}:
              </Typography>
              <Typography color="grey" variant="body4" fontWeight={600}>
                {getLocaleDateFormat(getViewingDate())}
              </Typography>
            </InfoItem>
          ) : null}
        </InfoContent>
      </Popover>
    </Wrapper>
  );
};
