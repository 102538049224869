import React, { MouseEvent } from "react";

import { fetchLinkToFile } from "api";
import { axiosService } from "app";
import { FileFormatImage, Icon } from "ui-kit";

import { Tooltip, Typography, useTheme } from "@mui/material";
import {
  downloadFile,
  MS_OFFICE_MIME_TYPES,
  PREVIEW_MIME_TYPES,
} from "@sbm/fe-utils";
import { MimeTypesEnum } from "@types";

import { AttachedFile } from "./styles";

interface MessageAttachedFileProps {
  fileName: string;
  fileNameForSystem: string;
  fileFormat: MimeTypesEnum;
  convertedPdfFileName?: string;
}

export const MessageAttachedFile: React.FC<MessageAttachedFileProps> = ({
  fileFormat,
  fileName,
  fileNameForSystem,
  convertedPdfFileName,
}) => {
  const theme = useTheme();

  const handlePreviewFile = async () => {
    const isPreviewAvailable = PREVIEW_MIME_TYPES.includes(fileFormat);
    const isMSOfficeFile = MS_OFFICE_MIME_TYPES.includes(fileFormat);

    let linkToFile = "";

    if (isMSOfficeFile && convertedPdfFileName) {
      linkToFile = await fetchLinkToFile(convertedPdfFileName);
    } else if (isPreviewAvailable) {
      linkToFile = await fetchLinkToFile(fileNameForSystem);
    }

    if (linkToFile) {
      window.open(linkToFile, "_blank");
    }
  };

  const handleDownloadFile = async (e?: MouseEvent<SVGSVGElement>) => {
    e?.stopPropagation();

    const { data: linkToFile } = await axiosService({
      endpoint: `contract-attached-files/get-link`,
      body: { filename: fileNameForSystem },
    });

    downloadFile(linkToFile, fileName);
  };

  return (
    <AttachedFile onClick={handlePreviewFile}>
      <div className="attached_file_item">
        <FileFormatImage format={fileFormat} size={20} />

        <Tooltip arrow placement="top" disableInteractive title={fileName}>
          <Typography className="ellipsis" variant="body2" fontWeight={600}>
            {fileName}
          </Typography>
        </Tooltip>
      </div>

      <Icon
        name="Download"
        className="cursor-pointer download-icon"
        size={20}
        color={theme.palette.primary.main}
        onClick={handleDownloadFile}
      />
    </AttachedFile>
  );
};
