import React from "react";

import { addDays } from "date-fns";
import en from "date-fns/locale/en-US";
import ru from "date-fns/locale/ru";
import { DefaultTFuncReturn } from "i18next";

import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ruRU, enUS } from "@mui/x-date-pickers/locales";
import { getStorageItem, STORAGE_KEYS } from "@sbm/fe-utils";

export interface DatePickerProps extends MuiDatePickerProps<any> {
  onDateChange?: (arg: Date) => void;
  date?: Date;
  size?: "small" | "medium";
  disabledCurrentDay?: boolean;
  variant?: "standard" | "filled" | "outlined";
  color?: "error" | "info" | "primary" | "secondary" | "success" | "warning";
  label?: DefaultTFuncReturn;
  required?: boolean;
  disable?: boolean;
}

const locale = getStorageItem(STORAGE_KEYS.lang);
const isEnglish = locale === "en";

const dateFormat = `dd/${isEnglish ? "MMM" : "MM"}/yyyy`;

export const DatePicker = (props: DatePickerProps) => {
  const {
    onDateChange,
    date,
    size = "small",
    disabledCurrentDay = false,
    label = "",
    color = "secondary",
    variant = "standard",
    readOnly,
    required = false,
    disable,
  } = props;

  const [value, setValue] = React.useState(date);

  const handleChange = (dateObject: Date) => {
    onDateChange?.(dateObject);
    setValue(dateObject);
  };

  const minDate = addDays(new Date(), 1);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={isEnglish ? en : ru}
      localeText={
        isEnglish
          ? enUS.components.MuiLocalizationProvider.defaultProps.localeText
          : ruRU.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      <MuiDatePicker
        value={value}
        onChange={handleChange}
        format={dateFormat}
        readOnly={readOnly}
        disabled={disable}
        disableOpenPicker={disable}
        minDate={disabledCurrentDay ? minDate : null}
        sx={{ opacity: disable ? 0.5 : 1 }}
        slotProps={{
          textField: {
            required,
            size,
            fullWidth: true,
            variant,
            color,
          },
        }}
        label={label}
        {...props}
      />
    </LocalizationProvider>
  );
};
