import React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useGetContractHistory } from "api";
import { DotsLoader, Icon, TrackerTreeItemIcon } from "ui-kit";

import { Stack, Typography, useTheme } from "@mui/material";
import { getAPIErrorMessage, getLocaleDateFormat } from "@sbm/fe-utils";

import { CorrespondenceEventWrapper, TitleWrapper } from "./styles";

const parser = new DOMParser();

export const ContractHistoryContainer = ({
  id,
  date,
  contractNumber,
}: {
  id: number | undefined;
  date: Date | undefined;
  contractNumber: string | undefined;
}) => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  const { data, isLoading, error } = useGetContractHistory(id);

  if (isLoading) return <DotsLoader />;

  if (error) {
    const errorMSG = getAPIErrorMessage(error);
    if (errorMSG) {
      toast.error(errorMSG);
    }
  }

  if (!data) return null;

  return (
    <>
      <TitleWrapper>
        <Typography variant="h8_bold" lineHeight={2} color="text.primary">
          {t("sequence.number")}
        </Typography>
        <Typography variant="h8_bold" lineHeight={2} color="text.primary">
          {contractNumber || "_"}
        </Typography>
        <Typography variant="h8_bold" lineHeight={2} color="text.primary">
          {`${t("From")} ${date ? getLocaleDateFormat(date) : "-/-/-"}`}
        </Typography>

        <Icon name="FileSignature" color={palette.secondary.main} size={20} />
      </TitleWrapper>

      {data?.map((item, index) => {
        const parsedHtmlDoc = parser.parseFromString(
          item.generatedText,
          "text/html"
        );
        const htmlDoc = parsedHtmlDoc.getElementsByTagName("body");
        const labelBody = htmlDoc?.[0].innerHTML.replaceAll(
          "classname",
          "class"
        );

        return (
          <CorrespondenceEventWrapper key={index}>
            <Stack display="flex" flexDirection="row" alignItems="center">
              <TrackerTreeItemIcon typeEventId={item.typeEvent.id} />
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{ __html: labelBody }}
              />
            </Stack>
            <Stack display="flex" flexDirection="row" alignItems="center">
              <Typography variant="body3" color="text.secondary" minWidth={110}>
                {getLocaleDateFormat(item.createdAt, "hh:mm")}
              </Typography>
            </Stack>
          </CorrespondenceEventWrapper>
        );
      })}
    </>
  );
};
