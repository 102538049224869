import { CorrespondenceTypeEnum } from "@types";

import incoming_card_img from "../../../../../assets/backgrounds/correspondence_card_img.svg";
import outgoing_card_img from "../../../../../assets/backgrounds/outgoing_card_img.png";
import service_note_card_img_en from "../../../../../assets/backgrounds/service_node_card_img_en.png";
import service_note_card_img_ru from "../../../../../assets/backgrounds/service_node_card_img_ru.png";

export const cardImage = (type: CorrespondenceTypeEnum, isEnglish: boolean) => {
  switch (type) {
    case CorrespondenceTypeEnum.incomingDocument:
      return incoming_card_img;
    case CorrespondenceTypeEnum.outgoingDocument:
      return outgoing_card_img;
    case CorrespondenceTypeEnum.serviceNote:
      if (isEnglish) {
        return service_note_card_img_en;
      }
      return service_note_card_img_ru;
  }
};
