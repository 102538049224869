import React from "react";

import { MimeTypesEnum } from "@types";

import bmpImage from "../../../assets/images/icons/bmp.svg";
import docImage from "../../../assets/images/icons/doc.svg";
import docxImage from "../../../assets/images/icons/docx.svg";
import dwgImage from "../../../assets/images/icons/dwg.svg";
import jpegImage from "../../../assets/images/icons/jpeg.svg";
import jpgImage from "../../../assets/images/icons/jpg.svg";
import pdfImage from "../../../assets/images/icons/pdf.svg";
import pngImage from "../../../assets/images/icons/png.svg";
import xlsImage from "../../../assets/images/icons/xls.svg";
import xlsxImage from "../../../assets/images/icons/xlsx.svg";

export interface FileFormatImageProps {
  format: MimeTypesEnum;
  size?: number;
}

export const FileFormatImage = (props: FileFormatImageProps) => {
  const { format, size = 38 } = props;

  const Source = React.useMemo(() => {
    switch (format) {
      case MimeTypesEnum.pdf:
        return pdfImage;
      case MimeTypesEnum.doc:
        return docImage;
      case MimeTypesEnum.docx:
        return docxImage;
      case MimeTypesEnum.xlsx:
        return xlsxImage;
      case MimeTypesEnum.xls:
        return xlsImage;
      case MimeTypesEnum.png:
        return pngImage;
      case MimeTypesEnum.jpg:
        return jpgImage;
      case MimeTypesEnum.jpeg:
        return jpegImage;
      case MimeTypesEnum.bmp:
        return bmpImage;
      case MimeTypesEnum.dwg:
      case MimeTypesEnum.octec:
        return dwgImage;
      default:
        return "";
    }
  }, [format]);

  if (!format) return null;

  return (
    <img
      src={Source}
      alt="file format"
      draggable={false}
      width={size}
      height={size}
    />
  );
};
