import React, { useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  useCreateTask,
  useDeleteTaskMutation,
  useGetPotentialExecutors,
  useGetTaskAttachmentsById,
  useGetTaskById,
  useGetTaskLinksById,
} from "api";
import {
  closeDrawer,
  resetTaskCreationStep,
  setChangeTaskCreationStep,
  setModal,
  TransitionPrompt,
  useAppDispatch,
  useAppSelector,
} from "app";
import { Drawer, Icon } from "ui-kit";

import { Stack, useTheme } from "@mui/material";
import { Button } from "@sbm/ui-components";
import {
  ExecutorType,
  IAttachLink,
  ICreateTask,
  IExecutorOption,
  ITaskById,
  ModalVariants,
  TypeOfTheTaskEnum,
} from "@types";

import { getInitialTaskFields } from "./constants";
import { createRequestBody } from "./helpers";
import { SelectOfExecutors } from "./steps";
import { SettingUpTasks } from "./steps/Step2SettingUpTasks";
import { AttachmentAndSending } from "./steps/Step3AttachmentAndSending";
import { TaskStepper } from "./TaskStepper";

interface Props {
  initialData?: ITaskById;
}

export const CreateTaskForm = ({ initialData }: Props) => {
  const { t } = useTranslation("tasks");
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { id: parentTaskId } = useParams();

  const { taskCreationStep } = useAppSelector((state) => state.task);

  const { drawer, drawerType } = useAppSelector((state) => state.global);

  const [showPrompt, setShowPrompt] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState<IExecutorOption[]>([]);

  const form = useForm<ICreateTask>({
    defaultValues: {
      structuralUnitType: ExecutorType.Immediate_subordinates,
      defaultValuesPerUser: getInitialTaskFields(
        initialData as ITaskById | null
      ),
    },
  });

  const organizationId = initialData?.executorOfTheTask?.organization.id;

  const onDeleteSuccess = () => {
    handleCloseDrawer();
  };

  const { mutate: deleteTask, isLoading: isRemoving } =
    useDeleteTaskMutation(onDeleteSuccess);

  const { data: taskData } = useGetTaskById(parentTaskId, {
    enabled: Boolean(parentTaskId),
  });

  const { data: linksData } = useGetTaskLinksById(Number(parentTaskId));
  const { data: filesData } = useGetTaskAttachmentsById(Number(parentTaskId));

  const {
    reset,
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors, dirtyFields, isSubmitting },
  } = form;
  const { structuralUnitType, executorIds } = useWatch({ control });

  useEffect(() => {
    // Adding links and files to the drawer in Step 3
    if (linksData?.items.length) {
      const linksList = getValues("links") || [];
      const transformedLinksData = linksData.items.map((item) => ({
        ...item,
        shortNameOfTheDocument: item.link,
      }));
      const updatedLinksList = linksList.concat(
        transformedLinksData as unknown as IAttachLink[]
      );
      setValue("links", updatedLinksList);
    }

    if (filesData?.items.length) {
      const filesList = getValues("files") || [];
      const transformedFileData = filesData.items.map((item) => ({
        ...item,
        name: item.fileName,
      }));
      const updatedFilesList = filesList.concat(
        transformedFileData as unknown as File[]
      );
      setValue("files", updatedFilesList);
    }
  }, [linksData, filesData, setValue, getValues]);

  const handleCloseDrawer = () => {
    reset();
    dispatch(resetTaskCreationStep());
    dispatch(closeDrawer());
  };

  const handleClose = () => {
    handleCloseDrawer();
  };

  const handleClosePrompt = () => {
    setShowPrompt(false);
  };
  const defaultValues = form.watch("defaultValuesPerUser");

  const handleCancel = () => {
    if (
      Boolean(defaultValues?.textOfTheTask) ||
      getValues("executorIds")?.length > 0
    ) {
      setShowPrompt(true);
      return;
    }
    handleClose();
  };

  const handleDeleteTask = () => {
    dispatch(
      setModal({
        open: true,
        variant: ModalVariants.removeTask,
        content: {
          data: {
            onRemove: () => {
              deleteTask(Number(parentTaskId));
            },
          },
        },
      })
    );
  };

  const handleConfirmTransition = () => {
    reset();
    handleClose();
  };

  const { mutate: createTask, isLoading } = useCreateTask(
    handleConfirmTransition,
    handleConfirmTransition
  );

  const { data = [] } = useGetPotentialExecutors();

  const getExecutorsData = () => {
    if (!organizationId) {
      return data;
    } else
      return data.filter((item) => item.organization.id === organizationId);
  };

  const onSubmit = handleSubmit(async (data: ICreateTask) => {
    const requestBody = createRequestBody(
      data,
      parentTaskId,
      initialData?.executionRequirements
    );
    createTask(requestBody);
  });

  const handleNextStep = () => {
    dispatch(setChangeTaskCreationStep(1));
  };

  const onSave = () => {
    console.log("SAVE");
  };

  const handleBackStep = () => {
    dispatch(setChangeTaskCreationStep(-1));
  };

  const handleSelectedOptions = (options: IExecutorOption[]) => {
    setSelectedOptions(options);
  };

  useEffect(() => {
    setSelectedOptions([]);
    setValue("executorIds", []);
  }, [structuralUnitType, setValue]);

  const isResolutionForTheDocument =
    initialData?.typeOfTheTask === TypeOfTheTaskEnum.resolutionForTheDocument;

  const renderFormContent = () => {
    switch (taskCreationStep) {
      case 0:
        return (
          <SelectOfExecutors
            form={form}
            data={getExecutorsData()}
            selectedOptions={selectedOptions}
            setSelectedOptions={handleSelectedOptions}
            requirements={initialData?.executionRequirements}
            registration={initialData?.registration}
            isResolutionForTheDocument={isResolutionForTheDocument}
          />
        );
      case 1:
        return (
          <SettingUpTasks
            form={form}
            selectedOptions={selectedOptions}
            setSelectedOptions={handleSelectedOptions}
            requirements={initialData?.executionRequirements}
            registration={initialData?.registration}
          />
        );
      case 2:
        return <AttachmentAndSending form={form} />;
      default:
        return null;
    }
  };

  const isNextButtonDisabled = useMemo(() => {
    if (taskCreationStep === 0) {
      return (
        !defaultValues?.textOfTheTask?.trim() ||
        !executorIds ||
        !(executorIds.length > 0)
      );
    }
    if (taskCreationStep === 1) {
      const textArray =
        executorIds?.map((filed) => filed.textOfTheTask?.trim()) || [];
      return textArray.includes("");
    }
    // if (taskCreationStep === 1) {
    //   return !selectedOptions?.length;
    // }
    return false;
  }, [taskCreationStep, executorIds, defaultValues?.textOfTheTask]);

  return (
    <>
      {showPrompt && (
        <TransitionPrompt
          open={showPrompt}
          onClose={handleClosePrompt}
          onConfirm={handleCloseDrawer}
        />
      )}

      <Drawer
        title={
          initialData
            ? isResolutionForTheDocument
              ? t("create.resolution")
              : t("delegate.task")
            : t("create.task")
        }
        open={Boolean(drawer)}
        onClose={handleCancel}
        actions={
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Stack>
              {!taskCreationStep ? null : (
                <Button
                  variant="text"
                  size="medium"
                  color="secondary"
                  sx={{ fontSize: "15px" }}
                  onClick={handleBackStep}
                  startIcon={
                    <Icon
                      name="ArrowLeft"
                      color={theme.palette.secondary.main}
                    />
                  }
                >
                  {t("back")}
                </Button>
              )}
            </Stack>
            <Stack flexDirection="row" justifyContent="flex-end" gap={4}>
              <Button
                variant="outlined"
                onClick={handleCancel}
                color="secondary"
                size="large"
              >
                {t("cancel")}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                disabled={isNextButtonDisabled}
                loading={isLoading}
                onClick={taskCreationStep !== 2 ? handleNextStep : onSubmit}
              >
                {taskCreationStep === 2 ? t("send") : t("next")}
              </Button>
            </Stack>
          </Stack>
        }
      >
        <Stack position="relative">
          <TaskStepper />

          <form noValidate style={{ marginTop: "80px" }}>
            {renderFormContent()}
          </form>
        </Stack>
      </Drawer>
    </>
  );
};
