import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAcceptExecution } from "api";
import { Modal } from "ui-kit";

import { FormControlLabel, Stack, Switch } from "@mui/material";
import { Input, Button } from "@sbm/ui-components";

export const AcceptExecutionPopup = ({
  open,
  onClose,
  data: {
    data: { id },
  },
}: {
  open: boolean;
  onClose: () => void;
  data: { data: { id: number } };
}) => {
  const { t } = useTranslation("tasks");

  const [comment, setComment] = useState<string>("");
  const [switcherValue, setSwitcherValue] = useState<boolean>(false);

  const { mutate: acceptExecution, isLoading } = useAcceptExecution(onClose);

  const handleAcceptExecution = () => {
    acceptExecution({ id, comment });
  };

  const handleChangeSwitch = () => {
    setComment("");
    setSwitcherValue(!switcherValue);
  };

  const getDisableState = useMemo(() => {
    if (!switcherValue) {
      return Boolean(comment.trim().length);
    } else return Boolean(switcherValue && !comment.trim().length);
  }, [switcherValue, comment]);

  const content = {
    title: t("accept.reason"),
    body: (
      <Stack>
        <FormControlLabel
          label={t("leave.comment")}
          control={
            <Switch
              color="secondary"
              onClick={handleChangeSwitch}
              checked={switcherValue}
            />
          }
        />
        {switcherValue ? (
          <Input
            label={t("comment")}
            multiline
            required
            maxLength={1000}
            onChange={(e) => setComment(e.target.value)}
            sx={{ my: 2 }}
          />
        ) : null}
      </Stack>
    ),
    mainButton: (
      <Button
        autoFocus
        variant="contained"
        color="secondary"
        onClick={handleAcceptExecution}
        loading={isLoading}
        disabled={getDisableState}
      >
        {t("confirm")}
      </Button>
    ),
  };

  return <Modal isOpen={open} onClose={onClose} content={content} />;
};
