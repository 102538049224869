import { UI_CONFIG } from "app";
import styled from "styled-components";

import { tabClasses, tabsClasses } from "@mui/material";
import { hexToRgba } from "@sbm/fe-utils";

export const BasicTabsWrapper = styled.div<{ $hovered: boolean }>`
  opacity: ${({ $hovered }) => ($hovered ? 0.1 : 1)};

  .fixed {
    width: 100%;

    position: fixed;
    top: ${UI_CONFIG.header}px;
    margin: ${({ theme }) => theme.spacing(-2, 0)};

    background-color: ${({ theme }) => theme.palette.grey[100]};

    transition: all ease 350ms;

    z-index: ${({ theme }) => theme.zIndex.fab};
  }

  .${tabsClasses.scroller} {
    flex: inherit;
    width: fit-content;
  }

  .${tabsClasses.root} {
    z-index: 99;
  }

  .${tabsClasses.flexContainer} {
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }

  .${tabsClasses.flexContainer} {
    background-color: ${({ theme }) => theme.palette.background.opacity};

    padding: ${({ theme }) => theme.spacing()};
    border-radius: ${({ theme }) => theme.spacing(2)};
  }

  .${tabsClasses.indicator} {
    display: none;
  }

  .${tabClasses.selected} {
    color: ${({ theme }) => theme.palette.secondary.main};
    border-bottom: 2px solid ${({ theme }) => theme.palette.secondary.main} !important;
  }

  .${tabClasses.root} {
    min-height: unset;
    min-width: unset;
    height: 42px;
    font-size: 14px;
    font-weight: 600;
    padding: ${({ theme }) => theme.spacing(2, 4)};
    border-bottom: 2px solid transparent;
    transition: color 0.3s 0.1s, border-bottom 0.3s 0.1s;
  }

  .Mui-disabled {
    color: ${({ theme }) => hexToRgba(theme.palette.text.primary, 0.27)};
  }
`;
