import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useCreateEmployee, useGetEmployeeById } from "api";
import {
  axiosService,
  closeDrawerWithData,
  getEmployees,
  getPersonalData,
  setModal,
  TransitionPrompt,
  updateEmployee,
  useAppDispatch,
  useAppSelector,
} from "app";
import { Drawer } from "ui-kit";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import {
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { getPersonalDataName } from "@sbm/fe-utils";
import {
  Button,
  FormAutocomplete,
  FormRadio,
  FormSelect,
  Input,
} from "@sbm/ui-components";
import { useQueryClient } from "@tanstack/react-query";
import {
  EnumEmployeeType,
  ICreateEmployee,
  ModalVariants,
  TypeEmploymentRelationshipEnum,
} from "@types";

import { getStructuredPayload } from "./helpers";
import { ActionsSection, FormWrapper, TitleWrapper } from "./styles";

interface Props {
  data?: ICreateEmployee & {
    title?: string;
    employeeIdUpdate?: number;
    externalSubstitution?: {
      organization: { fullTextOrganizationName: string };
    };
  };
  isViewMode: boolean;
  isDrawerMode?: boolean;
  isEditModeDrawer?: boolean;
}

export const CreateStaffMemberContainer: React.FC<Props> = ({
  data,
  isViewMode,
  isDrawerMode,
  isEditModeDrawer,
}) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const employeeTypePrevRef = React.useRef(EnumEmployeeType.employee);

  const { drawerWithData } = useAppSelector((state) => state.global);
  const { modal } = useAppSelector((state) => state.modals);

  const [showPrompt, setShowPrompt] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);

  const [inputEmployeeValue, setInputEmployeeValue] = React.useState("");

  const [employeeOptions, setEmployeeOptions] = React.useState<
    { title: string; id: number; briefTextOrganizationName?: string }[]
  >([]);

  const { data: employeeData } = useGetEmployeeById(
    data?.externalSubstitution ? data.employeeId : data?.employeeIdUpdate
  );

  const { mutate: createEmployee, isLoading } = useCreateEmployee();

  const organizationId =
    modal?.content.data?.organizationId ||
    drawerWithData?.content.data.organizationId ||
    "";

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    setValue,
    clearErrors,
  } = useForm<ICreateEmployee>({
    defaultValues: {
      structuralUnitId: data?.structuralUnitId,
      positionId: data?.positionId,
      staffUnitId: data?.staffUnitId,
      employeeType: data?.employeeType || EnumEmployeeType.employee,
      employeeId: data?.employeeId,
      employeeIdUpdate: data?.employeeIdUpdate,
      additionalInformation: data?.externalSubstitution
        ? data?.externalSubstitution?.organization?.fullTextOrganizationName
        : data?.additionalInformation,
      validity: data?.validity || true,
      additionalOptions: false,
      typeEmploymentRelationship:
        employeeData?.typeEmploymentRelationship ||
        TypeEmploymentRelationshipEnum.typeA,
    },
  });

  const {
    positionId,
    employeeId,
    validity,
    staffUnitId,
    employeeType,
    additionalInformation,
    employeeIdUpdate,
    additionalOptions,
  } = useWatch({
    control,
  });

  const structuralUnitOptions = React.useMemo(() => {
    if (!data) return [];

    return [{ value: data.structuralUnitId!, option: data.name! }];
  }, [data]);

  const positionOptions = React.useMemo(() => {
    if (!data) return [];

    return [{ value: data.positionId!, option: data.nameOfPosition! }];
  }, [data]);

  const isRequiredFieldsFilled = Boolean(
    staffUnitId && positionId && employeeId
  );

  const additionTypeOptions = [
    {
      option: t("adding.full.time.employee"),
      value: EnumEmployeeType.employee,
    },
    {
      option: t("adding.external.overlap"),
      value: EnumEmployeeType.substitution,
    },
  ];

  const employmentTypeOptions = React.useMemo(
    () => [
      {
        option: t("type_a"),
        value: TypeEmploymentRelationshipEnum.typeA,
      },
      {
        option: t("type_b"),
        value: TypeEmploymentRelationshipEnum.typeB,
      },
      ...(employeeType !== EnumEmployeeType.substitution
        ? [
            {
              option: t("type_c"),
              value: TypeEmploymentRelationshipEnum.typeC,
            },
          ]
        : []),
    ],
    [employeeType, t]
  );

  const ModalTitle = React.useMemo(() => {
    if (isViewMode && data && data.title) return data.title;

    if (employeeType === EnumEmployeeType.employee)
      return t("adding.staff.member");

    return t("adding.external.overlap");
  }, [isViewMode, data, employeeType, t]);

  const toggleEditMode = () => {
    setIsEditMode((prevState) => !prevState);
  };

  const handleValidityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      data?.employeeType === EnumEmployeeType.employee &&
      employeeData?.hasExternalSubstitutions
    ) {
      dispatch(
        setModal({
          open: true,
          variant: ModalVariants.invalidateEmployee,
          content: data,
        })
      );
    } else {
      register("validity").onChange(event);
    }
  };

  const handleEmployeeInputChange = (val: string, reason: string) => {
    if (val === inputEmployeeValue) return;

    setInputEmployeeValue(val);
    // Delete action
    if (reason === "clear") {
      clearErrors("employeeId");
      setValue("additionalInformation", "");
      return setValue("employeeId", undefined);
    }
    // Action is select from the list
    if (reason === "reset") {
      const option = employeeOptions.find((i) => i.title === val);
      if (!option) return;

      if (employeeType === EnumEmployeeType.substitution) {
        setValue("additionalInformation", option.briefTextOrganizationName);
      }
      return setValue("employeeId", option.id);
    }
  };

  const getEmployeeDefaultValue = React.useCallback(
    (id?: number) => {
      if (!id || !employeeOptions.length) return;

      const employee = employeeOptions.find((i) => i.id === id);
      if (!employee) return;

      return employee.title;
    },
    [employeeOptions]
  );

  const fetchEmployees = React.useCallback(
    async (search: string) => {
      const isEmployee = employeeType === EnumEmployeeType.employee;

      const getDataCallback = isEmployee ? getPersonalData : getEmployees;

      // if selected full-time we should use getPersonalData api, if external  - getEmployees
      const { items } = await dispatch(getDataCallback(search));

      const options = items
        .map((i: any) => ({
          title: getPersonalDataName(isEmployee ? i : i.personalData),
          id: i.id,
          briefTextOrganizationName:
            i.staffUnit?.organization?.briefTextOrganizationName,
        }))
        .filter((i: { title: string | undefined }) => Boolean(i.title));

      // We have pagination limit 200 for getting employee list, in case the selected employee is not included adding it manually
      if (employeeId && isViewMode && isEmployee) {
        const exists = options.findIndex(
          (i: typeof items) => i.id === employeeId
        );

        if (exists === -1) {
          try {
            const resp = await axiosService({
              endpoint: `personal-data/${employeeId}`,
            });

            const optionToAdd = {
              title: getPersonalDataName(resp.data),
              id: resp.data.id,
            };

            options.push(optionToAdd);
          } catch (e) {
            console.log(e, "eee");
          }
        }
      }

      return options;
    },
    [dispatch, employeeType, employeeId, isViewMode]
  );

  const handleUpdateEmployee = async (form: Partial<ICreateEmployee>) => {
    if (!employeeId || !organizationId) return;

    await dispatch(
      updateEmployee(form, String(employeeIdUpdate), organizationId)
    );

    await queryClient.invalidateQueries(["get_staff_allocations"]);

    setLoading(false);
    dispatch(setModal(null));
    dispatch(closeDrawerWithData());
  };

  const handleClose = () => {
    if (Object.keys(dirtyFields).length > 0 || inputEmployeeValue) {
      setShowPrompt(true);
      return;
    }
    dispatch(closeDrawerWithData());
  };

  const handleConfirmTransition = () => {
    dispatch(closeDrawerWithData());
    handleClosePrompt();
  };

  const handleClosePrompt = () => {
    setShowPrompt(false);
  };

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);

    if (!additionalOptions) {
      delete data["typeEmploymentRelationship"];
    }

    delete data["additionalOptions"];
    const dataToSend = getStructuredPayload(data, isViewMode);

    if (isEditMode || isEditModeDrawer) {
      const editedData = {
        additionalInformation: dataToSend.additionalInformation,
        validity: dataToSend.validity,
        employeeType: dataToSend.employeeType,
        typeEmploymentRelationship: dataToSend.typeEmploymentRelationship,
      };

      if (data.validity === false) {
        return dispatch(
          setModal({
            open: true,
            variant: ModalVariants.confirmDisableStaffMember,
            content: {
              confirmNavigation: () => handleUpdateEmployee(editedData),
              close: () => setLoading(false),
            },
          })
        );
      }
      await handleUpdateEmployee(editedData);
    } else {
      createEmployee(dataToSend, organizationId);
    }

    setLoading(false);
    dispatch(setModal(null));
    dispatch(closeDrawerWithData());
  });

  React.useEffect(() => {
    const type = data?.externalSubstitution
      ? employeeData?.externalSubstitutions?.find(
          (item) => item.id === data.employeeIdUpdate
        )?.typeEmploymentRelationship
      : employeeData?.typeEmploymentRelationship;

    if (type) {
      setValue("typeEmploymentRelationship", type);
    }
  }, [
    data?.externalSubstitution,
    data?.employeeIdUpdate,
    employeeData?.externalSubstitutions,
    employeeData?.typeEmploymentRelationship,
    setValue,
  ]);

  React.useEffect(() => {
    if (employeeType && !isViewMode) {
      setValue("additionalInformation", "");
      setValue("employeeId", undefined);
    }
  }, [employeeType, setValue, isViewMode]);

  React.useEffect(() => {
    if (employeeType && employeeType !== employeeTypePrevRef.current) {
      employeeTypePrevRef.current = employeeType;
    }
  }, [employeeType]);

  if (isDrawerMode) {
    return (
      <>
        {showPrompt && (
          <TransitionPrompt
            open={showPrompt}
            onClose={handleClosePrompt}
            onConfirm={handleConfirmTransition}
          />
        )}
        <Drawer
          anchor="right"
          open={Boolean(data)}
          onClose={handleClose}
          resizable={false}
          title={t(isEditModeDrawer ? "edit_employee" : "add_employee")}
          actions={
            <Stack display="flex" flexDirection="row" justifyContent="flex-end">
              <Stack flexDirection="row" gap={4}>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  onClick={handleClose}
                >
                  {t("cancel")}
                </Button>

                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  loading={loading || isLoading}
                  disabled={!isRequiredFieldsFilled}
                  onClick={onSubmit}
                >
                  {t("save")}
                </Button>
              </Stack>
            </Stack>
          }
        >
          <FormWrapper noValidate isDrawerMode>
            <Stack width="100%" flexDirection="column" gap={4}>
              <FormSelect
                readOnly
                label={t("structural.unit")}
                name="structuralUnitId"
                control={control}
                values={structuralUnitOptions}
              />
              <FormSelect
                readOnly
                label={t("position")}
                name="positionId"
                control={control}
                values={positionOptions}
              />
              <FormRadio
                disabled={isViewMode}
                name="employeeType"
                control={control}
                values={additionTypeOptions}
              />
              <FormAutocomplete
                required
                disabled={!employeeType}
                readOnly={isViewMode}
                inputValue={inputEmployeeValue}
                freeSolo={false}
                label={t("employee.fullname")}
                fetchData={fetchEmployees}
                defaultSelected={getEmployeeDefaultValue(employeeId)}
                onFetchDataSuccess={setEmployeeOptions}
                onInputChange={handleEmployeeInputChange}
                error={!!errors.employeeId}
              />
              <Input
                shrink
                value={additionalInformation}
                readOnly={
                  employeeType === EnumEmployeeType.substitution &&
                  isEditModeDrawer
                }
                maxLength={300}
                label={
                  employeeType === EnumEmployeeType.employee
                    ? t("additional.information")
                    : t("name.of.organization")
                }
                {...register("additionalInformation")}
              />
              {isEditModeDrawer && (
                <FormControlLabel
                  label={
                    employeeType === EnumEmployeeType.substitution
                      ? t("valid.substitution")
                      : t("valid.employee")
                  }
                  control={
                    <Switch
                      color="secondary"
                      defaultChecked={validity}
                      {...register("validity")}
                      onChange={handleValidityChange}
                    />
                  }
                />
              )}
              <FormControlLabel
                label={t("additional_options")}
                control={
                  <Switch
                    color="secondary"
                    defaultChecked={additionalOptions}
                    {...register("additionalOptions")}
                  />
                }
              />
              {additionalOptions && (
                <Stack gap={2}>
                  <Typography variant="body1" fontWeight="bold">
                    {t("employment_type")}
                  </Typography>
                  <FormRadio
                    name="typeEmploymentRelationship"
                    control={control}
                    values={employmentTypeOptions}
                  />
                </Stack>
              )}
            </Stack>
          </FormWrapper>
        </Drawer>
      </>
    );
  }

  return (
    <FormWrapper noValidate onSubmit={onSubmit}>
      <TitleWrapper>
        <Typography variant="h6" fontWeight={500}>
          {ModalTitle}
        </Typography>

        {isViewMode ? (
          <Button
            fullWidth={false}
            variant="contained"
            color="inherit"
            size="small"
            onClick={toggleEditMode}
            startIcon={isEditMode ? <ArrowBackIcon /> : <EditIcon />}
          >
            {isEditMode ? t("back") : t("edit")}
          </Button>
        ) : null}
      </TitleWrapper>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormSelect
            readOnly
            label={t("structural.unit")}
            name="structuralUnitId"
            control={control}
            values={structuralUnitOptions}
          />
        </Grid>

        <Grid item xs={12}>
          <FormSelect
            readOnly
            label={t("position")}
            name="positionId"
            control={control}
            values={positionOptions}
          />
        </Grid>

        <Grid item xs={12}>
          <FormRadio
            disabled={isViewMode}
            name="employeeType"
            control={control}
            values={additionTypeOptions}
          />
        </Grid>

        <Grid item xs={12}>
          <FormAutocomplete
            required
            readOnly={isViewMode}
            disabled={!employeeType}
            inputValue={inputEmployeeValue}
            freeSolo={false}
            label={t("employee.fullname")}
            fetchData={fetchEmployees}
            defaultSelected={getEmployeeDefaultValue(employeeId)}
            onFetchDataSuccess={setEmployeeOptions}
            onInputChange={handleEmployeeInputChange}
            error={!!errors.employeeId}
          />
        </Grid>

        <Grid item xs={12} sm={7}>
          <Input
            shrink
            value={additionalInformation}
            readOnly={
              employeeType === EnumEmployeeType.substitution ||
              (isViewMode && !isEditMode)
            }
            label={
              employeeType === EnumEmployeeType.employee
                ? t("additional.information")
                : t("name.of.organization")
            }
            maxLength={300}
            {...register("additionalInformation")}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={5}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <FormControlLabel
            label={
              employeeType === EnumEmployeeType.substitution
                ? t("valid.substitution")
                : t("valid.employee")
            }
            labelPlacement="start"
            disabled={(isViewMode && !isEditMode) || !isViewMode}
            control={
              <Switch
                defaultChecked={validity}
                {...register("validity")}
                onChange={handleValidityChange}
              />
            }
          />
        </Grid>
      </Grid>

      <ActionsSection>
        {isViewMode && !isEditMode ? (
          <Button
            variant="contained"
            fullWidth
            onClick={() => dispatch(setModal(null))}
          >
            {t("close")}
          </Button>
        ) : (
          <Button
            variant="contained"
            fullWidth
            type="submit"
            loading={loading}
            disabled={
              loading ||
              !isRequiredFieldsFilled ||
              (isEditMode && !Object.keys(dirtyFields).length)
            }
          >
            {t("save")}
          </Button>
        )}
      </ActionsSection>
    </FormWrapper>
  );
};
