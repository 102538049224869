import { useAppSelector } from "app";

import { CorrespondenceTypeEnum, DrawerVariantsEnum } from "@types";

import { AdjustAgreementRoute } from "./components";

interface Props {
  correspondenceId: number | undefined;
  correspondenceType: CorrespondenceTypeEnum;
}

export const AdjustAgreementRouteContainer = ({
  correspondenceId,
  correspondenceType,
}: Props) => {
  const { drawer } = useAppSelector((state) => state.global);

  if (
    drawer !== DrawerVariantsEnum.adjustAgreementRoute ||
    !correspondenceId ||
    !correspondenceType
  )
    return null;

  return (
    <AdjustAgreementRoute
      correspondenceId={correspondenceId}
      correspondenceType={correspondenceType}
    />
  );
};
