import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useDeleteContractMutation } from "api";
import { Modal } from "ui-kit";

import { Button } from "@mui/material";

import { Paths } from "../../../../routes";

interface RemoveContractPopupProps {
  open: boolean;
  onClose: () => void;
  data: {
    data: {
      id: number;
    };
  };
}

export const RemoveContractPopup: React.FC<RemoveContractPopupProps> = (
  props
) => {
  const {
    open,
    onClose,
    data: { data },
  } = props;
  const { id } = data;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleConfirmTransition = () => {
    onClose();
    navigate(Paths.CONTRACTS);
  };

  const { mutate: deleteContract } = useDeleteContractMutation(
    handleConfirmTransition
  );

  const content = {
    title: t("remove_contract_title"),
    body: t("remove_contract_desc"),
    secondaryButton: (
      <Button
        onClick={onClose}
        variant="outlined"
        size="large"
        color="secondary"
      >
        {t("cancel")}
      </Button>
    ),
    mainButton: (
      <Button
        autoFocus
        variant="contained"
        color="secondary"
        size="large"
        onClick={() => {
          deleteContract(id);
          onClose();
        }}
      >
        {t("confirm")}
      </Button>
    ),
  };

  return <Modal isOpen={open} onClose={onClose} content={content} />;
};
