import { useCallback, useEffect, useRef } from "react";

import { UI_CONFIG } from "app";
import { DefaultTFuncReturn } from "i18next";

import { Stack, Tab, Tabs } from "@mui/material";

import { BasicTabsWrapper } from "./styles";

export interface BasicTabsProps {
  tabIndex: number;
  onBack?: () => void;
  onChange: (event: React.SyntheticEvent, arg: number) => void;
  /* Use this prop to disable sticky tabs position */
  sticky?: boolean;
  hovered?: boolean;
  tabs: {
    label: DefaultTFuncReturn;
    icon?: JSX.Element;
    disabled?: boolean;
    component: JSX.Element;
  }[];
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

export const BasicTabs = (props: BasicTabsProps) => {
  const { tabs, tabIndex, onChange, sticky = true, hovered = false } = props;

  const ref = useRef<HTMLDivElement | null>(null);

  const scrollListener = useCallback(() => {
    if (!ref.current || !sticky) return;

    if (window.scrollY > UI_CONFIG.header + 262) {
      // 262px is the minimum height of the card.
      return ref.current.classList.add("fixed");
    }

    ref.current.classList.remove("fixed");
  }, [sticky]);

  useEffect(() => {
    if (!sticky) return;

    window.addEventListener("scroll", scrollListener);

    return () => {
      window.removeEventListener("scroll", scrollListener);
    };
  }, [scrollListener, sticky]);

  if (!tabs || !tabs.length) return null;

  return (
    <BasicTabsWrapper className="tabs_wrapper" $hovered={hovered}>
      <Stack direction="row" ref={ref}>
        <Tabs value={tabIndex} onChange={onChange} aria-label="basic tabs">
          {tabs.map((tab, index) => (
            <Tab
              key={tab.label}
              label={tab.label}
              icon={tab.icon}
              disabled={tab.disabled}
              iconPosition="start"
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Stack>

      {tabs.map((tab, index) => (
        <TabPanel key={tab.label} value={tabIndex} index={index}>
          {tab.component}
        </TabPanel>
      ))}
    </BasicTabsWrapper>
  );
};
