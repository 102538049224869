import React from "react";
import { useTranslation } from "react-i18next";

import { updateApprovalRoute, useAppDispatch } from "app";
import { Drawer } from "ui-kit";

import { Stack } from "@mui/material";
import { capitalize } from "@sbm/fe-utils";
import { Button } from "@sbm/ui-components";
import { IApprovalRoute } from "@types";

import { ApprovalRouteChangeContainer } from "../../../ApprovalRouteChangeContainer";

interface ChangeRouteProps {
  onClose: () => void;
  data: IApprovalRoute[] | undefined;
  currentRoute: IApprovalRoute | null | undefined;
}

export const ChangeRoute: React.FC<ChangeRouteProps> = ({
  data,
  onClose,
  currentRoute,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [selectedRow, setSelectedRow] = React.useState<IApprovalRoute | null>(
    currentRoute || null
  );

  const handleApply = () => {
    if (selectedRow) {
      dispatch(updateApprovalRoute(selectedRow));
    }

    onClose();
  };

  return (
    <Drawer
      open
      anchor="right"
      onClose={onClose}
      resizable={true}
      title={t("select.other.route")}
      actions={
        <Stack
          flexDirection="row"
          justifyContent="end"
          alignItems="center"
          gap={4}
        >
          <Button
            size="large"
            color="secondary"
            variant="outlined"
            onClick={onClose}
            sx={{ width: "fit-content" }}
          >
            {capitalize(t("cancel"))}
          </Button>
          <Button
            size="large"
            color="secondary"
            variant="contained"
            onClick={handleApply}
            sx={{ width: "fit-content" }}
          >
            {capitalize(t("save"))}
          </Button>
        </Stack>
      }
    >
      <ApprovalRouteChangeContainer
        data={data}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
      />
    </Drawer>
  );
};
