import { useAppSelector } from "app";

import { CorrespondenceTypeEnum, DrawerVariantsEnum } from "@types";

import { AttachesCorrespondenceLinkForm } from "./components/AttachesCorrespondenceLinkForm";

interface Props {
  correspondenceId: number | undefined;
  type: CorrespondenceTypeEnum;
}

export const AttachedLinkContainer = ({ correspondenceId, type }: Props) => {
  const { drawer } = useAppSelector((state) => state.global);

  if (drawer !== DrawerVariantsEnum.attachLinkInCorrespondence) return null;

  return (
    <AttachesCorrespondenceLinkForm
      correspondenceId={correspondenceId}
      type={type}
    />
  );
};
