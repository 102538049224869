import React from "react";

import { Stack } from "@mui/material";
import { CorrespondenceTypeEnum } from "@types";

import { CorrespondenceFilesTable } from "./CorrespondenceFilesTable";
import { CorrespondenceLinksTable } from "./CorrespondenceLinksTable";

interface TaskDetailsProps {
  correspondenceId: number | undefined;
  isAuthor: boolean;
  type: CorrespondenceTypeEnum;
}

export const CorrespondenceAttachmentsAndFiles: React.FC<TaskDetailsProps> = ({
  correspondenceId,
  isAuthor,
  type,
}) => {
  return (
    <Stack mt={6} gap={6} overflow="auto">
      <CorrespondenceFilesTable
        correspondenceId={correspondenceId}
        isAuthor={isAuthor}
        type={type}
      />
      <CorrespondenceLinksTable
        correspondenceId={correspondenceId}
        isAuthor={isAuthor}
        type={type}
      />
    </Stack>
  );
};
