import React from "react";

import { AppDispatch, openDrawerWithData } from "app";
import { TFunction } from "i18next";
import { MuiTheme } from "ui-kit";

import { Button } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import {
  COLUMN_SIZE,
  getLocaleDateFormat,
  StaffAllocationFilterKeys,
} from "@sbm/fe-utils";
import { DrawerWithDataVariantsEnum, EnumEmployeeType } from "@types";

import { StaffAllocationActions } from "./StaffAllocationActions";
import {
  AdditionalInformation,
  EmployeeColumnWrapper,
  EmployeeName,
} from "./styles";

export const getColumns: (
  t: TFunction,
  isEditMode: boolean,
  dispatch: AppDispatch,
  organizationId?: string
) => GridColDef[] = (t, isEditMode, dispatch, organizationId) => [
  {
    field: "nameOfPosition",
    width: COLUMN_SIZE * 3,
    headerName: "staff_position.name",
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      const style = { fontWeight: params.row.headOfStructuralUnit ? 600 : 400 };
      return <span style={style}>{params.row.nameOfPosition}</span>;
    },
  },
  {
    field: "employeeName",
    width: COLUMN_SIZE * 2.5,
    headerName: "employee.fullname",
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      const handleClick = (e: any) => {
        e.stopPropagation();

        // Uncomment if will need the old version of create staff member
        // dispatch(
        //   setModal({
        //     open: true,
        //     variant: ModalVariants.createStaffMember,
        //     content: {
        //       data: { ...params.row, organizationId },
        //     },
        //   })
        // );

        dispatch(
          openDrawerWithData({
            variant: DrawerWithDataVariantsEnum.addOrEditEmployee,
            content: { data: { ...params.row, organizationId } },
          })
        );
      };
      return (
        <EmployeeColumnWrapper>
          <EmployeeName hasEmployee={Boolean(params.row.employeeName)}>
            {params.row.employeeName || t("vacancy")}
          </EmployeeName>

          {isEditMode &&
          !params.row.employeeName &&
          params.row.positionId &&
          !params.row.externalSubstitution ? (
            <Button
              variant="contained"
              color="secondary"
              size="small"
              sx={{ zIndex: 999 }}
              onClick={handleClick}
            >
              {t("add")}
            </Button>
          ) : null}
        </EmployeeColumnWrapper>
      );
    },
  },
  {
    field: "dateOfRecruitment",
    width: COLUMN_SIZE * 2,
    headerName: "employee.assignment.date",
    sortable: false,
    filterable: false,
  },
  {
    field: "additionalInformation",
    minWidth: COLUMN_SIZE * 4,
    headerName: "note",
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      const staffUnitAdditionalInfo = params.row.additionalInformation;
      const externalSubstitution = params.row.externalSubstitution;

      const isSubstitution =
        params.row.employeeType === EnumEmployeeType.substitution;

      if (isSubstitution) {
        const employeeOrgInfo = `${
          externalSubstitution?.organization?.fullTextOrganizationName || ""
        } ${t("employee.ext.subst.since")} ${
          getLocaleDateFormat(externalSubstitution?.dateOfStartSubstitution) ||
          ""
        }`;

        return (
          <AdditionalInformation>
            <span>{externalSubstitution.employee?.fullName}</span>
            <span style={{ color: MuiTheme.palette.text.secondary }}>
              {employeeOrgInfo}
            </span>
          </AdditionalInformation>
        );
      }

      if (staffUnitAdditionalInfo)
        return <span>{staffUnitAdditionalInfo}</span>;
    },
  },
  {
    field: "actions",
    maxWidth: COLUMN_SIZE,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      const handleEdit = () => {
        dispatch(
          openDrawerWithData({
            variant: DrawerWithDataVariantsEnum.addOrEditEmployee,
            content: {
              data: { ...params.row, organizationId },
              isEditModeDrawer: true,
            },
          })
        );
      };
      return (
        params.row.employeeId && <StaffAllocationActions onEdit={handleEdit} />
      );
    },
  },
];

export const STAFF_ALLOCATION_FILTERABLE_FIELDS = [
  {
    title: "staff_position.name",
    field: StaffAllocationFilterKeys.positionId,
    options: [],
    optionType: "",
    value: "",
  },
  {
    title: "employee.fullname",
    field: StaffAllocationFilterKeys.employeeId,
    options: [],
    optionType: "",
    value: "",
  },
  {
    title: "name.of.the.structural.unit",
    field: StaffAllocationFilterKeys.structuralUnitId,
    options: [],
    optionType: "",
    value: "",
  },
  {
    title: "employee.assignment.date",
    field: StaffAllocationFilterKeys.dateOfRecruitment,
    options: [],
    value: "",
    optionType: "range",
  },
];
