import { UI_CONFIG } from "app";
import styled from "styled-components";
import { FLEX_ROW_ALIGN_CENTER, FLEX_ROW_FULL } from "ui-kit";

import { Chip } from "@mui/material";
import { hexToRgba } from "@sbm/fe-utils";

import male_avatar from "../../../../assets/images/avatars/male_avatar.png";

export const StatusWrapper = styled.div<{ $isRead: boolean }>`
  ${FLEX_ROW_ALIGN_CENTER};
  overflow: visible !important;
  .read_unread_icon {
    opacity: ${({ $isRead }) => ($isRead ? 0 : 1)};
    background-color: ${({ $isRead, theme }) =>
      $isRead ? "" : theme.palette.primary.dark};
    width: 8px;
    height: 8px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.4s ease;
    &:hover {
      opacity: 1;
      background-color: ${({ $isRead, theme }) =>
        $isRead ? theme.palette.primary.light : theme.palette.primary.dark};
    }
  }
`;

export const CellWrapper = styled.div<{ $isMessageViewed: boolean }>`
  ${FLEX_ROW_ALIGN_CENTER};
  gap: ${({ theme }) => theme.spacing()};

  font-weight: ${({ $isMessageViewed }) => ($isMessageViewed ? 400 : 700)};
`;

export const AttachedFilesWrapper = styled.div<{ $isMessageViewed: boolean }>`
  ${FLEX_ROW_FULL};
  width: 100%;
  font-weight: ${({ $isMessageViewed }) => ($isMessageViewed ? 400 : 700)};
  gap: ${({ theme }) => theme.spacing()};
`;

export const StyledChip = styled(Chip)<{
  $isIncomingMessage: boolean;
  $isMessageViewed: boolean;
}>`
  width: ${UI_CONFIG.chipWidth}px;
  padding-left: ${({ theme }) => theme.spacing(3)};
  padding-right: ${({ theme }) => theme.spacing(3)};
  align-items: center;
  font-weight: ${({ $isMessageViewed, $isIncomingMessage }) =>
    $isMessageViewed || !$isIncomingMessage ? 400 : 700};
  color: ${({ theme, $isIncomingMessage }) =>
    $isIncomingMessage
      ? theme.palette.text.primary
      : theme.palette.secondary.main};
  background: ${({ theme, $isIncomingMessage }) =>
    $isIncomingMessage
      ? hexToRgba(theme.palette.text.primary, 0.08)
      : hexToRgba(theme.palette.secondary.main, 0.08)};
`;

export const AvatarWrapper = styled.div<{
  $fromSystem?: boolean;
}>`
  border-radius: 50%;
  background-image: ${({ $fromSystem }) =>
    $fromSystem ? `url(${male_avatar})` : ``};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
`;
