// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

const API_URL =
  import.meta.env.VITE_API_URL || "https://api.testing.sbm.systems";
const KEYCLOAK_URL =
  import.meta.env.VITE_KEYCLOAK_URL || "https://keycloak.testing.sbm.systems";
const REALM = "sbm-realm";
const CLIENT_ID = "sbm-frontend-client";
const WS_API_URL = "https://ws-api.testing.sbm.systems/";

export const environment = {
  production: false,
  apiUrl: API_URL,
  realm: REALM,
  clientId: CLIENT_ID,
  WSApiUrl: WS_API_URL,
  authority: `${KEYCLOAK_URL}/realms/${REALM}/`,
  storeKeyPrefix: "sbm.",
  userStorageType: "local",
  stateStorageType: "local",
  muiLicenseKey:
    "f5648cb41c2d1c37c5610eadf8932afeTz05MzUxNSxFPTE3NTE0Nzg1MjEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
};
