import React, { useEffect, useRef } from "react";

import { useAppSelector } from "app";
import { DotsLoader } from "ui-kit";

import { ConversationResponse } from "@types";

import { ConversationCard } from "../../ConversationCard";
import { ConversationWrapper } from "../styles";

interface Props {
  conversationData?: ConversationResponse[];
  authorUserID: number;
  processing: boolean;
}

export const MessagesSection = ({
  conversationData,
  authorUserID,
  processing,
}: Props) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const { currentMessageId } = useAppSelector((state) => state.message);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [conversationData]);

  useEffect(() => {
    if (processing) {
      return;
    } else if (currentMessageId && conversationData) {
      const index = conversationData.findIndex(
        (message) => message.message.id === currentMessageId
      );
      if (index !== -1) {
        const currentMessageRef = document.getElementById(
          `conversation-card-${index}`
        );
        if (currentMessageRef) {
          currentMessageRef.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    }
  }, [currentMessageId, conversationData, processing]);

  return (
    <ConversationWrapper>
      {!conversationData ? <DotsLoader /> : null}
      {conversationData?.map((c, index) => (
        <div key={c.message.id} id={`conversation-card-${index}`}>
          <ConversationCard
            data={c}
            key={index}
            authorUserID={authorUserID}
            currentMessage={c.message.id === currentMessageId}
          />
        </div>
      ))}
      <div ref={messagesEndRef} />
    </ConversationWrapper>
  );
};
