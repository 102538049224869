import toast from "react-hot-toast";

import { axiosService } from "app";
import { AxiosError } from "axios";

import { getAPIErrorMessage, getFilterParams } from "@sbm/fe-utils";
import { QueryFunctionContext } from "@tanstack/react-query";
import {
  GenderEnum,
  ICreateEmployee,
  IExternalOrganizationStaff,
  IMeta,
  IOrganization,
  IOrganizationEmployee,
} from "@types";

export const fetchOrganizationsList = async (
  { pageParam }: QueryFunctionContext,
  sortBy = "",
  search = "",
  filter: (string | undefined)[] = []
) => {
  const page = pageParam ?? 1;
  const limit = 20;

  const filterParams = getFilterParams(filter);

  if (Object.keys(filterParams).includes("filter.activityIndicator")) {
    filterParams["filter.activityIndicator"] =
      filterParams["filter.activityIndicator"][0];
  }

  const params = {
    search,
    sortBy,
    limit,
    page,
    ...filterParams,
  };

  const { data } = await axiosService({
    endpoint: "/organizations",
    body: params,
  });

  return data;
};

export const fetchOrganizationStaffAllocation = async (
  orgId: string | undefined,
  search = "",
  filter: (string | undefined)[] = []
) => {
  const dateFilters = ["filter.dateOfRecruitment"];
  const filterParams = getFilterParams(filter, dateFilters);

  const params = {
    search,
    ...filterParams,
  };
  try {
    const { data } = await axiosService({
      endpoint: `/staff-units/organization-staff-allocation-tree/${orgId}`,
      body: params,
    });

    return data;
  } catch (e) {
    const errorMessage = getAPIErrorMessage(e as AxiosError);
    toast.error(errorMessage);
  }
};

export const fetchEmployeesInHolding = async (search = "", limit?: number) => {
  const params: {
    limit: number;
    page: number;
    search: string;
  } = {
    limit: limit || 200,
    page: 1,
    search,
  };

  try {
    const { data } = await axiosService({
      endpoint: `/employees/in-holding`,
      body: params,
    });

    return data;
  } catch (e) {
    const errorMessage = getAPIErrorMessage(e as AxiosError);
    toast.error(errorMessage);
  }
};

export const fetchCreateEmployee = async (body: ICreateEmployee) => {
  try {
    const { data } = await axiosService({
      endpoint: "employees",
      method: "POST",
      body,
    });

    return data;
  } catch (e) {
    const errorMessage = getAPIErrorMessage(e as AxiosError);
    toast.error(errorMessage);
  }
};

export const fetchOrganizationById = async (id: number) => {
  const { data } = await axiosService({
    endpoint: `/organizations/${id}`,
  });

  return data;
};

export const fetchFunctionalDirectionOfTheUnit = async (id: number) => {
  const body = {
    organizationId: id,
  };
  const { data } = await axiosService({
    endpoint: `/function-for-structural-unit/available-functions`,
    body,
  });

  return data;
};

export const postExternalOrganizationStaff = async (body: {
  organizationId: number;
  staff: IOrganizationEmployee[];
}) => {
  const { data } = await axiosService({
    endpoint: `/external-organization-staff`,
    method: "POST",
    body,
  });

  return data;
};

export const getExternalOrganizationStaff = async (
  search: string,
  organizationId: number,
  rightToSignStaff?: boolean
) => {
  const body: {
    limit: number;
    page: number;
    search: string;
    "filter.organizationId": number;
    "filter.rightToSignStaff"?: boolean;
  } = {
    limit: 200,
    page: 1,
    search,
    "filter.organizationId": organizationId,
  };

  if (rightToSignStaff) {
    body["filter.rightToSignStaff"] = rightToSignStaff;
  }

  const { data } = await axiosService({
    endpoint: `/external-organization-staff`,
    body,
  });

  return data as { items: IExternalOrganizationStaff[]; meta: IMeta };
};

export const fetchUpdateOrganization = async (
  body: Omit<IOrganization, "employees" | "externalOrganizationStaff">,
  organizationId: number | undefined
) => {
  if (!organizationId) return;

  const { data } = await axiosService({
    endpoint: `/organizations/${organizationId}`,
    method: "PUT",
    body,
  });

  return data;
};

export const postPersonalData = async (body: {
  name: string;
  surname: string;
  patronymic: string;
  gender: GenderEnum;
}) => {
  const { data } = await axiosService({
    endpoint: `/personal-data`,
    method: "POST",
    body,
  });

  return data;
};

export const getPersonalData = async (search: string) => {
  const body = {
    limit: 200,
    page: 1,
    search,
  };
  const { data } = await axiosService({
    endpoint: `/personal-data`,
    body,
  });

  return data;
};

export const getOrganizationListById = async (organizationId: number) => {
  const { data } = await axiosService({
    endpoint: `/organizations/${organizationId}`,
  });

  return data;
};

export const uploadOrganizationFile = async (body: {
  requestBody: FormData;
  organizationId: number;
}) => {
  const { data } = await axiosService({
    endpoint: `/organizations/${body.organizationId}/files`,
    method: "PUT",
    headers: { "Content-Type": "multipart/form-data" },
    body: body.requestBody,
  });

  return data;
};

export const getOrganizationFileById = async (organizationId: number) => {
  const { data } = await axiosService({
    endpoint: `/organizations/${organizationId}/files`,
  });

  return data;
};

export const getOrgStaffAllocationDetails = async (body: {
  externalSubstitutionId?: number;
  employeeId?: number;
  staffUnitId: number;
}) => {
  const { data } = await axiosService({
    endpoint: "/staff-units/organization-staff-allocation",
    body,
  });

  return data;
};
