import React from "react";
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { matchIsValidTel } from "mui-tel-input";
import { Icon } from "ui-kit";

import {
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { hexToRgba } from "@sbm/fe-utils";
import { Button, PhoneInput } from "@sbm/ui-components";
import { IOrganization } from "@types";

import { PhoneTypeAutocomplete } from "./PhoneTypeAutocomplete";

interface PhoneNumbersProps {
  register: UseFormRegister<IOrganization>;
  setValue: UseFormSetValue<IOrganization>;
  control: Control<IOrganization>;
}

export const PhoneNumbers: React.FC<PhoneNumbersProps> = ({
  control,
  setValue,
}) => {
  const { palette } = useTheme();
  const { t } = useTranslation("common", {
    keyPrefix: "create_organization.contacts_section",
  });

  const { phone = [] } = useWatch({ control });

  const handleAdd = () => {
    const dataToSet = [...phone, { number: "" }] as IOrganization["phone"];
    setValue("phone", dataToSet, { shouldDirty: true });
  };

  const handleDelete = (position: number) => {
    const newPhoneNumbers = (phone?.filter((_, index) => index !== position) ||
      []) as IOrganization["phone"];

    setValue("phone", newPhoneNumbers, {
      shouldDirty: true,
    });
  };

  return (
    <Card>
      <CardContent>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mb={phone.length > 0 ? 4 : 0}
        >
          <Typography variant="h9_semiBold" color="text.disabled">
            {t("phone_numbers_section_title")}
          </Typography>

          <Button
            size="small"
            color="secondary"
            variant="text"
            onClick={handleAdd}
            startIcon={
              <Icon name="Plus" size={18} color={palette.secondary.main} />
            }
          >
            {t("add_button_label")}
          </Button>
        </Stack>

        <Stack gap={4}>
          {phone.map((_, index) => {
            const phoneNumber = phone?.[index]?.number;
            const phoneType = phone?.[index]?.phoneType;
            const customPhoneType = phone?.[index]?.customPhoneType;

            return (
              <Stack
                key={index}
                flexDirection="row"
                alignItems="center"
                gap={4}
              >
                <Controller
                  key={`phone[${index}].number`}
                  name={`phone[${index}].number`}
                  control={control as unknown as Control<FieldValues>}
                  rules={{
                    required: true,
                    validate: phoneNumber ? matchIsValidTel : undefined,
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <PhoneInput
                        required
                        error={fieldState.invalid}
                        label={t("phone_number_input_label")}
                        {...field}
                      />
                    );
                  }}
                />

                <PhoneTypeAutocomplete
                  defaultValue={phoneType || customPhoneType}
                  position={index}
                  setValue={setValue}
                />

                <IconButton size="small" onClick={() => handleDelete(index)}>
                  <Icon
                    size={20}
                    name="XCircle"
                    className="cursor-pointer"
                    color={hexToRgba(palette.primary.main, 0.54)}
                  />
                </IconButton>
              </Stack>
            );
          })}
        </Stack>
      </CardContent>
    </Card>
  );
};
