import styled from "styled-components";
import { FLEX_COLUMN, FLEX_ROW_ALIGN_CENTER } from "ui-kit";

import { Card } from "@mui/material";

export const Wrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(6)};
`;
export const CardWrapper = styled(Card)`
  ${FLEX_COLUMN};
  position: relative;
  padding: ${({ theme }) => theme.spacing(3, 4, 4, 4)};
  margin: ${({ theme }) => theme.spacing(4, 0)};
  border-radius: ${({ theme }) => theme.spacing(4)};
  gap: ${({ theme }) => theme.spacing(4)};
  z-index: 1;
`;
export const TableActions = styled.div`
  ${FLEX_ROW_ALIGN_CENTER};
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing(8)};

  width: 100%;

  margin-bottom: ${({ theme }) => theme.spacing(5)};

  button {
    max-width: 220px;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    flex-direction: column-reverse;
    align-items: flex-end;

    button {
      max-width: 100%;
    }
  }
`;
