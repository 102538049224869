import { useAppSelector } from "app";

import { CorrespondenceTypeEnum, DrawerVariantsEnum } from "@types";

import { AttachedCorrespondenceForm } from "./components/AttachedCorrespondenceForm";

interface Props {
  correspondenceId: number | undefined;
  type: CorrespondenceTypeEnum;
}

export const AttachedFileContainer = ({ correspondenceId, type }: Props) => {
  const { drawer } = useAppSelector((state) => state.global);

  if (drawer !== DrawerVariantsEnum.attachFileInCorrespondence) return null;

  return (
    <AttachedCorrespondenceForm
      correspondenceId={correspondenceId}
      type={type}
    />
  );
};
